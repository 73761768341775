import { ACTIONS } from "../action-constants/Actions";

export const addReducer = ( state={}, action={} )=>{

    switch(action.type){
        
        case ACTIONS.COMMON_ACTION.ADD.ADDING:{

            let newState = { ...{}, ...state };
            let { isAdding } = action.payload;
            newState.isAdding = isAdding;
            return newState
        }
        case ACTIONS.COMMON_ACTION.ADD.ADDED:{
            
            let newState = { ...{}, ...state };
            let { isAdding } = action.payload;
            newState.isAdding = isAdding;
            return newState;
        }
        case ACTIONS.COMMON_ACTION.ADD.ADDING_FAILED:{
            
            let newState = { ...{}, ...state };
            let { error,isAdding } = action.payload;
            newState.isAdding = isAdding;
            newState.msg = error;
            return newState
        }
        default:{
            return state;
        }
    }
}