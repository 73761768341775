import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import 'antd/dist/antd.css';
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import "./assets/styles/style.scss";
// import * as serviceWorker from "./serviceWorker";
import { store } from "./store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
