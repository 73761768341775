
import React, { Component} from "react";
import {NavLink} from 'react-router-dom';
import SlidingPanel from 'react-sliding-side-panel';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { GetNavBarApi } from "../../../../store/api-actions/menu/GetNavBarApi";
import logo from '../../../../assets/images/logo.png';
import user from "../../../../assets/images/user.jpg";


const mapDispatchToProps = dispatch => {
  return {
    GetNavBar: (reqObj)=>dispatch(GetNavBarApi(reqObj)),
  };
};

const mapStateToProps = state => {
  return {
    navBarListState: state.navBarList
  };
};

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      isToggleActive:false,
      isToggleClass:"hide",
      isActiveClass : "",
      menuNo:0
    };
  }

  toggleHandler=(data)=>{
    let isClass = document.body.classList.contains('open-sidebar')
    if(!isClass){
      if(data.isToggle){
        this.setState({isToggleActive:false,isToggleClass:"hide",isActiveClass:"",menuNo:data.menuNo})
      }else{
        this.setState({isToggleActive:true,isToggleClass:"show",isActiveClass:"activec",menuNo:data.menuNo})
      }
    }
  }

  componentDidMount(){
    let { match } = this.props
    console.log("===0000000000000",match)
    let reqObj = {};
    reqObj.userId = match.params.id
    this.props.GetNavBar(reqObj).then(res=>{
      const { message = null, result = null } = res;
    })
  }
  
  render() {
    let { list } = this.props.navBarListState
    console.log("ppppoooooooo",list,"jjjjooooo",this.props)
    return (
      <div className="sidebar-main">
      <SlidingPanel
        type={'left'}
        isOpen={true}
        //isOpen={this.props.isSlide}
        size={100}
      >
        
          <div className="sidebar-wrapper">
            <div className="logoadmin">
            <img src="https://drivinginstructorlicensecourse.com/static/media/Advantage-Circle-Logo.f1eb15a6.png" className="logo-img" alt="styleoak" />
            </div>
            <div className="user-panel">
              <div className="pull-left image">
                <img src={user} className="img-circle" alt="Admin Image" />
              </div>
              <div className="pull-left info">
                <p>Admin </p>
                <a><i className="fa fa-circle text-success"></i> Online</a>
              </div>
            </div>
            <ul className="list-unstyled">
            { list.map((item, index) => (
              item.productData.length<1?(
                <li key={Math.random()}>            
                  <NavLink to={item.route}>
                    <div className="icons-cls"><i className={item.icon} aria-hidden="true"></i></div> 
                    <span>{item.name}</span>
                  </NavLink>
                </li>
              ):(
                <li className="subcls">            
                  <a className={"arrow "+(this.state.menuNo==item._id?this.state.isActiveClass:"")} onClick = {()=>{
                    this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:item._id})
                  }} >
                    <div className="icons-cls"><i className={item.icon} aria-hidden="true"></i></div>
                    <span>{item.name}</span>
                  </a>
                  <ul className={"sub-categorys "+(this.state.menuNo==item._id?this.state.isToggleClass:"hide")}>
                  { item.productData.map((item1, index) => (
                    <li>
                    <NavLink to={item1.route}>
                      <div className="icons-cls"><i className={item.icon}></i></div>
                      {item1.name}
                    </NavLink>
                    </li>
                  ))} 
                  </ul>
                </li>
              )
            ))}


              {/* <li>            
                <NavLink to="/Dashboard">
                  <div className="icons-cls"><i className="fa fa-tachometer" aria-hidden="true"></i></div> 
                  <span>Dashboard</span>
                </NavLink>
              </li>
               <li className="subcls">            
 
                <a className={"arrow "+(this.state.menuNo==1?this.state.isActiveClass:"")} onClick = {()=>{
 
                  this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:1})
                }} >
                  <div className="icons-cls"><i class="fa fa-bars" aria-hidden="true"></i></div>
                  <span>Categorys</span>
 
                </a>
 
                <ul className={"sub-categorys "+(this.state.menuNo==1?this.state.isToggleClass:"hide")}>
                  <li>
                  <NavLink to="/portal/category">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Categorys
                  </NavLink>
                  </li>
                </ul>
              </li>
              
              <li>            
                <NavLink to="/portal/user">
                  <div className="icons-cls"><i className="fa fa-user" aria-hidden="true"></i></div> 
                  <span>User List</span>
                </NavLink>
              </li>
              <li className="subcls">            
                <a className={"arrow "+(this.state.menuNo==2?this.state.isActiveClass:"")} onClick = {()=>{
                  this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:2})
                }} >
                  <div className="icons-cls">
                  <i class="fa fa-database"></i>
                    </div>
                  <span>Products</span>
                </a>
                <ul className={"sub-categorys "+(this.state.menuNo==2?this.state.isToggleClass:"hide")}>
                  <li>
                  <NavLink to="/portal/product">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Products
                  </NavLink>
                  </li>
                </ul>
              </li>
              <li>            
                <NavLink to="/portal/Customers">
                  <div className="icons-cls">
                  <i class="fa fa-users" aria-hidden="true"></i>
                    </div> 
                  <span>Customers</span>
                </NavLink>
              </li>
              <li>            
                <NavLink to="/portal/Order">
                  <div className="icons-cls">
                  <i class="fa fa-list-ul" aria-hidden="true"></i>
                    </div> 
                  <span> Orders</span>
                </NavLink>
              </li>
              <li>            
                <NavLink to="/portal/banner">
                  <div className="icons-cls"><i className="fa fa-image" aria-hidden="true"></i></div> 
                  <span>Banner</span>
                </NavLink>
              </li>
              <li className="subcls">            
                <a to='#' className={"arrow "+(this.state.menuNo==5?this.state.isActiveClass:"")} onClick = {()=>{
                  this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:5})
                }} >
                  <div className="icons-cls">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    </div>
                  <span>Reports</span>
                </a>
                <ul className={"sub-categorys "+(this.state.menuNo==5?this.state.isToggleClass:"hide")}>
                  <li>
                  <NavLink to="/portal/LowStockProducts">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Low Stock Products
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/ProductsOutofStock">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Out of Stock Products 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/CustomerOrdersTotal">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Customer Orders-Total 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/StatsProductsPurchased">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Total Purchased 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/StatsProductsLiked">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Products Liked 
                  </NavLink>
                  </li>
                </ul>
              </li>
              <li className="subcls">            
                <a to='#' className={"arrow "+(this.state.menuNo==8?this.state.isActiveClass:"")} onClick = {()=>{
                  this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:8})
                }} >
                  <div className="icons-cls">
                  <i class="fa fa-gears" aria-hidden="true"></i>
                    </div>
                  <span>General Settings</span>
                </a>
                <ul className={"sub-categorys "+(this.state.menuNo==8?this.state.isToggleClass:"hide")}>
                  <li>
                  <NavLink to="/portal/Units">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Units
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/OrderStatus">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Order Status 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/FacebookSettings">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Facebook
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/GoogleSettings">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Google 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/PushNotification">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Push Notification
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="Alert Settings">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Alert Settings 
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/Settings">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Settings 
                  </NavLink>
                  </li>
                </ul>
              </li>
              <li className="subcls">            
                <a to='#' className={"arrow "+(this.state.menuNo==9?this.state.isActiveClass:"")} onClick = {()=>{
                  this.toggleHandler({isToggle:this.state.isToggleActive,menuNo:9})
                }} >
                  <div className="icons-cls">
                  <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                  <span>Manage Admins</span>
                </a>
                <ul className={"sub-categorys "+(this.state.menuNo==9?this.state.isToggleClass:"hide")}>
                  <li>
                   <NavLink to="/portal/subadmin">
 
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Sub Admins
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to="/portal/ManageRoles">
                    <div className="icons-cls"><i class="fa fa-circle-o"></i></div>
                    Manage Roles 
                  </NavLink>
                  </li>
                </ul>
              </li>            */}
            </ul>
          </div>
      </SlidingPanel>
    </div>

    );
  }
}

export default Sidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
 
