
import React, { Component } from 'react';
import { Heading,InputBox,} from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Link } from 'react-router-dom';
import download from '../../../../assets/images/download.svg';

class WebsiteThemes extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Website Themes"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="website-themes">
          <h4>Choose your website theme</h4>
          <hr/>
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <div className="col-md-3 col-xs-6">
                            <label className="thumbnail checkbox text-center">
                            <img src={download} width="100%" height="180px" className="image" alt="Product Images"/>
                            <InputBox
                            type="radio"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                            />
                            Style 1
                            </label> 
                            </div>
                        </div>
                    </form>
                )}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default WebsiteThemes 