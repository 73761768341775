import { ACTIONS } from "../action-constants/Actions";

export const updateReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.COMMON_ACTION.UPDATE.UPDATING:{

            let newState = { ...{}, ...state };
            newState.isSuccess = false
            return newState
        }
        case ACTIONS.COMMON_ACTION.UPDATE.UPDATED:{

            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.isSuccess = true
            newState.msg = message
            return newState
        }
        case ACTIONS.COMMON_ACTION.UPDATE.UPDATING_FAILED:{

            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.isSuccess = false
            newState.msg = error;
            return newState
        }
        default:{
            return state;
        }
    }
}