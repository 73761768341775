import { ACTIONS } from "../../action-constants/Actions"


const Fetching=()=>{
    return {
        type: ACTIONS.COMMON_ACTION.FETCH.FETCHING,
        payload:{
            isFetching:true,
            
        }
    }
}

const Failed=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.FETCH.FETCHING_FAILED,
        payload:{
            isFetching:false,
            result,
            msg
        }
    }
}

const Fetched=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.FETCH.FETCHED,
        payload:{
            isFetching:false,
            result:result,
            message:msg
        }
    }
}

export const Get = {
    Failed,
    Fetching,
    Fetched
}