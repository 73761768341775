import { ACTIONS } from "../../action-constants/Actions"


const CategoryFetching=()=>{
    return {
        type: ACTIONS.CATEGORY.CATEGORY_FETCHING,
        payload:{
            isCategoryFetching:true,
            
        }
    }
}

const CategoryFailed=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.CATEGORY_FAILED,
        payload:{
            isCategoryFetching:false,
            result,
            msg
        }
    }
}

const CategoryFetched=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.CATEGORY_FETCHED,
        payload:{
            isCategoryFetching:false,
            result:result,
            message:msg
        }
    }
}

const CategoryDetailsFetched=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.CATEGORY_DETAILS_FETCHED,
        payload:{
            isCategoryFetching:true,
            result:result,
            message:msg
        }
    }
}


export const category = {
    CategoryFailed,
    CategoryFetching,
    CategoryFetched,
    CategoryDetailsFetched
}