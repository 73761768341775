import React, { Component } from 'react'
import { _callApi } from '../../../../services/baseService';
import { connect } from "react-redux";
import { Heading,Image,Button } from '../../commonComponents';
import { GetDealsApi } from "../../../../store/api-actions/deals/GetDealsApi";
import {NavLink} from 'react-router-dom';
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import { END_POINT } from "../../../../constants/apiEndPoints";
import Loader from 'react-loader-spinner';
import { DeleteDealsApi } from "../../../../store/api-actions/deals/DeleteDealsApi";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';

const mapDispatchToProps = dispatch => {
    return {
      deals: (reqData)=>dispatch(GetDealsApi(reqData)),
      delete:(reqData) => dispatch(DeleteDealsApi(reqData))
    };
  };
  
  const mapStateToProps = state => {
    return {
      dealsState: state.deals
    };
  };

class Deals extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:""
        };
      }
      componentDidMount() {
        this.props.deals().then(res=>{

          const { message = null, result = null } = res;
    
             console.log("================77777",this.props)
        })
      }
      handlePageClick = data => {
        let reqData = {};
        if(data && !isNaN(data.selected)){
          reqData.pageNo = parseInt(data.selected)+1;
        }
        if(data && data.target && data.target.value){
          reqData.title = data.target.value;
        }
        this.props.deals(reqData).then(res=>{
          const { message = null, result = null } = res;
        })
      };
      handleDelete = data => {
        let reqData = {};
        if(data._id){
          reqData.dealsId = data._id;
        }
        this.props.delete(reqData).then(res=>{
          const { message = null, result = null } = res;
          this.setState({isWarningConfirMsg:false,isSuccessConfirMsg:true,_id:""});
        })
      };
      cancelDelete = () => {
        this.setState({isWarningConfirMsg:false,_id:""});
      };
    
      confirmMsg = data => {
        this.setState({isWarningConfirMsg:true,_id:data._id});
      };

      redirectTo=(data)=>{
        let { history } = this.props
        if(data.type==1){
          history.push(`/portal/view-deals/${data._id}`)
        }else{
          history.push(`/portal/edit-deals/${data._id}`)
        }
        
      }

      render() {
        var conm = "";
        var loader = "";
        let { list,totalCount} = this.props.dealsState;
        
        if(this.state.isWarningConfirMsg){
          var obj = {
            isWarningConfirMsg:this.state.isWarningConfirMsg,
            confirmBtnText:MESSAGE.CONFIRM_BTN_TEXT,
            title:MESSAGE.WARNING_TITLE,
            onConfirm:()=>{
              this.handleDelete(this.state)
            },
            onCancel:()=>{
              this.cancelDelete(this.state)
            },
            subtitle:MESSAGE.WARNING_SUB_TITLE
          }
          conm = swal.warning(obj);
        }
    
        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.handlePageClick();
    
            },
            subtitle:MESSAGE.SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }

        if(this.props.dealsState.isDealsFetching){
         loader =  <div className="loader-custom">
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={30000000} //3 secs

          />
        </div>
        }
        
        return (
          <div className="album pt-3">
            <div className="container-fluid">
           <Heading headingClass="bg-light" headingText="Deals"/>
           <hr />
           <SearchBox 
            inputOnChange={this.handlePageClick}
            inputName="name"
            btnOnClick={this.handlePageClick}
           />
           <hr />
           {conm}
           <div className="row"> 
            <div className="col-md-12">
            <NavLink to="/portal/add-deals">
               <Button btnType="button" btnClass="btn btn-info pull-right" btnText="Add Deals" />
            </NavLink>   
            </div>
           </div>
            <div className="clearfix">&nbsp;</div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>title</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Reference Code</th>
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.title}</td>
                        <td>{item.price}</td>
                        <td>{item.discount}</td>
                        <td>{item.referenceCode}</td>
                        <td><Image imageSrc={ END_POINT.S3BASE_URL+item.image} imageClass="ima-size-30" /></td>
                        <td>
                          <i onClick={()=>{
                            item.type=0;
                            this.redirectTo(item)
                          } } className="fa fa-edit"></i>|
                          <i onClick={()=>{
                            item.type=1;
                            this.redirectTo(item)
                          } } className="fa fa-eye"></i>|
                          <i className="fa fa-trash" onClick={()=>{
                            this.confirmMsg(item)
                          }}></i>&nbsp;&nbsp;
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount/END_POINT.LIMIT}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pull-right pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        );
      }
}

export default Deals = connect(mapStateToProps, mapDispatchToProps)(Deals);
