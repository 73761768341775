import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { EditProfileApi } from "../../../../store/api-actions/adminProfile/EditProfileApi";



const mapDispatchToProps = dispatch => {
    return {
      editProfile: form => dispatch(EditProfileApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        editProfileState: state.editProfile
    };
  };

class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:""
        };
    }
    
    editProfileForm = (values, formikBag)=> {

        console.log("&&&&&&&&&",values);
        console.log("&&&&&&&&&",formikBag);
         
        // uploadFile(file, config)
        // .then(data => console.log(data))
        // .catch(err => console.error(err))

    this.props.editProfile(values).then(res=>{
        const { message = null, result = null } = res;
        if ( Object.keys(result).length  > 0) {
          setTimeout(()=>{
            this.props.history.push('/portal/category')
          },2000)
        }
    })
        
    };

    isImage=(name)=>{

      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

      if(allowedExtensions.exec(name)){
        return true;
      }else{

        return false;
      }
    }
    

    imagePreview=(event)=>{
     
      
      if(event.target.files.length > 0){

        let file = event.target.files[0];

        if(this.isImage(file.name)){

          let preview = URL.createObjectURL(event.target.files[0])
          this.setState({
            file: Object.assign({preview}, file)
          })   
        }
        // console.log(file);
        
      }
    }

    render() {
        let { editProfileState } = this.props;
        return (
          <div className="album pt-3">
          <div className="container-fluid">
          <Heading headingClass="bg-light" headingText="Edit Profile"/>
          <hr />
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {/* {JSON.stringify(ChangePassword)} */}
                    {/* <span style={{ color: addCategoryState.isSuccess ? 'green' : 'red' }} > { addCategoryState.msg }</span> */}
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name:'',
                  }}
                  onSubmit={this.editProfileForm}
                  validationSchema={VALIDATORS.EDITPROFILE}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Name"
                            inputClass="form-control"
                            type="text"
                            placeholder="Name"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                         <div className="upload-img">
                         <InputBox
                            formWrapClass = "form-group"
                            labelText="Image"
                            inputClass="form-control"
                            type="file"
                            placeholder="Image"
                            name = "profileImage"
                            handleChange= {this.imagePreview}
                          />
                         </div>

                         {/* <input type="file" name="image" onChange = {this.imagePreview} value=""/>  */}
                         
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="submit"
                        btnText="Submit"
                      />
                      </div>
                     
                  </form>
                )}
                  />
                    
                </div>
                <div className="col-md-6 text-center">
                  <div className="img-placeholder">
                    <img className="wh-174-194" src={this.state.file.preview}/>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>

            
        )
    }
}

export default EditProfile = connect(mapStateToProps, mapDispatchToProps)(EditProfile);