import React, { Component } from "react";
import {Tabs,Tab} from "react-bootstrap";
import {Bestsellers,MostViewedProducts,NewCustomers,Customers,} from './index';


class DashboardStoreStats extends Component {

    render() {
        return (
           <div className="customer-report-main">
               <Tabs defaultActiveKey="bestsellers" id="uncontrolled-tab-example">
                    <Tab eventKey="bestsellers" title="All New Order">
                       <Bestsellers item={this.props.item}/>
                    </Tab>
                    <Tab eventKey="most-viewed-products" title="New Reject Order">
                       <MostViewedProducts item={this.props.item} />
                    </Tab>
                    <Tab eventKey="new-customers" title="New Confirmed Order">
                       <NewCustomers item={this.props.item}/>
                    </Tab>
                    {/* <Tab eventKey="Customers" title="Customers">
                       <Customers item={this.props.item}/>
                    </Tab> */}
                </Tabs>
           </div>
        );
    }
}

export default DashboardStoreStats 
