import { ACTIONS } from "../action-constants/Actions";

const UserForgeting = () => {
  return {
    type: ACTIONS.FORGET_PASSWORD.FORGETING,
    payload: { isForget: true }
  };
};

const UserForgeted = result => {
  return {
    type: ACTIONS.FORGET_PASSWORD.FORGETED,
    payload: { isForget: false, result }
  };
};

const UserForgetingFail = error => {
  return {
    type: ACTIONS.FORGET_PASSWORD.FORGETING_FAILED,
    payload: { isForget: false, error: error }
  };
};



export const userForget = {
  UserForgeting,
  UserForgeted,
  UserForgetingFail,
};
