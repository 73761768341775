import { ACTIONS } from "../action-constants/Actions";

const UserListFetching = () => {
  return {
    type: ACTIONS.USER_LIST.USER_LIST_FETCHING,
    payload: { isUserListFetching: true }
  };
};

const UserListFetched = result=> {
  return {
    type: ACTIONS.USER_LIST.USER_LIST_FETCHED,
    payload: { isUserListFetching: false ,result}
  };
};

const UserListFailed = error => {
  return {
    type: ACTIONS.USER_LIST.USER_LIST_FAILED,
    payload: { isUserListFetching: false }
  };
};



export const userList = {
  UserListFetching,
  UserListFetched,
  UserListFailed,
};
