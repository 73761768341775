import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { passwordChanging } from "../actions/ChangePassword";

// console.log(PasswordChanging)

export const ChangePasswordApi = form => {
  // console.log(form);
  return (dispatch, getState) => {
    // console.log(dispatch,'di');

    dispatch(passwordChanging.PasswordChanging());

    return _callApi(
      END_POINT.CHANGEPASSWORD.END_POINT,
      END_POINT.CHANGEPASSWORD.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      // console.log(res);
      if ( Object.keys(result).length === 0) {
        dispatch(passwordChanging.PasswordChangingFailed(message));
        return res.data;
      } else {
        localStorage.setItem("user", JSON.stringify(result));
        //console.log(localStorage.getItem("user"));
        dispatch(passwordChanging.PasswordChanged({result, message}));
        return res.data;
      }
    });
  };
};
