import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Seminar } from "../../actions/SeminarResult/seminarResult";

export const SeminarApi = form => {
  return (dispatch, getState) => {
    dispatch(Seminar.SeminarFetching());
    return _callApi(
      END_POINT.RESULT.SEMINAR_RESULT.END_POINT,
      END_POINT.RESULT.SEMINAR_RESULT.METHOD,
      form
    ).then(res => {
      if(form){
        res.data.result.filter = form;
      }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Seminar.SeminarFailed({result,message}));
        return res.data;
      }else {
        dispatch(Seminar.SeminarFetched({result,message}));
        return res.data;
      }
    });
  };
};
