import React from 'react'
import { Dropdown } from 'semantic-ui-react'

// import { countryOptions } from '../common'

const SelectBox = ({ formWrapClass,labelClass,labelText, selectClass, name, options, handleChange,errorClass,errorText, selected = ""}) => {


    const option = options.map((obj) =>
        <option key={obj.key} value={obj.value} selected={obj.value == selected}>{obj.text}</option> 
    );
    return (
        <div className={formWrapClass}>
            <label className={labelClass}>{labelText}</label>
            <select onChange={handleChange} name={name} className={selectClass}>
                <option selected disabled >select</option>
                {option}
            </select>
             <span className={errorClass}>{errorText}</span> 
        </div>
    )
}

export default SelectBox

