import { ACTIONS } from "../../action-constants/Actions";

const SeminarUserListFetching = () => {
  return {
    type: ACTIONS.SEMINAR_USER_LIST.SEMINAR_USER_LIST_FETCHING,
    payload: { isSeminarUserListFetching: true }
  };
};

const SeminarUserListFetched = result=> {
  return {
    type: ACTIONS.SEMINAR_USER_LIST.SEMINAR_USER_LIST_FETCHED,
    payload: { isSeminarUserListFetching: false ,result}
  };
};

const SeminarUserListFailed = error => {
  return {
    type: ACTIONS.SEMINAR_USER_LIST.SEMINAR_USER_LIST_FAILED,
    payload: { isSeminarUserListFetching: false }
  };
};



export const SeminaruserList = {
    SeminarUserListFetching,
    SeminarUserListFetched,
    SeminarUserListFailed,
};
