import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Heading,InputBox,LoadingComponent } from "../../commonComponents";
import { GetUserDetailsApi } from '../../../../store/api-actions/GetUserDetailsApi';
// import { GetUserDetailsApi } from '../../../../store/api-actions/GetUserDetailsApi';
import {Tabs,Tab} from "react-bootstrap";
import {SeminarResult,TestAttempt} from './index';
import PaymentHistory from './PaymentHistory';

const mapDispatchToProps = (dispatch)=>{

    return {
        userDetails: (reqObj)=>dispatch(GetUserDetailsApi(reqObj))
    }
}

const mapStateToProps = (state)=>{

    return {
        userDetailsState: state.userDetails
    }
}

class UserDetail extends Component {

    constructor(props){
        super(props);
        this.state={
          isLoading:false
        }
    }
    componentDidMount(){
        this.setState({isLoading:true})
        let { match } = this.props
        let reqObj = {};
        if(match.params.id){
          reqObj.userId	= match.params.id;
        }
        this.props.userDetails(reqObj).then(res=>{
          const { message = null, result = null } = res;
          this.setState({isLoading:false})
          console.log("####$$$$$$$",this.props)
        })
    }
    
    render() {
        let {result} = this.props.userDetailsState;
        var today = new Date(result.created);
        var dd = today.getDate();

        var mm = today.getMonth()+1; 
        var yyyy = today.getFullYear();
        if(dd<10) 
        {
            dd='0'+dd;
        } 

        if(mm<10) 
        {
            mm='0'+mm;
        } 

        var loader = "";
        if(this.state.isLoading){
          loader = <LoadingComponent/>
        }
        return (
            <div className="album pt-3">
              
            <div className="container-fluid">
            <Heading  headingText="View User Details"/>
            <hr/>
            <div className="container">
            {loader}
              <div className="row">
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="User Name"
                            inputClass="form-control"
                            type="text"
                            name = "name"
                            inpuReadOnly = {true}
                            value={result.userName}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="First Name"
                            inputClass="form-control"
                            type="text"
                            name = "name"
                            inpuReadOnly = {true}
                            value={result.firstName}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Middle Name"
                            inputClass="form-control"
                            type="text"
                            name = "name"
                            inpuReadOnly = {true}
                            value={result.middleName}
                          />
                        </div>   
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Last Name"
                            inputClass="form-control"
                            type="text"
                            name = "name"
                            inpuReadOnly = {true}
                            value={result.lastName}
                          />
                        </div>    
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Email"
                            inputClass="form-control"
                            type="email"
                            name = "email"
                            inpuReadOnly = {true}
                            value={result.email}
                          />
                        </div>
                          
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Phone"
                            inputClass="form-control"
                            type="text"
                            name = "contactNo"
                            inpuReadOnly = {true}
                            value={result.phoneNumber}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Address1"
                            inputClass="form-control"
                            type="text"
                            name = "address1"
                            inpuReadOnly = {true}
                            value={result.address1}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Address2"
                            inputClass="form-control"
                            type="text"
                            name = "address2"
                            inpuReadOnly = {true}
                            value={result.address2}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="City"
                            inputClass="form-control"
                            type="text"
                            name = "city"
                            inpuReadOnly = {true}
                            value={result.city}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="State"
                            inputClass="form-control"
                            type="text"
                            name = "state"
                            inpuReadOnly = {true}
                            value={result.state}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Zip Code"
                            inputClass="form-control"
                            type="text"
                            name = "zipCode"
                            inpuReadOnly = {true}
                            value={result.zipCode}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="DOB"
                            inputClass="form-control"
                            type="text"
                            name = "dob"
                            inpuReadOnly = {true}
                            value={mm+'/'+dd+'/'+yyyy}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="School Name"
                            inputClass="form-control"
                            type="text"
                            name = "schoolName"
                            inpuReadOnly = {true}
                            value={result.schoolName}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Driver License"
                            inputClass="form-control"
                            type="text"
                            name = "driverLicense"
                            inpuReadOnly = {true}
                            value={result.driverLicense}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="School Instructor"
                            inputClass="form-control"
                            type="text"
                            name = "schoolInstructor"
                            inpuReadOnly = {true}
                            value={result.schoolInstructor}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="School Operator"
                            inputClass="form-control"
                            type="text"
                            name = "schoolOperator"
                            inpuReadOnly = {true}
                            value={result.schoolOperator}
                          />
                          
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <div className="customer-report-main">
                      <Tabs defaultActiveKey="user-products" id="uncontrolled-tab-example">
                          <Tab eventKey="user-products" title="Seminar Result">
                             <SeminarResult studentDetails={result}/>
                          </Tab>
                          <Tab eventKey="use-rorder" title="Total Test Attempt">
                            <TestAttempt/>
                          </Tab>
                          <Tab eventKey="use-payment" title="Payment History">
                          <PaymentHistory/>
                          </Tab>
                          
                      </Tabs>
                  </div>
              </div>
            </div>
          </div>
        )
    }
}

export default  UserDetail = connect(mapStateToProps, mapDispatchToProps)(UserDetail);