import React, { Component } from "react";

export default class Register extends Component {
  render() {
    return (
      <div className="auth-wrapper d-flex align-items-center justify-content-center">
        <div className="auth-inner-wrapper">
          <div className="auth-card">
            <div className="auth-header">
              <div className="auth-logo">
                {/* <img src={"img"} alt={"img"} /> */}
                <h3>Register</h3>
              </div>
            </div>
            <div className="auth-body">
              <div className="form-group auth-form-field">
                <div className="auth-label">Email</div>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group auth-form-field">
                <div className="auth-label">Password</div>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group auth-form-field">
                <div className="auth-label">Confirm Password</div>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group auth-form-field">
                <button type="submit" className="btn-primary btn btn-block">
                  Submit
                </button>
              </div>
            </div>
            <div className="auth-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}
