
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import { Link } from 'react-router-dom';
import IndiaFlag from '../../../../assets/images/IndiaFlag.png';
import Arabic from '../../../../assets/images/Arabic.jpg'


class Languages extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Languages"/>
            </div>
            <div className="col-md-8">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add Language"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>Default</th>
                  <th>Language</th>
                  <th>Icon</th>
                  <th>Code</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                    <label>
                        <input type="radio" name="languages_id" value="1" class="default_language" checked=""/>
                    </label>
                    </td>
                    <td>English</td>
                    <td>
                        <img src={IndiaFlag} width="32" className="IndiaFlag" alt="India Flag"/>
                    </td>
                    <td>En</td>
                    <td> 
                        <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <label>
                        <input type="radio" name="languages_id" value="1" class="default_language" checked=""/>
                    </label>
                    </td>
                    <td>عربى</td>
                    <td>
                    <img src={Arabic} width="32" className="Arabic" alt="Arabic"/>
                    </td>
                    <td>Ar</td>
                    <td> 
                        <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </Link>
                        <Link className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Languages 