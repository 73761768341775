import { ACTIONS } from "../../action-constants/Actions";

export const paymentReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.RESULT.HISTORY.HISTORY_FETCHING:{
            let newState = { ...{}, ...state };
            newState.isPaymentHistoryFetching=false
            newState.list=[];
            return newState
        }
        case ACTIONS.RESULT.HISTORY.HISTORY_FETCHED:{
            let newState = { ...{}, ...state };
            let { result } = action.payload;
            newState.isPaymentHistoryFetching=false
            newState.list =  result
            return newState;
        }
        case ACTIONS.RESULT.HISTORY.HISTORY_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.isPaymentHistoryFetching=false
            newState.list=[];
            return newState
        }
        default:{
            return state;
        }
    }
}