
import React, { Component } from 'react';
import { Heading,Button,InputBox,TextArea,SelectBox } from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Link } from 'react-router-dom';

class WebSettings extends Component {
  
  render() {
    const options = [
        { key:'0', value: '0', text: 'style 1' },
        { key:'1', value: '1', text: 'style 2' },
        { key:'1', value: '2', text: 'style 3' },
      ];
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Website Settings"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-6">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                        <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Home Style"
                            name = "homestyle"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                          <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Home Colors"
                            name = "homecolors"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                          <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Show Sitename or Logo"
                            name = "showsitenameorlogo"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                        <InputBox
                            formWrapClass = "form-group"
                            labelText="Website Name"
                            inputClass="form-control"
                            placeholder="Please enter Website Name."
                            type="text"
                            name = "websitename"
                            handleChange={handleChange}
                            value={values.websitename}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Facebook URL"
                            inputClass="form-control"
                            placeholder="Please enter Facebook URL."
                            type="text"
                            name = "facebookurl"
                            handleChange={handleChange}
                            value={values.facebookurl}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Google URL"
                            inputClass="form-control"
                            placeholder="Please enter Google URL."
                            type="text"
                            name = "googleurl"
                            handleChange={handleChange}
                            value={values.googleurl}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Twitter URL"
                            inputClass="form-control"
                            placeholder="Please enter Twitter URL."
                            type="text"
                            name = "twitterurl"
                            handleChange={handleChange}
                            value={values.twitterurl}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Linked URL"
                            inputClass="form-control"
                            placeholder="Please enter Linked URL."
                            type="text"
                            name = "linkedurl"
                            handleChange={handleChange}
                            value={values.linkedurl}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="Update"
                        btnText="Update"
                      />
                      <Link to="#" className="btn btn-default" type="button">
                          Back
                      </Link>
                      </div>
                    </form>
                )}
                />
          </div>
            <div className="col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WebSettings 