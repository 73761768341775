import React, { Component } from "react";
import {Table} from "react-bootstrap";


class MostViewedProducts extends Component {

    render() {
        var list = this.props.item.newOrder?this.props.item.newOrder.data:[];
        return (
            <div className="customer-report">
                <Table responsive>
                <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Product</th>      
                            <th>Days</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    { list.map((item, index) => ( //reject order
                        item.bookingStatus==3?(
                            <tr>
                                <td>{item.title}</td>
                                <td>{item.fromUserDetails.name}</td>
                                <td>{item.days}</td>
                                <td>₹{item.totalPrice}</td>
                            </tr>
                        ):("")
                        
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

export default MostViewedProducts 
