import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { categoryDelete } from "../../actions/Category/DeleteCategory";

export const DeleteCategoryApi = form => {
  return (dispatch, getState) => {
    dispatch(categoryDelete.CategoryDeleting());
    return _callApi(
      END_POINT.CATEGORY.CATEGORYDELETE.END_POINT,
      END_POINT.CATEGORY.CATEGORYDELETE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(categoryDelete.CategoryDeletingFailed({result,message}));
        return res.data;
      } else {
          dispatch(categoryDelete.CategoryDeleted({result,message}));
        return res.data;
      }
    });
  };
};
