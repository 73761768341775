import React, { Component } from 'react'
import { connect } from "react-redux";
import { PaymentHistoryApi } from "../../../../store/api-actions/paymentHistory/PaymentHistory";
import { withRouter } from "react-router";
import {COMMON_FUNCTION} from '../../../../constants/commonFunction';




const mapDispatchToProps = dispatch => {
  return {
    PaymentHistory: (reqData)=>dispatch(PaymentHistoryApi(reqData))
};
};

const mapStateToProps = state => {
  return {
    paymentHistoryState: state.paymentHistory
  };
};

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:"",
          alertMessage:""
        };
      }
      componentDidMount() {
        let reqData = {};
        console.log("%%%%%%%%%%%%%%%",this.props)
        reqData.userId = this.props.match.params.id
        this.props.PaymentHistory(reqData).then(res=>{})
      }

      
      

      render() {
         
        let { list} = this.props.paymentHistoryState;
        var loader = "";
        
        return (
          <div className="album pt-3">
           <div className="container-fluid">
              <div className="table-responsive postion-relative">
                { loader } 
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>seminarId</th>
                      <th>Transaction Id</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list?.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item?.seminarId}</td>
                        <td>{item?.transactionId}</td>
                        <td>$45</td>
                        <td>{item?.status}</td>
                        <td>{COMMON_FUNCTION.parseDate(item?.created)}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
                 
              </div>
            </div>
          </div>
        );
      }
}
export default PaymentHistory = withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentHistory));