import { VALIDATORS } from "./validation-schemas";
import _moment from "moment";

export const COMMON_FUNCTION={
    imagePreview:(event)=>{
        if(event.target.files.length > 0){
          let file = event.target.files[0];
          console.log("890890890",file)
          if(VALIDATORS.isImage(file.name)){
            let preview = URL.createObjectURL(event.target.files[0])
            return {file: Object.assign(preview, file),s3file : file}
          }else{
            return {file: "",s3file : ""}
          }
        }
    },
    filterArray:(data,key,val)=>{
      console.log(data,key,val)
      data.find(function(item, i)
      { 
        return +item.key == val;
      })
    },
    parseDate:(ms)=>{
      return _moment(ms).format('MM/DD/YYYY');
    }
     
}
