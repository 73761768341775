import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { User } from "../actions/User/User";

export const GetUserDetailsApi = form => {
  return (dispatch, getState) => {
    dispatch(User.UserDetailFetching({ result:{}, msg:'' }));
    return _callApi(
      END_POINT.USER.USER_DETAIL.END_POINT,
      END_POINT.USER.USER_DETAIL.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      console.log("34555555555555",res)
      if ( Object.keys(result).length === 0) {
        dispatch(User.UserDetailFailed({ result:{}, msg:message }));
        return res.data;
      } else {
        dispatch(User.UserDetailFetched({ result:result, msg:message }));
        return res.data;
      }
    });
  };
};
