import React, { Component } from "react";
import {Table} from "react-bootstrap";


class Customers extends Component {
    render() {
        return (
            <div className="customer-report">
                 <Table responsive>
                    <thead>
                        <tr>
                        <th>Customer</th>    
                        <th>Orders</th>
                        <th>Average</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Avinash Gupta</td>
                        <td>2</td>
                        <td>₹300.00</td>
                        <td>₹600.00</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default Customers 
