import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
// let UserLogin = require("../actions/UserLogin")
import { userLogin } from "../actions/UserLogin";

export const LoginApi = form => {
  console.log(form);
  return (dispatch, getState) => {
    // console.log(dispatch,'di');

    dispatch(userLogin.UserLogging());

    return _callApi(
      END_POINT.LOGIN.END_POINT,
      END_POINT.LOGIN.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
       
      if ( Object.keys(result).length === 0) {
        dispatch(userLogin.UserLogInFail(message));
        return res.data;
      } else {
        localStorage.setItem("user", JSON.stringify(result));
        console.log(localStorage.getItem("user"));
        dispatch(userLogin.UserLoggedIn(result));
        return res.data;
      }
    });
  };
};
