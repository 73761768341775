import React, { Component } from 'react'
import { _callApi } from '../../../../services/baseService';
import { END_POINT } from '../../../../constants/apiEndPoints';
import { connect } from "react-redux";
import { Heading, LoadingComponent, Button } from '../../commonComponents';
import { QuestionListApi } from "../../../../store/api-actions/question/QuestionListApi";
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import { DeleteQuestionApi } from "../../../../store/api-actions/question/DeleteQuestionApi";
import { swal } from '../../../../constants/confirm-alert';
import { MESSAGE } from '../../../../constants/confirmMessage';
import { Link } from 'react-router-dom';
import { COMMON_FUNCTION } from '../../../../constants/commonFunction';

const mapDispatchToProps = dispatch => {
  return {
    questionList: (reqData) => dispatch(QuestionListApi(reqData)),
    deleteQuestion: (reqData) => dispatch(DeleteQuestionApi(reqData))
  };
};

const mapStateToProps = state => {
  console.log(state, "states in questinos");
  return {
    questionListState: state.questionList,
    deleteQuestionState: state.deleteQuestion
  };
};

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWarningConfirMsg: false,
      isSuccessConfirMsg: false,
      _id: "",
      isLoading: true
    };
  }
  componentDidMount() {
    this.props.questionList().then(res => {
      const { message = null, result = null } = res;
    })
  }

  handlePageClick = data => {
    let reqData = {};
    if (data && !isNaN(data.selected)) {
      reqData.pageNo = parseInt(data.selected) + 1;
    }
    if (data && data.target && data.target.value) {
      reqData.name = data.target.value;
    }
    this.props.questionList(reqData).then(res => {
      const { message = null, result = null } = res;
    })
  };

  handleDelete = data => {
    let reqData = {};
    if (data._id) {
      reqData.questionId = data._id;
    }
    this.props.deleteQuestion(reqData).then(res => {
      const { message = null, result = null } = res;
      this.setState({ isWarningConfirMsg: false, isSuccessConfirMsg: true, _id: "" });
    })
  };
  redirectToAdd = () => {
    let { history } = this.props
    history.push(`/portal/question-add`);
  }

  redirectToEdit = (data) => {
    let { history } = this.props
    history.push(`/portal/question-edit/${data._id}`)
  }


  cancelDelete = () => {
    this.setState({ isWarningConfirMsg: false, _id: "" });
  };
  confirmMsg = data => {
    this.setState({ isWarningConfirMsg: true, _id: data._id });
  };

  render() {
    let conm = "";
    console.log(this.props.questionListState, "this.props.questionListState");
    let { list, totalCount } = this.props.questionListState;
    /**=========== alert confirmation========= */
    if (this.state.isWarningConfirMsg) {
      var obj = {
        isWarningConfirMsg: this.state.isWarningConfirMsg,
        confirmBtnText: MESSAGE.CONFIRM_BTN_TEXT,
        title: MESSAGE.WARNING_TITLE,
        onConfirm: () => {
          this.handleDelete(this.state)
        },
        onCancel: () => {
          this.cancelDelete(this.state)
        },
        subtitle: MESSAGE.WARNING_QUESTION_SUB_TITLE
      }
      conm = swal.warning(obj);
    }

    if (this.state.isSuccessConfirMsg) {
      var obj = {
        title: MESSAGE.SUCCESS_TITLE,
        onConfirm: () => {
          this.setState({ isSuccessConfirMsg: false });
          this.handlePageClick();

        },
        subtitle: MESSAGE.DELETE_QUESTION_SUB_TITLE
      }
      conm = swal.success(obj);
    }
    var loader = "";
    if (this.state.isLoading) {
      loader = <LoadingComponent />
    }
    /**=========== alert confirmation========= */
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <Heading headingText="QUESTIONS LIST" />
          <hr />
          <div className="row">
            <div className="col-md-4">
              
              <Button
            btnType="button"
            btnClass="btn btn-primary"
            btnEventClick={this.redirectToAdd}
            btnText="Add Question"
          />
            </div>
          </div>

          {conm}
          <hr />
          <div className="table-responsive">
            {loader}
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Seminar</th>
                  <th>Title</th>
                  <th>Option 1</th>
                  <th>Option 2</th>
                  <th>Option 3</th>
                  <th>Option 4</th>
                  <th>Answer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.seminarId}</td>
                    <td>{item.title}</td>
                    <td>{item.option1}</td>
                    <td>{item.option2}</td>
                    <td>{item.option3}</td>
                    <td>{item.option4}</td>
                    <td>{item.answer}</td>
                    {/* <td>{COMMON_FUNCTION.parseDate(item.created)}</td> */}
                    <td>
                      <Link onClick={() => {
                        this.redirectToEdit(item)
                      }} className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-edit"></i>
                      </Link>
                      <Link onClick={() => {
                        this.confirmMsg(item)
                      }} className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                        <i className="fa fa-trash"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
              nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalCount / END_POINT.LIMIT}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'text-center pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Question = connect(mapStateToProps, mapDispatchToProps)(Question);