import { ACTIONS } from "../../action-constants/Actions"


const GetCategoryDetailsFetching=()=>{
    return {
        type: ACTIONS.CATEGORY.GETCATEGORYDETAILS.GETCATEGORYDETAILS_FETCHING,
        payload:{
            isCategoryDetailsFetching:true,
            
        }
    }
}

const GetCategoryDetailsFailed=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.GETCATEGORYDETAILS.GETCATEGORYDETAILS_FAILED,
        payload:{
            isCategoryDetailsFetching:false,
            result,
            msg
        }
    }
}

const GetCategoryDetailsFetched=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.GETCATEGORYDETAILS.GETCATEGORYDETAILS_FETCHED,
        payload:{
            isCategoryDetailsFetching:false,
            result:result,
            message:msg
        }
    }
}


export const GetCategoryDetails = {
    GetCategoryDetailsFetching,
    GetCategoryDetailsFailed,
    GetCategoryDetailsFetched
}