import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { editCategory } from "../../actions/Category/EditCategory";

export const EditCategoryApi = form => {
  return (dispatch, getState) => {
    dispatch(editCategory.CategoryEditing());
    return _callApi(
      END_POINT.CATEGORY.EDITCATEGORY.END_POINT,
      END_POINT.CATEGORY.EDITCATEGORY.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
     
      if ( Object.keys(result).length === 0) {
        
        dispatch(editCategory.CategoryEditingFailed({result,message}));
        return res.data;
      } else {
        dispatch(editCategory.CategoryEdited({result}));
        return res.data;
      }
    });
  };
};
