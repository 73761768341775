
import React, { Component } from 'react';
import { Heading,Button,InputBox,SelectBox,TextArea } from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import IndiaFlag from '../../../../assets/images/IndiaFlag.png'
//import { Dropdown } from "react-bootstrap";
//import { Link } from 'react-router-dom';

class SendNotifications extends Component {
  
  render() {
    const options = [
        { key:'0', value: '0', text: 'All' },
        { key:'1', value: '1', text: 'IOS' },
        { key:'2', value: '2', text: 'Android' },
        { key:'3', value: '3', text: 'Website' },
      ];
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Send Notification"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-6">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                        <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Devices"
                            name = "devices"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                          <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Status"
                            name = "status"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                        <InputBox
                            formWrapClass = "form-group"
                            labelText="Title"
                            inputClass="form-control"
                            type="text"
                            placeholder="Title"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <div class="upload-img">
                            <InputBox
                                formWrapClass = "form-group"
                                labelText="Image"
                                inputClass="form-control"
                                type="file"
                                placeholder="Image"
                                name = "image"
                                handleChange= {(e)=>{
                                let imgData = COMMON_FUNCTION.imagePreview(e);
                                this.setState({
                                    file: imgData.file,
                                    s3file : imgData.s3file
                                });
                                }}
                            />
                          </div>
                          <TextArea
                          formWrapClass = "form-group"
                          labelText="Message"
                          inputClass="form-control"
                          name = "message"
                          handleChange={handleChange}
                          value={values.description}
                          errorClass = "text-danger"
                          errorText = { touched.description && errors.description }
                          /> 
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="submit"
                        btnText="Submit"
                      />
                      </div>
                    </form>
                )}
                />
          </div>
            <div className="col-md-6 text-center">
                <div className="img-placeholder">
                <img className="wh-174-194" src={IndiaFlag}/>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SendNotifications 