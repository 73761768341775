
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import { Link } from 'react-router-dom';
import bollywoodkart from '../../../../assets/images/bollywoodkart.jpg';



class Manufacturers extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Manufacturers"/>
            </div>
            <div className="col-md-8">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add New Manufacturer"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Other Info</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                      1
                    </td>
                    <td>Bollywoodkart</td>
                    <td>
                        <img src={bollywoodkart} width="100" className="bollywoodkart" alt="Bollywoodkart"/>
                    </td>
                    <td>http://bollywoodkart.com/</td>
                    <td> 
                        <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </Link>
                        <Link className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Manufacturers 