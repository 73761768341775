
import React, { Component } from 'react';
import { Heading,Button ,InputBox,SelectBox,TextArea,LoadingComponent} from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { connect } from "react-redux";
import { GetMenuApi } from "../../../../store/api-actions/menu/GetMenuApi";
import { GivePermissionApi } from "../../../../store/api-actions/subadmin/GivePermissionApi";
import { withRouter } from 'react-router-dom';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';

const mapDispatchToProps = dispatch => {
  return {
    GetMenu: (reqObj)=>dispatch(GetMenuApi(reqObj)),
    GivePermission: (reqObj)=>dispatch(GivePermissionApi(reqObj)),
   };
};

const mapStateToProps = state => {
  return {
    menuListState: state.menuList
  };
};

class ManageRoles extends Component {
  
  constructor(props){
      super(props);
      this.state={
        menuId:[],
        formFields: {},
        errors: {},
        message: null,
        messageElement: false,
        success: false,
        file:"",
        s3file:"",
        isSuccessConfirMsg:false,
        isLoader:false
      }

  }

  componentDidMount(){
      let { match } = this.props
      console.log("===0000000000000",match)
      let reqObj = {};
      reqObj.userId = match.params.id
      this.props.GetMenu(reqObj).then(res=>{
        const { message = null, result = null } = res;
        let {list} = this.props.menuListState
        //this.props.menuListState = COMMON_FUNCTION.filterArray(result,"isAllow",1)
        this.setState({"menuId":(list.filter(function(item, i){ return +item.isAllow == 1;})).map(a => a._id)})
      })
      
  }

  permisionHandler=(event)=>{
    console.log('handleChange', event.target.checked,event.target.value);
    if(event.target.checked){
      console.log(this.state.menuId)
      let mm = this.state.menuId
      let manuId = mm.push(event.target.value)
      this.setState({menuId:mm})
      console.log("===00000000====",mm)
    }else{
      let mm = this.state.menuId
      var ty = mm.filter(function(e) { return e !== event.target.value })
      this.setState({menuId:ty})
      console.log("[[[]]]][[[[[]]]]]]",ty)
    }
     
  }

  handleSubmit = (event)=>{
    this.setState({isLoader:true});
    var reqObj = {}
    let { match } = this.props
    reqObj.userId = match.params.id
    reqObj.menuId = this.state.menuId
    this.props.GivePermission(reqObj).then(res=>{
        this.props.GetMenu(reqObj).then(res=>{
          const { message = null, result = null } = res;
          this.setState({isLoader:false,isSuccessConfirMsg:true});
          let {list} = this.props.menuListState
          this.setState({"menuId":(list.filter(function(item, i){ return +item.isAllow == 1;})).map(a => a._id)})
        })  
    })
    event.preventDefault()
    
  }

  render() {

    let {list} = this.props.menuListState
    let loader = "";
        let conm = "";
        if(this.state.isLoader){
          loader = <LoadingComponent/>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              //this.redirectTo();
    
            },
            subtitle:MESSAGE.PERMISSION
          }
          conm = swal.success(obj);
        }
    return (
      <div className="album pt-3">
        {loader}
        {conm}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Manage Roles"/>
            </div>
            <div className="col-md-8">
                {/* <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add Admin Type"/> */}
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="container">
                  <form onSubmit={this.handleSubmit}>
                      <div className="row">
                      { list.map((item, index) => (
                        <div className="col-md-3">
                          <div className="custom-control custom-checkbox">
                            <input onChange={this.permisionHandler} type="checkbox" checked={(this.state.menuId).includes(item._id)}  value={item._id} className="custom-control-input" name="menuId[]" id={item._id}/>
                            <label className="custom-control-label" for={item._id}>{item.name}</label>
                          </div>
                        </div>
                      ))}
                      </div>
                      <div className="clearfix">
                        &nbsp;
                      </div>
                      <div className="form-group auth-form-field">
                        <Button
                          btnClass="btn btn-primary"
                          btnType="submit"
                          btnText="Submit"
                        />
                      </div>
                      <div className="clearfix">
                        &nbsp;
                      </div>
                  </form>
          </div>
        </div>
      </div>
    );
  }
}
export default ManageRoles = withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageRoles));