import { ACTIONS } from "../../action-constants/Actions"


const AttemptFetching=()=>{

    return {
        type: ACTIONS.RESULT.ATTEPMT.ATTEPMT_FETCHING,
        payload:{
            isAttemptFetching:true,
        }
    }
}

const AttemptFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.ATTEPMT.ATTEPMT_FAILED,
        payload:{
            isAttemptFetching:false,
            result,
            msg
        }
    }
}

const AttemptFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.ATTEPMT.ATTEPMT_FETCHED,
        payload:{
            isAttemptFetching:false,
            result:result,
            message:msg
        }
    }
}

export const Attempt = {
    AttemptFetching,
    AttemptFailed,
    AttemptFetched
}