import React, { Component } from "react";
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { LoginApi } from "../../../../store/api-actions/LoginApi";
import logo from '../../../../assets/images/logo.png';

const mapDispatchToProps = dispatch => {
  return {
    login: form => dispatch(LoginApi(form))
  };
};

const mapStateToProps = state => {
  return {
    loginState: state.login
  };
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false
    };
  }

  loginForm = (values, formikBag)=> {
    this.props.login(values).then(res=>{
      const { error = null, result = null } = res;
      if ( Object.keys(result).length  > 0) {
        setTimeout(()=>{
          this.props.history.push('/')
        },1000)
      }
    })
      
  };

  render() {

    let { loginState } = this.props;

    return (
      <div className="auth-wrapper d-flex align-items-center justify-content-center">
        <div className="auth-inner-wrapper loginpage">
          <div className="auth-card">
            <div className="auth-header">
              <div className="auth-logo">
                 <img src="https://drivinginstructorlicensecourse.com/static/media/Advantage-Circle-Logo.f1eb15a6.png" className="logo-img" alt="dsac" />
              </div>
            </div>
            <div className="auth-body">

  {/* {JSON.stringify(loginState)} */}
              <Formik
              initialValues={{
                email:'',
                password:'',
              }}
              onSubmit={this.loginForm}
              validationSchema={VALIDATORS.LOGIN}
              render={({ handleSubmit, errors, touched, values, handleChange })=>(
                <form onSubmit={handleSubmit}>

                <span style={{ color: loginState.isSuccess ? 'green' : 'red' }} > { loginState.msg }</span>
                <div className="form-group auth-form-field">
                  <div className="auth-label">Email</div>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    className="form-control"
                    // required
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    { touched.email && errors.email }
                  </small>
                </div>
                <div className="form-group auth-form-field">
                  <div className="auth-label">Password</div>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    // required
                    value={values.password}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                  { touched.password && errors.password }
                  </small>
                </div>
                <div className="form-group auth-form-field">
                  <button 
                  disabled={loginState.isLogging}
                  type="submit" className="btn-primary btn btn-block">
                    Submit
                  </button>
                </div>
              </form>
              )}
              />
              
            </div>
            <div className="auth-footer">
              <Link to="/forgot-password" className="d-block ">Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login = connect(mapStateToProps, mapDispatchToProps)(Login);
