import { ACTIONS } from "../action-constants/Actions";

export const userDetailsReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.USER_DETAIL.USER_DETAIL_FETCHING:{

            let newState = { ...{}, ...state };

            newState.isSuccess = false
            return newState
        }
        case ACTIONS.USER_DETAIL.USER_DETAIL_FETCHED:{

            let newState = { ...{}, ...state };

            let { result, message } = action.payload;

            newState.isSuccess = true
            newState.msg = message
            newState.result =  result; 
            //console.log("-00000000000-----------",result)

            return newState;
        }
        case ACTIONS.USER_DETAIL.USER_DETAIL_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false

            newState.msg = error;

            return newState
        }
        default:{
            return state;
        }
    }
}