import axios from "axios";
import { END_POINT } from "../constants/apiEndPoints";

export const _callApi = (url, type, data) => {
  let user = null;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  if(data && data.accessToken){
    console.log("====**((()))",data)
    user = data
  }
  // set header conditionally in the api axios method 
  let header = user
    ? {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
        accessToken: user.accessToken
      }
    : { "Content-Type": "application/json" };

  

  // let header = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZDc5NGNlYTU2MWMzNDJlOWY0NGRhYzUiLCJlbWFpbCI6Im1hamlkazA2MUBnbWFpbC5jb20iLCJpYXQiOjE1NjgyNjYxMjd9.QAR688D_2ucV4PI5I9L3c297kRkNl1dy-cEEU2VZfZ0",
  //   accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZDc5NGNlYTU2MWMzNDJlOWY0NGRhYzUiLCJlbWFpbCI6Im1hamlkazA2MUBnbWFpbC5jb20iLCJpYXQiOjE1Njg0NTI5MzR9.ObR4dCX_OveuGNtMTfte-WZ0S83C7etWoUbJdW3OHhQ"
  // }
  axios.create({
    // baseURL: "https://jsonplaceholder.typicode.com/",
    baseURL: END_POINT.BASE_URL,
    responseType: "json"
  });

  if (type === 2) {
    console.log("header", data);
   if(data && data.pageNo){
     data.limit=END_POINT.LIMIT
   }else if(data){
     data.limit = END_POINT.LIMIT;
     data.pageNo = END_POINT.PAGENUMBER
   }else{
      var data ={limit:END_POINT.LIMIT,pageNo:END_POINT.PAGENUMBER}
   }

   return axios
     .get(`${END_POINT.BASE_URL}${url}`,
     {
       params:data,
       headers: header
     })
     .then(res => {
       console.log("@@@@@@@@&&&&&&&&&",res)
       if(res.data.message=="Unauthorized access "){
         console.log("*******&&&&&&&&&")
         window.location = END_POINT.APP_BASE;
         localStorage.removeItem("dmvUser");

       }
       return res;
     });
 }

 if (type === 1) {
   return axios
     .post(`${END_POINT.BASE_URL}${url}`, data, {
      headers: header
     })
     .then(res => {
       if(res.data.message=="Unauthorized access "){
         window.location = END_POINT.APP_BASE;
         localStorage.removeItem("dmvUser");

       }
       return res;
     });
 }

 if (type === 3) {
   return axios
     .put(`${END_POINT.BASE_URL}${url}`, data, {
      headers: header
     })
     .then(res => {
       if(res.data.message=="Unauthorized access "){
         window.location = END_POINT.APP_BASE;
         localStorage.removeItem("dmvUser");

       }
       return res;
     });
 }

 if (type === 4) {
   return axios
     .delete(`${END_POINT.BASE_URL}${url}`,{
       params:data,
       headers: header
     })
     .then(res => {
       if(res.data.message=="Unauthorized access "){
         window.location = END_POINT.APP_BASE;
         localStorage.removeItem("dmvUser");

       }
       return res;
     });
 }
};

export const _callApiForUpload = (url, type, data) => {

   return axios
     .post(`${END_POINT.BASE_URL}${url}`, data, {
      headers: {}
     })
     .then(res => {
       return res;
     });
}
