import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { questionDelete } from "../../actions/Question/DeleteQuestion";

export const DeleteQuestionApi = form => {
  return (dispatch, getState) => {
    dispatch(questionDelete.QuestionDeleting());
    return _callApi(
      END_POINT.QUESTION.QUESTIONDELETE.END_POINT,
      END_POINT.QUESTION.QUESTIONDELETE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(questionDelete.QuestionDeletingFailed({result,message}));
        return res.data;
      } else {
          dispatch(questionDelete.QuestionDeleted({result,message}));
        return res.data;
      }
    });
  };
};
