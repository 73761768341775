
import React, { Component } from 'react';
import { Heading} from '../../commonComponents';
import { Link } from 'react-router-dom';

class ShippingMethods extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Shipping Methods"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>Default</th>
                  <th>Shipping Title</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                    <label>
                        <input type="radio" name="shipping_methods_id" value="1" shipping_id="2" className="default_method"/>
                    </label>
                    </td>
                    <td>
                    Free Shipping
                    </td>
                    <td>
                    ---
                    </td>
                    <td>
                    <span class="label label-pending">
                    In Active 
                    </span>
                    
                    &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;

                    <Link to="#" className="method-status">
                        Active
                    </Link>
                    </td>
                    <td>
                    <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Link>
                    </td>
                    <td> 
                     ---
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default ShippingMethods 