import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducers } from "./reducers";
import { createLogger } from "redux-logger";

let initialState = {
  delete:{
    isDeleting:false,
    isSuccess:false,
    msg:"",
  },
  add:{
    isAdding:false,
    isSuccess:false,
    msg:"",
  },
  update:{
    isSuccess:false,
    msg:"",
    isUpdating:false
  },
  attempt:{
    isAttemptFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  seminar:{
    isSeminarFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  paymentHistory:{
    isPaymentHistoryFetching:false,
    list:[],
  },
  completeSeminar:{
    isSeminarUserListFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  getDealsDetails:{
    msg : "",
    data : {},
    isFetching:false
  },
  getBannerDetails:{
    msg : "",
    data : {},
    isFetching:false
  },
  banner:{
    isFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  subcategories:{
    isFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  login: {
    details: {
      accessToken: "",
      address: "",
      contactNo: "",
      email: "",
      imageUrl: "",
      name: "",
      _id: ''
    },
    msg: null,
    isSuccess: false,
    isLogging: null
  },
  changePassword:{
    isPasswordChanging:false
  },
  editProfile:{
    isSuccess:false,
    isProfileEditing:false
  },
  userList:{
    isUserListFetching:false,
    isSuccess:true,
    msg:"",
    list:[],
    totalCount:0,
    filter:{}
  },
  subAdminList:{
    isSubadminFetching:false,
    isSuccess:true,
    msg:"",
    list:[],
    totalCount:0,
    filter:{}
  },
  categories:{
    isUserListFetching:false,
    list:[],
    totalCount:0,
    categoryDetails:{},
    filter:{}
  },
  deleteCategory:{
    isCategoryDeleting:false,
    msg:"",
    isSuccess:""
  },
  deleteUser:{
    isUserDeleting:false,
    msg:"",
    isSuccess:""
  },
  deals:{
    isDealsFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  product:{
    isProductFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  userproduct:{
    isUserProductFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  order:{
    isFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  userOrder:{
    isUserOrderFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  menuList:{
    isMenuFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  dashboard:{
    isDashboardFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  navBarList:{
    isNavBarFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  acceptOrder:{
    isAcceptOrderFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  rejectOrder:{
    isRejectOrderFetching:false,
    list:[],
    totalCount:0,
    filter:{}
  },
  addCategory:{
    isCategoryAdding:false,
    msg: null,
    isSuccess: false,
  },
  editCategory:{
    isCategoryEditing:false,
    msg: null,
    isSuccess: false,
  },
  userDetails:{
    isUserDetailsFetching: false,
    isSuccess:false,
    msg:"",
    result:{}
  },
  questionList:{
    isQuestionListFetching:false,
    isSuccess:true,
    msg:"",
    list:[],
    totalCount:0,
    filter:{}
  }, 
  questionDetails:{
    isQuestionDetailsFetching: false,
    isSuccess:false,
    msg:"",
    result:{}
  },


};

let logger = createLogger();

export const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(thunkMiddleware, logger),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : f => f
  )
);
