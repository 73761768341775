
import React, { Component } from 'react';
import { Heading,Button,TextArea } from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Link } from 'react-router-dom';

class CustomCssJs extends Component {
  
  render() {
   
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Custom css/js"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-6">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>

                          <TextArea
                          formWrapClass = "form-group"
                          labelText="Before Head Tag"
                          inputClass="form-control"
                          placeholder="You can write custom CSS/JS or override CSS/JS. Or can include exxternal CSS/JS"
                          name = "beforeheadtag"
                          rows="10"
                          cols="50"
                          handleChange={handleChange}
                          value={values.beforeheadtag}
                          errorClass = "text-danger"
                          errorText = { touched.description && errors.description }
                          />
                          <TextArea
                          formWrapClass = "form-group"
                          labelText="End of Body Tag"
                          inputClass="form-control"
                          placeholder="You can write custom CSS/JS or override CSS/JS. Or can include exxternal CSS/JS"
                          name = "endofbodytag"
                          handleChange={handleChange}
                          value={values.endofbodytag}
                          errorClass = "text-danger"
                          errorText = { touched.description && errors.description }
                          />
                          
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="Update"
                        btnText="Update"
                      />
                      <Link to="#" className="btn btn-default" type="button">
                          Back
                      </Link>
                      </div>
                    </form>
                )}
                />
          </div>
            <div className="col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomCssJs 