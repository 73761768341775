import { ACTIONS } from "../../action-constants/Actions"


const QuestionAdding=()=>{

    return {
        type: ACTIONS.QUESTION.ADDQUESTION.QUESTION_ADDING,
        payload:{
            isQuestionAdding:true,
            
        },
        isSuccess: false,
    }
}

const QuestionFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.QUESTION.QUESTION_FAILED,
        payload:{
            isQuestionAdding:false,
            result,
            msg
        },
        isSuccess: false,
    }
}

const QuestionAdded=({ result })=>{
    
    

    return {
        type: ACTIONS.QUESTION.ADDQUESTION.QUESTION_ADDED,
        payload:{
            isQuestionAdding:false,
            result:result,
            // message:msg
        },
        isSuccess: true,
    }
}

export const addQuestion = {
    QuestionFailed,
    QuestionAdding,
    QuestionAdded
}