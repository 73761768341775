
import React, { Component } from 'react';
import { Heading,Button,InputBox,SelectBox,TextArea } from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import IndiaFlag from '../../../../assets/images/IndiaFlag.png'
//import { Dropdown } from "react-bootstrap";
//import { Link } from 'react-router-dom';

class PaymentMethods extends Component {
  
  render() {
    
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Payment Methods"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-6">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                        
                    </form>
                )}
                />
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentMethods 