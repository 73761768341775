import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { END_POINT } from '../../../../constants/apiEndPoints';
import { connect } from "react-redux";
import { Heading, Image, Button } from '../../commonComponents';
import { GetBannerApi } from "../../../../store/api-actions/banner/GetBannerApi";
import { NavLink } from 'react-router-dom';
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import { DeleteBannerApi } from "../../../../store/api-actions/banner/DeleteBannerApi";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';



const mapDispatchToProps = dispatch => {
  // console.log("!!!!!!!!!!!!!!,",GetCategories())
  return {
    banner: (reqData) => dispatch(GetBannerApi(reqData)),
    deleteBanner: (reqData) => dispatch(DeleteBannerApi(reqData))

  };
};

const mapStateToProps = state => {
  return {
    bannerList: state.banner,
    deleteState: state.delete
  };
};


class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWarningConfirMsg:false,
      isSuccessConfirMsg:false,
      _id:""
    };
  }
  componentDidMount() {
    this.props.banner().then(res => {})
  }

  handlePageClick = data => {
    let reqData = {};
    if(data && !isNaN(data.selected)){
      reqData.pageNo = parseInt(data.selected)+1;
    }
    if(data && data.target && data.target.value){
      reqData.title = data.target.value;
    }
    this.props.banner(reqData).then(res=>{})
  };

  handleDelete = data => {
    let reqData = {};
    if(data._id){
      reqData.bId = data._id;
    }
    this.props.deleteBanner(reqData).then(res=>{
      const { message = null, result = null } = res;
      this.setState({isWarningConfirMsg:false,isSuccessConfirMsg:true,_id:""});
    })
  };

  cancelDelete = () => {
    this.setState({isWarningConfirMsg:false,_id:""});
  };

  confirmMsg = data => {
    this.setState({isWarningConfirMsg:true,_id:data._id});
  };

  

  redirectTo=(data)=>{

    let { history } = this.props

    history.push(`/portal/edit-banner/${data._id}`)
  }

  render() {
    var conm = "";
    let { list,totalCount} = this.props.bannerList;
    
    if(this.state.isWarningConfirMsg){
      var obj = {
        isWarningConfirMsg:this.state.isWarningConfirMsg,
        confirmBtnText:MESSAGE.CONFIRM_BTN_TEXT,
        title:MESSAGE.WARNING_TITLE,
        onConfirm:()=>{
          this.handleDelete(this.state)
        },
        onCancel:()=>{
          this.cancelDelete(this.state)
        },
        subtitle:MESSAGE.WARNING_SUB_TITLE
      }
      conm = swal.warning(obj);
    }

    if(this.state.isSuccessConfirMsg){
      var obj = {
        title:MESSAGE.SUCCESS_TITLE,
        onConfirm:()=>{
          this.setState({isSuccessConfirMsg:false});
          this.handlePageClick();

        },
        subtitle:MESSAGE.SUCCESS_SUB_TITLE
      }
      conm = swal.success(obj);
    }
    
    

    return (
      <div className="album pt-3">
         
        <div className="container-fluid">
          <Heading headingClass="bg-light" headingText="Banner" />
          <hr />
          <div className="row">
            <div className="col-md-4">
            <SearchBox 
            inputOnChange={this.handlePageClick}
            inputName="name"
            btnOnClick={this.handlePageClick}
            />
            </div>
          </div>
          
          <hr />
          {conm}
          <div className="row">
            <div className="col-md-12">
              <NavLink to="/portal/add-banner">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add Banner" />
              </NavLink>
            </div>
          </div>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td><Image imageSrc={ END_POINT.S3BASE_URL+item.image} imageClass="ima-size-30" /></td>
                    <td>
                    <Link onClick={()=>{
                            this.redirectTo(item)
                          }} className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                    <i className="fa fa-edit"></i>
                    </Link>
                    <Link onClick={()=>{
                            this.confirmMsg(item)
                          }} className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                    <i className="fa fa-trash"></i>
                    </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
                  previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount?(totalCount/END_POINT.LIMIT):0}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'text-center pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Banner = connect(mapStateToProps, mapDispatchToProps)(Banner);