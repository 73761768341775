import { ACTIONS } from "../action-constants/Actions";

export const deleteUserReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.USER.DELETEUSER.USER_DELETING:{

            let newState = { ...{}, ...state };
            newState.isSuccess = false
            return newState
        }
        case ACTIONS.USER.DELETEUSER.USER_DELETED:{
            
            let newState = { ...{}, ...state };
          
            let { result, message } = action.payload;
            newState.msg = message
            newState.isSuccess = true
             

            return newState;
        }
        case ACTIONS.USER.DELETEUSER.USER_DELETING_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false
            newState.msg = error;
            return newState
        }
        
        default:{
            return state;
        }
    }
}