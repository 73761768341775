import React, { Component,useState } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox,SelectBox,TextArea } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { AddDealsApi } from "../../../../store/api-actions/deals/AddDealsApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';




 
const mapDispatchToProps = dispatch => {
    return {
      addDeals: form => dispatch(AddDealsApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
      addDealsState: state.add
    };
  };

class AddDeals extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false
        };

        // const [startDate, setStartDate] = useState(new Date("2014/02/08"));
        // const [endDate, setEndDate] = useState(new Date("2014/02/10"));
        
    }
    
    addDealsForm = (values, formikBag)=> {
      console.log(values);
      console.log(formikBag);
        uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
        .then(data => {
            values.image = data.key;
            this.props.addDeals(values).then(res=>{
              const { message = null, result = null } = res;
              if ( Object.keys(result).length  > 0) {
                this.setState({isSuccessConfirMsg:true});
               }
            })
        })
        .catch(err => console.error(err))
    };


    redirectTo=(data)=>{
      let { history } = this.props
      history.push(`/portal/category`)
    }

    render() {
        let { addDealsState } = this.props;
        let loader = "";
        let conm = "";
        const options = [
                          { key:'0', value: '0', text: '% Percent' },
                          { key:'1', value: '1', text: 'Value' }
                        ];
        if(this.props.addDealsState.isAdding){
          loader = <div className="loader-custom">
                      <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000000} //3 secs
                      />
                    </div>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.CAT_SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }
        

        return (
          <div className="album pt-3">
             {loader}
             {conm}           
          <div className="container-fluid">
          <Heading headingClass="bg-light text-center" headingText="Add Deals"/>
          <div className="container">
                  <Formik
                  initialValues={{
                    title:'',
                    address:'',
                    price:'',
                    discount:'',
                    discountType:'',
                    startDate:'',
                    endDate:'',
                    description:'',
                    
                  }}
                  onSubmit={this.addDealsForm}
                  validationSchema={VALIDATORS.DEALS}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Title"
                            inputClass="form-control"
                            type="text"
                            placeholder="Title"
                            name = "title"
                            handleChange={handleChange}
                            value={values.title}
                            errorClass = "text-danger"
                            errorText = { touched.title && errors.title }
                          />

                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Image"
                            inputClass="form-control"
                            type="file"
                            placeholder="Image"
                            name = "image"
                            handleChange= {(e)=>{
                              let imgData = COMMON_FUNCTION.imagePreview(e);
                              this.setState({
                                file: imgData.file,
                                s3file : imgData.s3file
                              });
                            }}
                          />
                          </div>
                          <div className="col-md-6 text-center">
                            <div className="img-placeholder">
                              <img className="wh-174-194" src={this.state.file}/>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                        <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Address"
                            inputClass="form-control"
                            type="text"
                            placeholder="Address"
                            name = "address"
                            handleChange={handleChange}
                            value={values.address}
                            errorClass = "text-danger"
                            errorText = { touched.address && errors.address }
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Price"
                            inputClass="form-control"
                            type="text"
                            placeholder="Price"
                            name = "price"
                            handleChange={handleChange}
                            value={values.price}
                            errorClass = "text-danger"
                            errorText = { touched.price && errors.price }
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Discount"
                            inputClass="form-control"
                            type="text"
                            placeholder="Discount"
                            name = "discount"
                            handleChange={handleChange}
                            value={values.discount}
                            errorClass = "text-danger"
                            errorText = { touched.discount && errors.discount }
                          />
                          <SelectBox
                            formWrapClass = "form-group col-md-6"
                            selectClass = "form-control"
                            labelText="Discount Type"
                            name = "discountType"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="startDate"
                            inputClass="form-control"
                            type="text"
                            placeholder="startDate"
                            name = "startDate"
                            handleChange={handleChange}
                            value={values.startDate}
                            errorClass = "text-danger"
                            errorText = { touched.startDate && errors.startDate }
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="End Date"
                            inputClass="form-control"
                            type="text"
                            placeholder="End Date"
                            name = "endDate"
                            handleChange={handleChange}
                            value={values.endDate}
                            errorClass = "text-danger"
                            errorText = { touched.endDate && errors.endDate }
                          />
                          <TextArea
                          formWrapClass = "form-group col-md-12"
                          labelText="Description"
                          inputClass="form-control"
                          name = "description"
                          handleChange={handleChange}
                          value={values.description}
                          errorClass = "text-danger"
                          errorText = { touched.description && errors.description }
                          />
                          <InputBox
                            type="hidden"
                            name = "lat"
                          />
                          <InputBox
                            type="hidden"
                            name = "long"
                          />
                        </div>
                      <div className="form-group auth-form-field">
                        <Button
                          btnClass="btn btn-primary"
                          btnType="submit"
                          btnText="Submit"
                        />
                      </div>
                      <div className="clearfix">
                        &nbsp;
                      </div>
                  </form>
                )}
                  />
          </div>
          </div>
        </div>
    
        )
    }
}

export default AddDeals = connect(mapStateToProps, mapDispatchToProps)(AddDeals);