import { ACTIONS } from "../../action-constants/Actions"


const QuestionListFetching=()=>{
    return {
        type: ACTIONS.QUESTION.QUESTION_FETCHING,
        payload:{
            isQuestionFetching:true,
            
        }
    }
}

const QuestionListFailed=(error)=>{
    return {
        type: ACTIONS.QUESTION.QUESTION_FAILED,
        payload:{
            isQuestionFetching:false
        }
    }
}

const QuestionListFetched=(result)=>{
    console.log("fsfsa", result);
    return {
        type: ACTIONS.QUESTION.QUESTION_FETCHED,
        payload:{
            isQuestionFetching:false,
            result
        }
    }
}


export const questionList = {
    QuestionListFetching,
    QuestionListFailed,
    QuestionListFetched
}