import * as Yup from 'yup';

export const VALIDATORS = {
   isImage: (name) => {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (allowedExtensions.exec(name)) {
         return true;
      } else {
         return false;
      }
   },
   LOGIN: Yup.object().shape({
      email: Yup.string().email("Email is invalid").required('Email is required'),
      password: Yup.string().required('Password is required')
   }),
   FORGET_PASSWORD: Yup.object().shape({
      email: Yup.string().email("Email is invalid").required('Email is required')
   }),
   UPDATE_PASSWORD: Yup.object().shape({
      password: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
   }),
   CHANGEPASSWORD: Yup.object().shape({
      currentPassword: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
      newPassword: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
      confirmPassword: Yup.string().required('Confirm Password is required.')
         .min(8, 'Password is too short - should be 8 chars minimum.')
         .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
         .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
   }),
   ADDCATEGORY: Yup.object().shape({
      name: Yup.string().required('Category Name is required').min(1, 'Category Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!')
   }),
   EDITPROFILE: Yup.object().shape({
      name: Yup.string().required('Profile Name is Required').min(1, 'Profile Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!')
   }),
   DEALS: Yup.object().shape({
      title: Yup.string().required('Title is required').min(1, 'Title is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      address: Yup.string().required('Address is required').min(1, 'Address is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      price: Yup.number().required('Price is required').min(1, 'Price is too short - should be 1 chars minimum.')
         .max(100000000, 'Too Long!'),
      discount: Yup.number().required('Discount is required').min(1, 'Discount is too short - should be 1 chars minimum.')
         .max(100000000, 'Too Long!'),
      discountType: Yup.number().required('Discount Type is required'),
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required')
      // image: Yup.string().required('Image is required'),
   }),
   ADDBANNER: Yup.object().shape({
      name: Yup.string().required('Banner Name is required').min(1, 'Banner Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!')
   }),
   ADDSUBADMIN: Yup.object().shape({
      name: Yup.string().required('Name is required').min(1, 'Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      email: Yup.string().email("Email is invalid").required('Email is required'),
      password: Yup.string().required('Password is required'),
      address: Yup.string().required('Address is required').min(1, 'Address is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
   }),
   EDIT_STUDENT: Yup.object().shape({
      userName: Yup.string().required('User Name is required').min(1, 'User Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      firstName: Yup.string().required('First Name is required').min(1, 'First Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      lastName: Yup.string().required('Last Name is required').min(1, 'Last Name is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      address1: Yup.string().required('Address is required').min(1, 'Address is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      city: Yup.string().required('City is required').min(1, 'City is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      state: Yup.string().required('State is required').min(1, 'State is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      zipCode: Yup.number().required('Zip Code  is required').min(1, 'Zip Code is too short - should be 1 chars minimum.')
         .max(5000000, 'Too Long!'),
      phoneNumber: Yup.string().required('Phone Number is required').min(7, 'Phone Number is too short - should be greater than 7 chars minimum.')
         .max(12, 'Too Long!'),
      email: Yup.string().required('Email is required').email('Please Fill Valid Email Address.'),
      dob: Yup.date().required('Dob is required')

   }),
   ADDQUESTION: Yup.object().shape({
      title: Yup.string().required('Question Title is required').min(1, 'Question Title is too short - should be 1 chars minimum.')
         .max(70, 'Too Long!'),
      option1: Yup.string().required('Option 1 is required'),
      option2: Yup.string().required('Option 2 is required'),
      option3: Yup.string().required('Option 3 is required'),
      option4: Yup.string().required('Option 4 is required'),
      answer: Yup.string().required('Answer is required'),
   }),
}
