import { ACTIONS } from "../action-constants/Actions";

export const deleteCategoryReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.CATEGORY.DELETECATEGORY.CATEGORY_DELETING:{

            let newState = { ...{}, ...state };
            newState.isSuccess = false
            return newState
        }
        case ACTIONS.CATEGORY.DELETECATEGORY.CATEGORY_DELETED:{
            
            let newState = { ...{}, ...state };
          
            let { result, message } = action.payload;
            newState.msg = message
            newState.isSuccess = true
              
            // console.log("-00000000000-----------",result)

            return newState;
        }
        case ACTIONS.CATEGORY.CATEGORY_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false
            newState.msg = error;
            return newState
        }
        
        default:{
            return state;
        }
    }
}