import { ACTIONS } from "../../action-constants/Actions"


const DashboardFetching=()=>{

    return {
        type: ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FETCHING,
        payload:{
            isDashboardFetching:true,
        }
    }
}

const DashboardFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FAILED,
        payload:{
            isDashboardFetching:false,
            result,
            msg
        }
    }
}

const DashboardFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FETCHED,
        payload:{
            isDashboardFetching:false,
            result:result,
            message:msg
        }
    }
}

export const Dashboard = {
    DashboardFailed,
    DashboardFetching,
    DashboardFetched
}