import { ACTIONS } from "../action-constants/Actions";

export const questionListReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.QUESTION.QUESTION_FETCHING:{

            let newState = { ...{}, ...state };

            newState.isSuccess = false

            newState.list =  [];
            newState.totalCount = 0
            return newState
        }
        case ACTIONS.QUESTION.QUESTION_FETCHED:{

            console.log("question reducer calling", action.payload);

            let newState = { ...{}, ...state };
            let { result, message } = action.payload;

            newState.isSuccess = true
            newState.msg = message
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0
            newState.filter = result?result.filter:{}

            return newState;
        }
        case ACTIONS.QUESTION.QUESTION_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false
            newState.msg = error;
            newState.list =  [];
            newState.totalCount = 0

            return newState
        }
        default:{
            return state;
        }
    }
}