import React, { Component } from 'react'
import { Heading,LoadingComponent } from '../../../components/commonComponents';

export default class Footer extends Component {
    render() {
        return (
            <div>
                <LoadingComponent/>
                footer
            </div>
        )
    }
}
