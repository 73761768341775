import { ACTIONS } from "../../action-constants/Actions"


const SeminarFetching=()=>{

    return {
        type: ACTIONS.RESULT.SEMINAR.SEMINAR_FETCHING,
        payload:{
            isSeminarFetching:true,
        }
    }
}

const SeminarFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.SEMINAR.SEMINAR_FAILED,
        payload:{
            isSeminarFetching:false,
            result,
            msg
        }
    }
}

const SeminarFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.SEMINAR.SEMINAR_FETCHED,
        payload:{
            isSeminarFetching:false,
            result:result,
            message:msg
        }
    }
}

export const Seminar = {
    SeminarFetching,
    SeminarFailed,
    SeminarFetched
}