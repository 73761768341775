import React, { Component } from "react";
//import {Tabs,Tab} from "react-bootstrap";
import {LastOrders,LastSearchTerms,TopSearchTerms,} from './index';



class DashboardSecondarySidebar extends Component {

    render() {
        return (
            <>
            <ul>
                <li className="dashboard-item dashboard-item-primary">
                    <div className="dashboard-item-title">
                       Lifetime Sales
                    </div>
                    <div className="dashboard-item-content">
                        <strong className="dashboard-sales-value">
                            <span className="price">
                                 ₹{this.props.item.totalSale}
                            </span>
                        </strong>
                    </div>
                </li>
                <li className="dashboard-item dashboard-item-primary">
                    <div className="dashboard-item-title">
                       Average Order
                    </div>
                    <div className="dashboard-item-content">
                        <strong className="dashboard-sales-value">
                            <span className="price">
                                ₹{this.props.item.totalSale}
                            </span>
                        </strong>
                    </div>
                </li>
            </ul>
            <div className="dashboard-item">
                <div className="dashboard-item-title">
                    Last Orders
                </div>
                <div className="dashboard-item-content">
                   <LastOrders item={this.props.item} />
                </div>
            </div>
            </>
        );
    }
}

export default DashboardSecondarySidebar 
