import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { GetQuestionDetails } from "../../actions/Question/GetQuestionDetails";

export const GetQuestionApi = form => {
  return (dispatch, getState) => {
    dispatch(GetQuestionDetails.GetQuestionDetailsFetching());
    return _callApi(
      END_POINT.QUESTION.QUESTIONDETAILS.END_POINT,
      END_POINT.QUESTION.QUESTIONDETAILS.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;

      console.log("question get details", res.data);
      
      if ( Object.keys(result).length === 0) {
        
        dispatch(GetQuestionDetails.GetQuestionDetailsFailed({result,message}));
        return res.data;
      } else {
        dispatch(GetQuestionDetails.GetQuestionDetailsFetched({result}));
        return res.data;
      }
    });
  };
};
