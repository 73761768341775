import React, { Component } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import { ForgetPasswordApi } from "../../../../store/api-actions/ForgetPasswordApi";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import {Image,LoadingComponent} from '../../commonComponents';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';

const mapDispatchToProps = dispatch => {
  return {
    ForgetPassword: form => dispatch(ForgetPasswordApi(form))
  };
};

const mapStateToProps = state => {
  return {
    forgetPasswordState: state.forgetPassword
  };
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      isSuccess:false,
      isLoading:false,
      isWarningConfirMsg:false,
      isSuccessConfirMsg:false,
      isFailedConfirMsg:false,
      _id:"",
      message: null,
      title:"",
      formFields: {},
      errors: {},
      messageElement: false,
      success: false,
      s3file:"",
      isLoader:false,
      image:"",
    };
  }

  forgetPasswordHandler = (values, formikBag)=> {
    this.setState({isLoading:true});
    this.props.ForgetPassword(values).then(res=>{
      // console.log("=====>>>>>>>>",values)
      const { error = null, result = null,message=null } = res;
      
      if(res.status && Object.keys(res.result).length  > 0){
        let { history } = this.props
        this.setState({isLoading:false,isSuccessConfirMsg:true,message:result.message,title:MESSAGE.SUCCESS_TITLE});
        }else{
          this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
      }
    })
      
  };
  render() {
    var conm = "";
    var loader = ""
    var obj = {
        title:this.state.title,
        onConfirm:()=>{
        this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
        this.props.history.push(`/`)
        },
        subtitle:this.state.message
    }

    if(this.state.isFailedConfirMsg){
        conm = swal.failed(obj);
    }
    
    if(this.state.isSuccessConfirMsg){
        conm = swal.success(obj);
    }
    if(this.state.isLoading){
        loader = <LoadingComponent/>
    }
    return (
      <div className="auth-wrapper d-flex align-items-center justify-content-center">
        {conm}
        <div className="auth-inner-wrapper frogotpassword">
        { loader }
          <div className="auth-card">
            <div className="auth-header">
              <div className="auth-logo">
              <img src="https://drivinginstructorlicensecourse.com/static/media/Advantage-Circle-Logo.f1eb15a6.png" className="logo-img" alt="styleoak" />
                <h3 className="forget">Forgot Password</h3>
              </div>
            </div>
            <div className="auth-body">
            
              <Formik
              initialValues={{
                email:'',
              }}
              onSubmit={this.forgetPasswordHandler}
              validationSchema={VALIDATORS.FORGET_PASSWORD}
              render={({ handleSubmit, errors, touched, values, handleChange })=>(
                <form onSubmit={handleSubmit}>
                 <span style={{ color: this.state.isSuccess ? 'green' : 'red' }} > { this.state.message }</span>
                <div className="form-group auth-form-field">
                  <div className="auth-label">Email</div>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    className="form-control"
                    // required
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    { touched.email && errors.email }
                  </small>
                </div>
                <div className="form-group auth-form-field">
                  <button 
                   
                  type="submit" className="btn-primary btn btn-block">
                    Submit
                  </button>
                </div>
              </form>
              )}
              />
            </div>
            <div className="auth-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
