import React, { Component } from "react";
import {Table} from "react-bootstrap";


class LastSearchTerms extends Component {

    render() {
        return (
            <Table responsive>
                <thead>
                    <tr>
                    <th>Search Term</th>
                    <th>Results</th>
                    <th>Uses</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Avinash Gupta</td>
                    <td>1</td>
                    <td>2</td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default LastSearchTerms 
