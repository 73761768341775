import { ACTIONS } from "../../action-constants/Actions";

export const acceptOrderReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.ORDER.ACCEPT_ORDER_LIST.ACCEPT_ORDER_FETCHING:{
            let newState = { ...{}, ...state };
            newState.isAcceptOrderFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        case ACTIONS.ORDER.ACCEPT_ORDER_LIST.ACCEPT_ORDER_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.isAcceptOrderFetching=false
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0
            newState.filter = result?result.filter:{}
            return newState;
        }
        case ACTIONS.ORDER.ACCEPT_ORDER_LIST.ACCEPT_ORDER_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.isAcceptOrderFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        default:{
            return state;
        }
    }
}