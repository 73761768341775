import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { LoadingComponent } from "../../commonComponents";
import { UpdatePasswordApi } from "../../../../store/api-actions/UpdatePasswordApi";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';

const mapDispatchToProps = dispatch => {
  return {
    UpdatePassword: form => dispatch(UpdatePasswordApi(form))
  };
};

const mapStateToProps = state => {
  return {
  };
};


class NewPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      isSuccess:false,
      isSuccessConfirMsg:false,
      isLoader:false
    };
  }

  updatePasswordHandler = (values, formikBag)=> {
    this.setState({isLoader:true});
    this.props.UpdatePassword(values).then(res=>{
      console.log("=====>>>>>>>>",values)
      const { error = null, result = null,message=null } = res;
      if (Object.keys(result).length  > 0) {
          this.setState({isLoader:false});
          this.setState({isSuccessConfirMsg:true});
      }
    })
  };

  redirectTo=(data)=>{
    let { history } = this.props
    history.push(`/`)
  }

  render() {
        let loader = "";
        let conm = "";
        if(this.state.isLoader){
          loader = <LoadingComponent/>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.PASSWORD_CHANGED
          }
          conm = swal.success(obj);
        }
    return (
      <div className="auth-wrapper d-flex align-items-center justify-content-center">
        {loader}
        {conm}  
        <div className="auth-inner-wrapper frogotpassword">
          <div className="auth-card">
            <div className="auth-header">
              <div className="auth-logo">
                <h3>New Password</h3>
              </div>
            </div>
            <div className="auth-body">
            <Formik
              initialValues={{
                password:'',
                accessToken:this.props.match.params.id
              }}
              onSubmit={this.updatePasswordHandler}
              validationSchema={VALIDATORS.UPDATE_PASSWORD}
              render={({ handleSubmit, errors, touched, values, handleChange })=>(
                <form onSubmit={handleSubmit}>

                 <span style={{ color: this.state.isSuccess ? 'green' : 'red' }} > { this.state.message }</span>
                <div className="form-group auth-form-field">
                  <div className="auth-label">New Password</div>
                  <input
                    type="password"
                    name="password"
                    value={values.password}
                    className="form-control"
                    // required
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    { touched.password && errors.password }
                  </small>
                </div>
                <div className="form-group auth-form-field">
                  <button 
                   
                  type="submit" className="btn-primary btn btn-block">
                    Submit
                  </button>
                </div>
              </form>
              )}
              />
            </div>
            <div className="auth-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewPassword = connect(mapStateToProps, mapDispatchToProps)(NewPassword);
