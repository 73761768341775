import { COMMON_FUNCTION } from "./commonFunction";
import { Container, Row, Col } from "react-bootstrap";



export const CONSTANTS = {
      MAINDIV:{
        "position": "absolute",
        "bottom": "28%",
        "left": "0",
        "font-size": "31px",
        "color": "rgb(87, 87, 82)",
        "font-weight": "500",
        "right": "0",
        "width": "100%"
      },
      NAMEDIV:{
        "font-style": "italic",
        "text-align":"center"
      },
      CONTENTDIV:{
        "text-align": "center",
        "width": "744px",
        "margin":"0 auto"
      },
      PARA:{
        "font-size": "15px"
      },
      PARA2:{
        "font-size": "15px",
        "margin-bottom":"2px"
      },
      schoolname:{
        "font-size": "14px",
        "text-align":"center",
        "margin-top": "20px"
      },
      signature:{
        "font-size": "20px",
        "text-align":"center",
        "margin-top": "39px"
      },
      GENDER: [
        { key:'0', value: '0', text: 'Male' },
        { key:'1', value: '1', text: 'Female' },
        { key:'2', value: '2', text: 'Other' }
      ],
      SEMINAR: [
        { key:'0', value: '', text: 'All' },
        { key:'1', value: 'seminar-1', text: 'Seminar-1' },
        { key:'2', value: 'seminar-2', text: 'Seminar-2' },
        { key:'3', value: 'seminar-3', text: 'Seminar-3' }
      ],
      headers:[
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" },
        { label: "Sign Up Date", key: "joiningDate" },
        { label: "Completion Date", key: "created" }
      ],
      STATE: [
        {text: "Alabama", value: "AL", key: 0},
        {text: "Alaska", value: "AK", key: 1},
        {text: "American Samoa", value: "AS", key: 2},
        {text: "Arizona", value: "AZ", key: 3},
        {text: "Arkansas", value: "AR", key: 4},
        {text: "California", value: "CA", key: 5},
        {text: "Colorado", value: "CO", key: 6},
        {text: "Connecticut", value: "CT", key: 7},
        {text: "Delaware", value: "DE", key: 8},
        {text: "District Of Columbia", value: "DC", key: 9},
        {text: "Federated States Of Micronesia", value: "FM", key: 10},
        {text: "Florida", value: "FL", key: 11},
        {text: "Georgia", value: "GA", key: 12},
        {text: "Guam", value: "GU", key: 13},
        {text: "Hawaii", value: "HI", key: 14},
        {text: "Idaho", value: "ID", key: 15},
        {text: "Illinois", value: "IL", key: 16},
        {text: "Indiana", value: "IN", key: 17},
        {text: "Iowa", value: "IA", key: 18},
        {text: "Kansas", value: "KS", key: 19},
        {text: "Kentucky", value: "KY", key: 20},
        {text: "Louisiana", value: "LA", key: 21},
        {text: "Maine", value: "ME", key: 22},
        {text: "Marshall Islands", value: "MH", key: 23},
        {text: "Maryland", value: "MD", key: 24},
        {text: "Massachusetts", value: "MA", key: 25},
        {text: "Michigan", value: "MI", key: 26},
        {text: "Minnesota", value: "MN", key: 27},
        {text: "Mississippi", value: "MS", key: 28},
        {text: "Missouri", value: "MO", key: 29},
        {text: "Montana", value: "MT", key: 30},
        {text: "Nebraska", value: "NE", key: 31},
        {text: "Nevada", value: "NV", key: 32},
        {text: "New Hampshire", value: "NH", key: 33},
        {text: "New Jersey", value: "NJ", key: 34},
        {text: "New Mexico", value: "NM", key: 35},
        {text: "New York", value: "NY", key: 36},
        {text: "North Carolina", value: "NC", key: 37},
        {text: "North Dakota", value: "ND", key: 38},
        {text: "Northern Mariana Islands", value: "MP", key: 39},
        {text: "Ohio", value: "OH", key: 40},
        {text: "Oklahoma", value: "OK", key: 41},
        {text: "Oregon", value: "OR", key: 42},
        {text: "Palau", value: "PW", key: 43},
        {text: "Pennsylvania", value: "PA", key: 44},
        {text: "Puerto Rico", value: "PR", key: 45},
        {text: "Rhode Island", value: "RI", key: 46},
        {text: "South Carolina", value: "SC", key: 47},
        {text: "South Dakota", value: "SD", key: 48},
        {text: "Tennessee", value: "TN", key: 49},
        {text: "Texas", value: "TX", key: 50},
        {text: "Utah", value: "UT", key: 51},
        {text: "Vermont", value: "VT", key: 52},
        {text: "Virgin Islands", value: "VI", key: 53},
        {text: "Virginia", value: "VA", key: 54},
        {text: "Washington", value: "WA", key: 55},
        {text: "West Virginia", value: "WV", key: 56},
        {text: "Wisconsin", value: "WI", key: 57},
        {text: "Wyoming", value: "WY", key: 58}
    ]
     
    
}
