import { ACTIONS } from "../../action-constants/Actions";

export const getNavBarReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FETCHING:{
            let newState = { ...{}, ...state };
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        case ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0
            newState.filter = result.filter?result.filter:{}
            return newState;
        }
        case ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        default:{
            return state;
        }
    }
}