import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { update } from "../../actions/CommonAction/Update";

export const EditBannerApi = form => {
  return (dispatch, getState) => {
    dispatch(update.Updating());
    return _callApi(
      END_POINT.BANNER.BANNER_UPDATE.END_POINT,
      END_POINT.BANNER.BANNER_UPDATE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
     if ( Object.keys(result).length === 0) {
        dispatch(update.UpdatingFailed({result,message}));
        return res.data;
      } else {
        dispatch(update.Updated({result}));
        return res.data;
      }
    });
  };
};
