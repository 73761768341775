import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { deals } from "../../actions/Deals/Deals";

export const GetDealsApi = form => {
  return (dispatch, getState) => {
    dispatch(deals.DealsFetching());
    return _callApi(
      END_POINT.DEALS.DEALS_LIST.END_POINT,
      END_POINT.DEALS.DEALS_LIST.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(deals.DealsFailed({result,message}));
        return res.data;
      } else {
        dispatch(deals.DealsFetched({result,message}));
        return res.data;
      }
    });
  };
};
