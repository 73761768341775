import { ACTIONS } from "../../action-constants/Actions";

const UserDeleting = () => {
  return {
    type: ACTIONS.USER.DELETEUSER.USER_DELETING,
    payload: { isUserDeleting: true }
  };
};

const UserDeleted = result=> {
  return {
    type: ACTIONS.USER.DELETEUSER.USER_DELETED,
    payload: { isUserDeleting: false ,result}
  };
};

const UserDeletingFailed = error => {
  return {
    type: ACTIONS.USER.DELETEUSER.USER_DELETING_FAILED,
    payload: { isUserDeleting: false }
  };
};



export const userDelete = {
  UserDeletingFailed,
  UserDeleted,
  UserDeleting,
};
