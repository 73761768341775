import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Heading,InputBox,SelectBox,MaterialUIPickers,LoadingComponent } from "../../commonComponents";
import { GetUserDetailsApi } from '../../../../store/api-actions/GetUserDetailsApi';
import { UpdateUserApi } from '../../../../store/api-actions/user/UpdateUserApi';
import {Tabs,Tab} from "react-bootstrap";
import {SeminarResult,TestAttempt} from './index';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { CONSTANTS } from "../../../../constants/constant";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import moment from 'moment'
const mapDispatchToProps = (dispatch)=>{

    return {
        userDetails: (reqObj)=>dispatch(GetUserDetailsApi(reqObj)),
        UpdateUser: (reqObj)=>dispatch(UpdateUserApi(reqObj))
    }
}

const mapStateToProps = (state)=>{
 
    return {
        userDetailsState: state.userDetails
    }
}

class EditUserDetail extends Component {

    constructor(props){
        super(props);
        this.state={
            isLoading:false,
            isWarningConfirMsg:false,
            isSuccessConfirMsg:false,
            isFailedConfirMsg:false,
            _id:"",
            message: null,
            title:"",
            formFields: {},
            errors: {},
            messageElement: false,
            success: false,
            s3file:"",
            isLoader:false,
            image:"",
        }
    }

    componentDidMount(){
        let { match } = this.props
        let reqObj = {};
        if(match.params.id){
          reqObj.userId	= match.params.id;
        }
        this.props.userDetails(reqObj).then(res=>{
          const { message = null, result = null } = res;
          console.log("####$$$$$$$",this.props)
        })
    }

    editUserHandler = (values, formikBag)=> {
        console.log("000000077777",values)
        this.setState({isLoading:true});
        values.dob = moment(values.dob, "YYYY/M/D").valueOf();
        this.props.UpdateUser(values).then(res=>{
          if(res.status && Object.keys(res.result).length  > 0){
            let { history } = this.props
            this.setState({isLoading:false,isSuccessConfirMsg:true,message:MESSAGE.USER_DETAILS_UPDATED,title:MESSAGE.SUCCESS_TITLE});
            }else{
              this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
          }
        })
      }

    render() {
            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/portal/user`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
        let {result} = this.props.userDetailsState;
         
        return (
            <div className="album pt-3">
            {loader}
            {conm}
            <div className="container-fluid">
            <Heading  headingText="Edit User Details"/>
            <hr/>
            <div className="container">
              
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    userId:result._id,
                    userName:result.userName,
                    firstName:result.firstName,
                    middleName:result.middleName,
                    lastName:result.lastName,
                    address1:result.address1,
                    address2:result.address2,
                    city:result.city,
                    state:result.state,
                    zipCode:result.zipCode,
                    dob:new Date(result.created),
                    email:result.email,
                    phoneNumber:result.phoneNumber,
                    schoolName:result.schoolName,
                    instructorLicense:result.instructorLicense,
                    schoolOperator:result.schoolOperator,
                    }}
                  onSubmit={this.editUserHandler}
                  validationSchema={VALIDATORS.EDIT_STUDENT}
                  render={({handleSubmit, errors, touched, values, handleChange,setFieldValue})=>(
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                      <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* User Name"
                        name = "userName"
                        handleChange={handleChange}
                        value={values.userName}
                        errorClass = "text-danger"
                        errorText = { touched.userName && errors.userName }
                    />  
                    
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* First Name"
                        name = "firstName"
                        handleChange={handleChange}
                        value={values.firstName}
                        errorClass = "text-danger"
                        errorText = { touched.firstName && errors.firstName }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="Middle Name"
                        name = "middleName"
                        handleChange={handleChange}
                        value={values.middleName}
                        errorClass = "text-danger"
                        errorText = { touched.middleName && errors.middleName }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Last Name"
                        name = "lastName"
                        handleChange={handleChange}
                        value={values.lastName}
                        errorClass = "text-danger"
                        errorText = { touched.lastName && errors.lastName }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Address 1"
                        name = "address1"
                        handleChange={handleChange}
                        value={values.address1}
                        errorClass = "text-danger"
                        errorText = { touched.address1 && errors.address1 }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="Address 2"
                        name = "address2"
                        handleChange={handleChange}
                        value={values.address2}
                        errorClass = "text-danger"
                        errorText = { touched.address2 && errors.address2 }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* City"
                        name = "city"
                        handleChange={handleChange}
                        value={values.city}
                        errorClass = "text-danger"
                        errorText = { touched.city && errors.city }
                    />  
                    <SelectBox
                        formWrapClass = "form-group col-md-6"
                        selectClass = "form-control"
                        placeholder = "* State"
                        name = "state"
                        handleChange={handleChange}
                        options = {CONSTANTS.STATE}
                        errorClass = "text-danger"
                        errorText = { touched.state && errors.state }
                    /> 
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Zip Code"
                        name = "zipCode"
                        handleChange={handleChange}
                        value={values.zipCode}
                        errorClass = "text-danger"
                        errorText = { touched.zipCode && errors.zipCode }
                    />  
                    <div className="col-md-6 dob">
                        <MaterialUIPickers
                            placeholder = "* Dob (DD/MM/YYYY)"
                            margin="normal"
                            id="dob"
                            name = "dob"
                            maxDate = {new Date()}
                            value={values.dob}
                            setFieldValue={(date) => {
                                setFieldValue('dob', date)
                                //this.dob(date)
                            }}
                            errorClass = "text-danger"
                            errorText = { touched.dob && errors.dob }
                        />
                    </div>
                    
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Phone No."
                        name = "phoneNumber"
                        handleChange={handleChange}
                        value={values.phoneNumber}
                        errorClass = "text-danger"
                        errorText = { touched.phoneNumber && errors.phoneNumber }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Email"
                        name = "email"
                        handleChange={handleChange}
                        value={values.email}
                        errorClass = "text-danger"
                        errorText = { touched.email && errors.email }
                    />  
                    
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* School Name"
                        name = "schoolName"
                        handleChange={handleChange}
                        value={values.schoolName}
                        errorClass = "text-danger"
                        errorText = { touched.schoolName && errors.schoolName }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="* Driver License#"
                        name = "instructorLicense"
                        handleChange={handleChange}
                        value={values.instructorLicense}
                        errorClass = "text-danger"
                        errorText = { touched.instructorLicense && errors.instructorLicense }
                    />  
                    <InputBox
                        formWrapClass = "form-group col-md-6"
                        inputClass="form-control"
                        type="text"
                        placeholder="School Operator#"
                        name = "schoolOperator"
                        handleChange={handleChange}
                        value={values.schoolOperator}
                        errorClass = "text-danger"
                        errorText = { touched.schoolOperator && errors.schoolOperator }
                    />  
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-info pull-right">Update</button>
                    </div>
                    </div>
                  </form>
                )}
                  />
                    
                     
              </div>
            </div>
          </div>
        )
    }
}

export default  EditUserDetail = connect(mapStateToProps, mapDispatchToProps)(EditUserDetail);