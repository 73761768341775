import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox,LoadingComponent } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { AddSubAdminApi } from "../../../../store/api-actions/subadmin/AddSubAdminApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';


 
const mapDispatchToProps = dispatch => {
    return {
        AddSubAdmin: form => dispatch(AddSubAdminApi(form))
    };
  };
  
  const mapStateToProps = state => {
    // return {
    //     addCategoryState: state.addCategory
    // };
  };

class AddSubAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false,
          isLoader:false
        };
    }
    
    addSubadminForm = (values, formikBag)=> {
        this.props.AddSubAdmin(values).then(res=>{
            const { message = null, result = null } = res;
            if (Object.keys(result).length  > 0) {
              this.setState({isLoader:false});
              this.setState({isSuccessConfirMsg:true});
            }
        })
    };


    redirectTo=(data)=>{
      let { history } = this.props
      history.push(`/portal/subadmin`)
    }

    render() {
         
        let loader = "";
        let conm = "";
        if(this.state.isLoader){
          loader = <LoadingComponent/>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.CAT_SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }

        return (
          <div className="album pt-3">
             {loader}
             {conm}           
          <div className="container-fluid">
          <Heading headingClass="" headingText="Add Sub Admin"/>
          <hr/>
          <div className="container">
                 <Formik
                  initialValues={{
                    name:'',
                  }}
                  onSubmit={this.addSubadminForm}
                  validationSchema={VALIDATORS.ADDSUBADMIN}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6"> 
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Name"
                            inputClass="form-control"
                            type="text"
                            placeholder="name"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                        </div>
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Email"
                            inputClass="form-control"
                            type="text"
                            placeholder="email"
                            name = "email"
                            handleChange={handleChange}
                            value={values.email}
                            errorClass = "text-danger"
                            errorText = { touched.email && errors.email }
                          />
                        </div> 
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Password"
                            inputClass="form-control"
                            type="text"
                            placeholder="password"
                            name = "password"
                            handleChange={handleChange}
                            value={values.password}
                            errorClass = "text-danger"
                            errorText = { touched.password && errors.password }
                          />
                        </div> 
                        <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Address"
                            inputClass="form-control"
                            type="text"
                            placeholder="address"
                            name = "address"
                            handleChange={handleChange}
                            value={values.address}
                            errorClass = "text-danger"
                            errorText = { touched.address && errors.address }
                          />
                        </div>  
                        <div className="col-md-12">
                            <div className="form-group auth-form-field">
                                <Button
                                    btnClass="btn btn-primary"
                                    btnType="submit"
                                    btnText="Submit"
                                />
                            </div>
                        </div>   
                    </div>
                  </form>
                )}
                  />
                    
                
          </div>
          </div>
        </div>

            
        )
    }
}

export default AddSubAdmin = connect(mapStateToProps, mapDispatchToProps)(AddSubAdmin);