import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox,LoadingComponent } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { AddSubCategoryApi } from "../../../../store/api-actions/subCategory/AddSubCategoryApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';


 
const mapDispatchToProps = dispatch => {
    return {
      addSubCategory: form => dispatch(AddSubCategoryApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        addSubCategoryState: state.add
    };
  };

class AddSubCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false,
          isLoader:false
        };
    }
    
    addSubCategoryForm = (values, formikBag)=> {
        this.setState({isLoader:true});
        uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
        .then(data => {
            values.image = data.key;
            values.categoryId = this.props.match.params.id;
            this.props.addSubCategory(values).then(res=>{
              const { message = null, result = null } = res;
              if ( Object.keys(result).length  > 0) {
                this.setState({isLoader:false});
                this.setState({isSuccessConfirMsg:true});
               }
            })
        })
        .catch(err => console.error(err))
    };


    redirectTo=(data)=>{
      let { history } = this.props
      history.push(`/portal/sub-category/`+data)
    }

    render() {
        let loader = "";
        let conm = "";
        if(this.state.isLoader){
          loader = <LoadingComponent/>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo(this.props.match.params.id);
    
            },
            subtitle:MESSAGE.CAT_SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }

        return (
          <div className="album pt-3">
             {loader}
             {conm}           
          <div className="container-fluid">
          <Heading  headingText="Add Sub Category"/>
          <hr/>
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                  <Formik
                  initialValues={{
                    name:'',
                  }}
                  onSubmit={this.addSubCategoryForm}
                  validationSchema={VALIDATORS.ADDCATEGORY}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Sub Category Name"
                            inputClass="form-control"
                            type="text"
                            placeholder="Sub Category Name"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <div className="upload-img">
                            <InputBox
                              formWrapClass = "form-group"
                              labelText="Sub Category Image"
                              inputClass="form-control"
                              type="file"
                              placeholder="Sub Category Image"
                              name = "image"
                              handleChange= {(e)=>{
                                let imgData = COMMON_FUNCTION.imagePreview(e);
                                this.setState({
                                  file: imgData.file,
                                  s3file : imgData.s3file
                                });
                              }}
                            />
                          </div>
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="submit"
                        btnText="Submit"
                      />
                      </div>
                  </form>
                )}
                  />
                    
                </div>
                <div className="col-md-6 text-center">
                  <div className="img-placeholder">
                    <img className="wh-174-194" src={this.state.file}/>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>

            
        )
    }
}

export default AddSubCategory = connect(mapStateToProps, mapDispatchToProps)(AddSubCategory);