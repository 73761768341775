import { ACTIONS } from "../action-constants/Actions";

export const getDetailsReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FETCHING:{
            let newState = { ...{}, ...state };
            newState.data =  {};
            return newState
        }
        case ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.msg = message
            newState.data = result;
            return newState;
        }
        case ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.msg = error;
            newState.data = {};
            return newState
        }
        default:{
            return state;
        }
    }
}