import { ACTIONS } from "../../action-constants/Actions"


const SubAdminFetching=()=>{
    return {
        type: ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FETCHING,
        payload:{
            isSubadminFetching:true,
        }
    }
}

const SubAdminFailed=({ result, msg })=>{
    return {
        type: ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FAILED,
        payload:{
            isSubadminFetching:false,
            result,
            msg
        }
    }
}

const SubAdminFetched=({ result, msg })=>{
    return {
        type: ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FETCHED,
        payload:{
            isSubadminFetching:false,
            result:result,
            message:msg
        }
    }
}

export const GetSubAdmin = {
    SubAdminFailed,
    SubAdminFetching,
    SubAdminFetched
}