
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';

class Devices extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
            <Heading headingText="Listing Devices"/>
            </div>
            <div className="col-md-6 pull-right">
            <Dropdown>
            <Dropdown.Toggle className="pull-right" variant="success" id="dropdown-basic">
                All
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                <Dropdown.Item href="#/action-2">IOS</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Android</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Website</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Device Detail</th>
                  <th>Device OS</th>
                  <th>User Info</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                    1
                    </td>
                    <td align="left">
                    <strong>Device Type: </strong>
                    Android
                    <br/>
                    <strong>Manufacturer: </strong>samsung
                    <br/>
                    <strong>Device Model: </strong>samsung SM-N975F
                    <br/>
                    <strong>Register Date: </strong>18/12/2019
                    </td>
                    <td>
                    Android 9
                    </td>
                    <td>
                    </td>
                    <td>
                    <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                    In Active
                    </Link>
                    &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                    <span class="label label-success">
                    Active
                    </span>
                    </td>
                    <td> 
                    <Link className="badge bg-light-blue btn btn-primary" data-toggle="tooltip" data-original-title="Edit" to="#">
                    Send
                    </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Devices 