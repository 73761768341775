import React, { Component } from 'react'
import { _callApi } from '../../../../services/baseService';
import { END_POINT } from '../../../../constants/apiEndPoints';
import { connect } from "react-redux";
import { Heading,LoadingComponent } from '../../commonComponents';
import { UserListApi } from "../../../../store/api-actions/UserListApi";
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import { DeleteUserApi } from "../../../../store/api-actions/user/DeleteUserApi";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { Link } from 'react-router-dom';
import {COMMON_FUNCTION} from '../../../../constants/commonFunction';

const mapDispatchToProps = dispatch => {
  return {
    userList: (reqData)=>dispatch(UserListApi(reqData)),
    deleteUser: (reqData)=>dispatch(DeleteUserApi(reqData))
  };
};

const mapStateToProps = state => {
  return {
    userListState: state.userList,
    deleteUserState: state.deleteUser
  };
};

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:"",
          isLoading:true
        };
      }
      componentDidMount() {
        this.props.userList().then(res=>{
          const { message = null, result = null } = res;
        })
      }

      handlePageClick = data => {
        let reqData = {};
        if(data && !isNaN(data.selected)){
          reqData.pageNo = parseInt(data.selected)+1;
        }
        if(data && data.target && data.target.value){
          reqData.name = data.target.value;
        }
        this.props.userList(reqData).then(res=>{
          const { message = null, result = null } = res;
        })
      };
      
      handleDelete = data => {
        let reqData = {};
        if(data._id){
          reqData.userId = data._id;
        }
        this.props.deleteUser(reqData).then(res=>{
          const { message = null, result = null } = res;
          this.setState({isWarningConfirMsg:false,isSuccessConfirMsg:true,_id:""});
        })
      };

      redirectTo=(data)=>{
        let { history } = this.props
        history.push(`/portal/user-detail/${data._id}`)
      }

      redirectToEdit=(data)=>{
        let { history } = this.props
        history.push(`/portal/user-edit/${data._id}`)
      }
      

      cancelDelete = () => {
        this.setState({isWarningConfirMsg:false,_id:""});
      };
      confirmMsg = data => {
        this.setState({isWarningConfirMsg:true,_id:data._id});
      };

      render() {
          let conm = "";
          let {list,totalCount} = this.props.userListState;
          /**=========== alert confirmation========= */
          if(this.state.isWarningConfirMsg){
            var obj = {
              isWarningConfirMsg:this.state.isWarningConfirMsg,
              confirmBtnText:MESSAGE.CONFIRM_BTN_TEXT,
              title:MESSAGE.WARNING_TITLE,
              onConfirm:()=>{
                this.handleDelete(this.state)
              },
              onCancel:()=>{
                this.cancelDelete(this.state)
              },
              subtitle:MESSAGE.WARNING_USER_SUB_TITLE
            }
            conm = swal.warning(obj);
          }
      
          if(this.state.isSuccessConfirMsg){
            var obj = {
              title:MESSAGE.SUCCESS_TITLE,
              onConfirm:()=>{
                this.setState({isSuccessConfirMsg:false});
                this.handlePageClick();
      
              },
              subtitle:MESSAGE.DELETE_USER_SUB_TITLE
            }
            conm = swal.success(obj);
          }
          var loader = "";
          if(this.state.isLoading){
            loader = <LoadingComponent/>
           }
          /**=========== alert confirmation========= */
        return (
          <div className="album pt-3">
            <div className="container-fluid">
            <Heading  headingText="STUDENTS"/>
            <hr />
            <div className="row">
              <div className="col-md-4">
              <SearchBox 
              inputOnChange={this.handlePageClick}
              inputName="name"
              btnOnClick={this.handlePageClick}
              />
              </div>
            </div>
            
            {conm}
            <hr />
              <div className="table-responsive">
                {loader}
                <table className="table table-hover text-center  table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>User Name</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Sign Up Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.userName}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.email}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{COMMON_FUNCTION.parseDate(item.created)}</td>
                        <td>
                        <Link onClick={()=>{
                            this.redirectTo(item)
                          } }  className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-eye"></i>
                        </Link>
                        <Link onClick={()=>{
                            this.redirectToEdit(item)
                          } }  className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-edit"></i>
                        </Link>
                        <Link onClick={()=>{
                            this.confirmMsg(item)
                          }} className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                        <i className="fa fa-trash"></i>
                        </Link> 
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount/END_POINT.LIMIT}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'text-center pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        );
      }
}
export default User = connect(mapStateToProps, mapDispatchToProps)(User);