import { ACTIONS } from "../../action-constants/Actions"


const GetQuestionDetailsFetching=()=>{
    return {
        type: ACTIONS.QUESTION.GETQUESTIONDETAILS.GETQUESTIONDETAILS_FETCHING,
        payload:{
            isQuestionDetailsFetching:true,
            
        }
    }
}

const GetQuestionDetailsFailed=({ result, msg })=>{
    return {
        type: ACTIONS.QUESTION.GETQUESTIONDETAILS.GETQUESTIONDETAILS_FAILED,
        payload:{
            isQuestionDetailsFetching:false,
            result,
            msg
        }
    }
}

const GetQuestionDetailsFetched=({ result, msg })=>{
    console.log(result, "fjsk gjsklfh lkhg sklhskgl ");
    return {
        type: ACTIONS.QUESTION.GETQUESTIONDETAILS.GETQUESTIONDETAILS_FETCHED,
        payload:{
            isQuestionDetailsFetching:false,
            result:result,
            message:msg
        }
    }
}


export const GetQuestionDetails = {
    GetQuestionDetailsFetching,
    GetQuestionDetailsFailed,
    GetQuestionDetailsFetched
}