import { ACTIONS } from "../../action-constants/Actions"


const NavBarFetching=()=>{

    return {
        type: ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FETCHING,
        payload:{
            isNavBarFetching:true,
        }
    }
}

const NavBarFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FAILED,
        payload:{
            isNavBarFetching:false,
            result,
            msg
        }
    }
}

const NavBarFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.MENU.NAVBAR_FETCH.NAVBAR_FETCH_FETCHED,
        payload:{
            isNavBarFetching:false,
            result:result,
            message:msg
        }
    }
}

export const NavBar = {
    NavBarFetching,
    NavBarFailed,
    NavBarFetched
}