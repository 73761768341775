import { ACTIONS } from "../../action-constants/Actions"


const QuestionEditing=()=>{

    return {
        type: ACTIONS.QUESTION.EDITQUESTION.QUESTION_EDITING,
        payload:{
            isQuestionEditing:true,
            
        },
        isSuccess: false,
    }
}

const QuestionEditingFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.QUESTION.EDITQUESTION.QUESTION_EDITING_FAILED,
        payload:{
            isQuestionEditing:false,
            result,
            msg
        },
        isSuccess: false,
    }
}

const QuestionEdited=({ result })=>{
    
    

    return {
        type: ACTIONS.QUESTION.EDITQUESTION.QUESTION_EDITED,
        payload:{
            isQuestionEditing:false,
            result:result,
            // message:msg
        },
        isSuccess: true,
    }
}

export const editQuestion = {
    QuestionEdited,
    QuestionEditingFailed,
    QuestionEditing
}