import { ACTIONS } from "../action-constants/Actions";

const PasswordChanging = () => {
  return {
    type: ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGING,
    payload: { isPasswordChanging: true }
  };
};

const PasswordChanged = ({result, message})=> {
  return {
    type: ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGED,
    payload: { isPasswordChanging: false, result, message }
  };
};

const PasswordChangingFailed = error => {
  return {
    type: ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGING_FAILED,
    payload: { isPasswordChanging: false, error: error }
  };
};



export const passwordChanging = {
  PasswordChanging,
  PasswordChanged,
  PasswordChangingFailed,
};
