import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {Col} from "react-bootstrap";

class AdvertisementsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false
        };
        
    }

    render() {
        let {item} = this.props
        return (
            <>
            <Col className="list-item" lg="4" md="6">
                <div className="list-item-detail bg-aqua">
                    <div className="inner">
                        <h3>{item.totalUser}</h3>
                        <p>Total number of users registered</p>
                    </div>
                    <div class="icon">
                    <i className="fa fa-pie-chart" aria-hidden="true"></i>
                    </div>
                    <Link className="small-box-footer" data-toggle="tooltip" data-original-title="New Orders">
                       
                    </Link>
                </div>
            </Col>
            <Col className="list-item" lg="4" md="6">
                <div className="list-item-detail bg-light-blue">
                    <div className="inner">
                        <h3>{item.recentUser}</h3>
                        <p>Total number of users recently joined</p>
                    </div>
                    <div class="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    </div>
                    <Link className="small-box-footer" data-toggle="tooltip" data-original-title="New Orders">
                       
                    </Link>
                </div>
            </Col>
            <Col className="list-item" lg="4" md="6">
                <div className="list-item-detail bg-teal">
                    <div className="inner">
                        <h3>{item.approvedPost}</h3>
                        <p>Total Approved Rental ads post</p>
                    </div>
                    <div class="icon">
                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                    </div>
                    <Link className="small-box-footer" data-toggle="tooltip" data-original-title="New Orders">
                       
                    </Link>
                </div>
            </Col>
            </>
        );
    }
}

export default AdvertisementsDetail 
