import { ACTIONS } from "../action-constants/Actions";

export const changePasswordReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGING:{

            let newState = { ...{}, ...state };

            newState.isSuccess = false
            return newState
        }
        case ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGED:{

            let newState = { ...{}, ...state };

            let { result, message } = action.payload;

            newState.isSuccess = true
            newState.msg = message

            return newState
        }
        case ACTIONS.USER_CHANGE_PASSWORD.USER_PASSWORD_CHANGING_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false

            newState.msg = error;

            return newState
        }
        default:{
            return state;
        }
    }
}