import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { editProfile } from "../../actions/AdminProfile/EditProfile";

export const EditProfileApi = form => {
  return (dispatch, getState) => {
    dispatch(editProfile.ProfileEditing());
    return _callApi(
      END_POINT.ADMINPROFILE.EDIT_PROFILE.END_POINT,
      END_POINT.ADMINPROFILE.EDIT_PROFILE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if (Object.keys(result).length === 0) {
        dispatch(editProfile.ProfileEditingFailed({result,message}));
        return res.data;
      } else {
        dispatch(editProfile.ProfileEdited({result}));
        return res.data;
      }
    });
  };
};
