import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Portal from "./view/Layout/portal/Portal";

import {
  Login,
  Register,
  ForgotPassword,
  NewPassword
} from "./view/components/authComponents";
import { dashboard } from "./view/components/mainComponents";


function App() {
  return (
    <BrowserRouter basename="/admin" >
      <Switch>
         <Route
          exact={true}
          path="/"
          render={props => {
            let user = JSON.parse(localStorage.getItem("user"));

            if (user && user.accessToken) {
              return <Redirect from="/" to="/portal/user" />;
            } else {
              return <Redirect from="/" to="/login" />;
            }
          }
        }
        />
        <Route
          path="/login"
          render={props => {
            let user = JSON.parse(localStorage.getItem("user"));

            if (user && user.accessToken) {
              return <Redirect from="/" to="/portal/user" />;
            } else {
              return <Login {...props} />;
            }
          }}
        />
        <Route
          path="/portal/user"
          render={props => {
            let user = JSON.parse(localStorage.getItem("user"));

            // console.log(user);

            if (user && user.accessToken) {
              return <Portal {...props} />;
            } else {
              return <Redirect from="/portal/user" to="/login" />;
            }
          }}
        /> */}
        {/* <Route path="/forgot-password" component={ForgotPassword}/> */}
        {/* <Route exact path="/" component={Login}/> */}
        <Route path="/login" component={Login}/>
        {/* <Route path="/register" component={Register}/> */}
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/new-password/:id" component={NewPassword}/>
        <Route path="/portal" component={Portal}/>
        {/* <Route exact path="/post" component={Portal}/> */}
        {/* <Route exact path="/album" component={Portal}/> */}
        <Route exact path="/change-password" component={Portal}/>
        {/* <Route exact path="/comments" component={Portal}/> */}
        {/* <Route exact path="/photos" component={Portal}/> */}
        <Route exact path="/post/post-details/:id" component={Portal}/>
        <Route path="*" render={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
