import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Get } from "../../actions/CommonAction/Get";

export const GetBannerApi = form => {
  return (dispatch, getState) => {
    dispatch(Get.Fetching());
    return _callApi(
      END_POINT.BANNER.GET_BANNER.END_POINT,
      END_POINT.BANNER.GET_BANNER.METHOD,
      form
    ).then(res => {
      res.data.result.filter ={};
        if(form){
            res.data.result.filter = form; 
        }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Get.Failed({result,message}));
        return res.data;
      } else {
        dispatch(Get.Fetched({result,message}));
        return res.data;
      }
    });
  };
};
