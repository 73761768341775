import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const  MaterialUIPickers = (props)=>{
  // The first commit of Material-UI
//   const [selectedDate, setSelectedDate] = React.useState(props.value);

//   const handleDateChange = date => {
//     //setSelectedDate(date);
//   };

  return (
    <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around" >
        <KeyboardDatePicker
          className="form-control"
          placeholder={props.placeholder}
          margin={props.margin}
          id={props.id}
          label={props.label}
          format="dd/MM/yyyy"
          value={props.value?props.value:''}
          name={props.name}
          onChange={props.setFieldValue}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDate={props.minDate}
          maxDate = {props.maxDate}
        />
      </Grid>
    </MuiPickersUtilsProvider>
    <span className={props.errorClass}>{props.errorText}</span>
    </>
  );
}

export default  MaterialUIPickers