import { combineReducers } from "redux";

import { loginReducer } from "./login-reducer";
import { changePasswordReducer } from "./changePasswordReducer";
import { userListReducer } from "./userListReducer";
import { questionListReducer } from "./questionListReducer";

import { categoriesReducer } from "./categoriesReducer";
import { productReducer } from "./productReducer";
import { editProfileReducer } from "./editProfileReducer";
import { deleteCategoryReducer } from "./deleteCategory";
import { userDetailsReducer } from "./userDetailsReducer";
import { deleteUserReducer } from "./deleteUserReducer"
import { deleteReducer } from "./deleteReducer"
import { addReducer } from "./addReducer"
import { getDetailsReducer } from "./getDetailsReducer"
import { updateReducer } from "./updateReducer"
import { getReducer } from "./common-reducer/getReducer"
import { editReducer } from "./common-reducer/editReducer"
import { userproductReducer } from "./userProductReducer"
import { userOrderReducer } from "./order-reducer/userOrderReducer"
import { rejectOrderReducer } from "./order-reducer/rejectOrderReducer"
import { acceptOrderReducer } from "./order-reducer/acceptOrderReducer"
import { subAdminReducer } from "./subAdminReducer"
import { getMenuReducer } from "./menu-reducer/menuReducer"
import { getNavBarReducer } from "./menu-reducer/navBarReducer"
import { dashboardReducer } from "./dashboard/dashboardReducer"
import { seminarReducer } from "./seminar-reducer/seminarReducer"
import { attemptReducer } from "./testattempt-reducer/testattemptReducer"
import { completeSeminarReducer } from "./complete-seminar/completeSeminarReducer"
import { paymentReducer } from "./payment-reducer/paymenthistoryReducer";
import { questionsReducer } from "./question-reducer/questionReducer";

export const reducers = combineReducers({
  login: loginReducer,
  changePassword: changePasswordReducer,
  userList: userListReducer,
  categories: categoriesReducer,
  subcategories: getReducer,
  deleteSubCategory: deleteReducer,
  product: productReducer,
  editProfile: editProfileReducer,
  deleteCategory: deleteCategoryReducer,
  userDetails: userDetailsReducer,
  deleteUser: deleteUserReducer,
  addCategory: categoriesReducer,
  delete: deleteReducer,
  add: addReducer,
  addSubCategory: addReducer,
  update: updateReducer,
  banner: getReducer,
  getBannerDetails: getDetailsReducer,
  order: getReducer,
  userproduct: userproductReducer,
  userOrder: userOrderReducer,
  acceptOrder: acceptOrderReducer,
  rejectOrder: rejectOrderReducer,
  subAdminList: subAdminReducer,
  menuList: getMenuReducer,
  navBarList: getNavBarReducer,
  dashboard: dashboardReducer,
  attempt: attemptReducer,
  seminar: seminarReducer,
  completeSeminar: completeSeminarReducer,
  paymentHistory: paymentReducer,
  questionList: questionListReducer,
  questionDetails: questionsReducer,
});
