import React, { Component } from "react";
import {Table} from "react-bootstrap";


class LastOrders extends Component {
    
    render() {
        
         // var list = this.props.item.newOrder?this.props.item.newOrder.data:[];
        //  console.log("{{{{}}}}}}{{{{{{{}}}}}",this.props.newOrder)
        return (
            <Table responsive>
                <thead>
                    <tr>
                    <th align="left">Customer</th>
                    <th align="center">Days</th>
                    <th align="right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        // list?(
                        //     <tr>
                        //         <td align="left">{list[0]}</td>
                        //         <td align="center">{list[0].days}</td>
                        //         <td align="right">₹{list[0].totalPrice}</td>
                        //     </tr>):
                        //     ("")
                    }
                    
                </tbody>
            </Table>
        );
    }
}

export default LastOrders 
