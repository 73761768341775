import { ACTIONS } from "../../action-constants/Actions";

export const dashboardReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FETCHING:{
            let newState = { ...{}, ...state };
            newState.isDashboardFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        case ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.isDashboardFetching=false
            newState.list =  result;
            newState.totalCount = 0
            newState.filter = result?result.filter:{}
            return newState;
        }
        case ACTIONS.DASHBOARD.DASHBOARD_LIST.DASHBOARD_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.isDashboardFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        default:{
            return state;
        }
    }
}