import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Countries,
  TaxClasses,
  TaxRates,
  ListingZones,
} from '../../../components/mainComponents/taxLocation';

import {
  Devices,
  SendNotifications,
} from '../../../components/mainComponents/notifications';

import {
  LowStockProducts,
  ProductsOutofStock,
  CustomerOrdersTotal,
  StatsProductsPurchased,
  StatsProductsLiked,
} from '../../../components/mainComponents/reports';

import {
  ConstantBanners,
  MailchimpSetting,
  SlidersImages,
  ContentPages,
  WebsiteThemes,
  SeoContent,
  CustomCssJs,
  WebSettings,
} from '../../../components/mainComponents/settingsWebsite';

import {
  Admin,
  ManageRoles,
} from '../../../components/mainComponents/manageAdmins';

import {
  Units,
  OrderStatus,
  FacebookSettings,
  GoogleSettings,
  PushNotification,
  Settings
} from '../../../components/mainComponents/generalSettings';

import {
  Dashboard,
  ChangePassword,
  User,
  UserDetail,
  Category,
  AddCategory,
  Product,
  GetDeals,
  EditProfile,
  EditCategory,
  AddDeals,
  EditDeals,
  ViewDeals,
  Banner,
  AddBanner,
  EditBanner,
  SubCategory,
  AddSubCategory,
  Order,
  Languages,
  Manufacturers,
  Customers,
  Coupons,
  ShippingMethods,
  PaymentMethods,
  AddSubAdmin,
  SubAdmin,
  EditSubAdmin,
  EditProduct,
  EditUserDetail,
  StudentCertificate,
  CompleteSeminarStudent,
  Question,
  EditQuestion,
  AddQuestion
  
} from "../../../components/mainComponents";

export default class ContentWrapper extends Component {
  render() {
    console.log("content wrapper calling");
    return (
      <div className="content">
        <Switch>
          <Route exact path="/portal" render={(props) => {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user && user.token) {
              return <Redirect from="/portal" to="/user" />
            } else {
              return <Redirect from="/portal" to="/login" />
            }
          }}  />
          <Route exact path="/portal/change-password" component={ChangePassword} />
          <Route path="/portal/user" component={User} />
          <Route path="/portal/excel-report" component={CompleteSeminarStudent} />
          <Route exact path="/portal/user-detail/:id" component={UserDetail} />
          <Route exact path="/portal/user-edit/:id" component={EditUserDetail} />
          <Route exact path="/portal/student-certificate/:id/:seminarId" component={StudentCertificate} />
          <Route exact path="/portal/add-category" component={AddCategory} />
          <Route path="/portal/category" component={Category} />
          <Route exact path="/portal/edit-category/:id" component={EditCategory} />
          <Route path="/portal/sub-category/:id" component={SubCategory} />
          <Route path="/portal/addsub-category/:id" component={AddSubCategory} />
          <Route path="/portal/product" component={Product} />
          <Route path="/portal/viewproduct/:id" component={EditProduct} />
          <Route path="/portal/Order" component={Order} />
          <Route path="/portal/deals" component={GetDeals} />
          <Route path="/portal/edit-profile" component={EditProfile} />
          <Route path="/portal/add-deals" component={AddDeals} />
          <Route path="/portal/edit-deals/:id" component={EditDeals} />
          <Route path="/portal/view-deals/:id" component={ViewDeals} />
          <Route path="/portal/banner" component={Banner} />
          <Route path="/portal/add-banner" component={AddBanner} />
          <Route path="/portal/edit-banner/:id" component={EditBanner} />
          <Route path="/Dashboard" component={Dashboard} />
          <Route path="/portal/Languages" component={Languages} />
          <Route path="/portal/Manufacturers" component={Manufacturers} />
          <Route path="/portal/Customers" component={Customers} />
          <Route path="/portal/Countries" component={Countries} />
          <Route path="/portal/TaxClasses" component={TaxClasses} />
          <Route path="/portal/TaxRates" component={TaxRates} />
          <Route path="/portal/ListingZones" component={ListingZones} />
          <Route path="/portal/Coupons" component={Coupons} />
          <Route path="/portal/Devices" component={Devices} />
          <Route path="/portal/SendNotifications" component={SendNotifications} />
          <Route path="/portal/ShippingMethods" component={ShippingMethods} />
          <Route path="/portal/PaymentMethods" component={PaymentMethods} />
          <Route path="/portal/LowStockProducts" component={LowStockProducts} />
          <Route path="/portal/ProductsOutofStock" component={ProductsOutofStock} />
          <Route path="/portal/CustomerOrdersTotal" component={CustomerOrdersTotal} />
          <Route path="/portal/StatsProductsPurchased" component={StatsProductsPurchased} />
          <Route path="/portal/StatsProductsLiked" component={StatsProductsLiked} />
          <Route path="/portal/ConstantBanners" component={ConstantBanners} />
          <Route path="/portal/MailchimpSetting" component={MailchimpSetting} />
          <Route path="/portal/SlidersImages" component={SlidersImages} />
          <Route path="/portal/ContentPages" component={ContentPages} />
          <Route path="/portal/WebsiteThemes" component={WebsiteThemes} />
          <Route path="/portal/SeoContent" component={SeoContent} />
          <Route path="/portal/CustomCssJs" component={CustomCssJs} />
          <Route path="/portal/WebSettings" component={WebSettings} />
          <Route path="/portal/Admin" component={Admin} />
          <Route path="/portal/ManageRoles/:id" component={ManageRoles} />
          <Route path="/portal/Units" component={Units} />
          <Route path="/portal/OrderStatus" component={OrderStatus} />
          <Route path="/portal/FacebookSettings" component={FacebookSettings} />
          <Route path="/portal/GoogleSettings" component={GoogleSettings} />
          <Route path="/portal/PushNotification" component={PushNotification} />
          <Route path="/portal/Settings" component={Settings} />
          <Route path="/portal/subadmin" component={SubAdmin} />
          <Route path="/portal/addsubadmin" component={AddSubAdmin} />
          {/* <Route path="/portal/editsubadmin/:id" component={EditSubAdmin} /> */}
          <Route exact path="/portal/questions" component={Question} />
          <Route exact path="/portal/question-add" component={AddQuestion} />
          <Route exact path="/portal/question-edit/:id" component={EditQuestion} />
          
        </Switch>
      </div>
    );
  }
}
