import { ACTIONS } from "../action-constants/Actions";

export const editProfileReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITING:{

            let newState = { ...{}, ...state };

            newState.isSuccess = false
            return newState
        }
        case ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITED:{

            let newState = { ...{}, ...state };

            let { result, message } = action.payload;

            newState.isSuccess = true
            newState.msg = message

            return newState
        }
        case ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITING_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false

            newState.msg = error;

            return newState
        }
        default:{
            return state;
        }
    }
}