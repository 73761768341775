
import React, { Component } from 'react';
import { Heading} from '../../commonComponents';
import { Link } from 'react-router-dom';
import defaultuser from '../../../../assets/images/defaultuser.png';

class StatsProductsLiked extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Stats Products Liked"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Image</th>
                  <th>Products</th>
                  <th>Liked</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                      1
                    </td>
                    <td>
                    <img src={defaultuser} width="100" className="image" alt="Product Images"/>
                    </td>
                    <td>
                    CLASSIC FIT SOFT-TOUCH POLO
                    </td>
                    <td>
                    10
                    </td>
                    <td>
                    <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default StatsProductsLiked 