import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Heading,InputBox,SelectBox,MaterialUIPickers,LoadingComponent } from "../../commonComponents";
import { GetUserDetailsApi } from '../../../../store/api-actions/GetUserDetailsApi';
import { UpdateUserApi } from '../../../../store/api-actions/user/UpdateUserApi';
import certificate from '../../../../assets/certificate/certificate.jpg';
import { CONSTANTS } from "../../../../constants/constant";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { SeminarApi } from "../../../../store/api-actions/seminarResult/SeminarResultApi";

import moment from 'moment'
const mapDispatchToProps = (dispatch)=>{

    return {
        Seminar: (reqData)=>dispatch(SeminarApi(reqData)),
        userDetails: (reqObj)=>dispatch(GetUserDetailsApi(reqObj)),
        UpdateUser: (reqObj)=>dispatch(UpdateUserApi(reqObj)),
    }
}

const mapStateToProps = (state)=>{
 
    return {
        userDetailsState: state.userDetails,
        seminarState:state.seminar
    }
}

class StudentCertificate extends Component {

    constructor(props){
        super(props);
        this.state={
            isLoading:true,
            isWarningConfirMsg:false,
            isSuccessConfirMsg:false,
            isFailedConfirMsg:false,
            _id:"",
            message: null,
            title:"",
            formFields: {},
            errors: {},
            messageElement: false,
            success: false,
            s3file:"",
            isLoader:false,
            image:"",
        }
    }

    componentDidMount(){
        let { match } = this.props
        let reqObj = {};

        let reqData = {};
        reqData.userId = this.props.match.params.id
        this.props.Seminar(reqData).then(res=>{})

        if(match.params.id){
          reqObj.userId	= match.params.id;
        }
        this.props.userDetails(reqObj).then(res=>{
          const { message = null, result = null } = res;
          console.log("####$$$$$$$",this.props)
          this.setState({isLoading:true})
        })
    }

    editUserHandler = (values, formikBag)=> {
        console.log("000000077777",values)
        this.setState({isLoading:true});
        values.dob = moment(values.dob, "YYYY/M/D").valueOf();
        this.props.UpdateUser(values).then(res=>{
          if(res.status && Object.keys(res.result).length  > 0){
            let { history } = this.props
            this.setState({isLoading:false,isSuccessConfirMsg:true,message:MESSAGE.USER_DETAILS_UPDATED,title:MESSAGE.SUCCESS_TITLE});
            }else{
              this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
          }
        })
      }

    render() {
            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/portal/user`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
        let {result} = this.props.userDetailsState;
        let {list} = this.props.seminarState;
        let date = null;
        if(this.props.match.params.seminarId){
            let dt = list.find((hero)=> {
                return hero.seminarId == this.props.match.params.seminarId;
            });
            console.log("completio adte dkf ",dt)
            date = dt?moment(dt.created).format('MM/DD/YYYY'):null; 
        }
        
        return (
            <div className="album pt-3">
            {loader}
            {conm}
            <div className="container-fluid">
            <Heading  headingText="Student Certificate"/>
            <hr/>
            <div className="container">
                <div  style={{position:"relative",width:"100%",float:"left"}} >
                <img src={certificate} style={{width:"100%"}} />   
                <div style={CONSTANTS.MAINDIV}>
                    <div style={CONSTANTS.NAMEDIV}> {result.firstName+' '+result.middleName+' '+result.lastName}</div>
                    <div style={CONSTANTS.CONTENTDIV}>
                    <p style={CONSTANTS.PARA}> For The Six-Hour Online Continuing Education {this.props.match.params.seminarId} for Driving School Instructor and/or Operators  </p>
                    <p style={CONSTANTS.PARA2}>I certify that I Completed the seminar as show above.</p>
                    <p style={CONSTANTS.PARA2}>I certify under penalty of perjury that the foregoing is true and correct..</p>
                    </div>
                    <div style={CONSTANTS.schoolname}>
                    <span>School Name: {result.schoolName} &nbsp;&nbsp;&nbsp;</span>
                    <span> Occupation License Number : {result.instructorLicense} &nbsp;&nbsp;&nbsp;  </span>
                    <span> Completion Date: {date}</span>
                    </div>
                    <div style={CONSTANTS.signature}>
                    <span>Signature of Student:_____________</span>
                    </div>
                </div>
                
                </div>         
            </div>
            </div>
          </div>
        )
    }
}

export default  StudentCertificate = connect(mapStateToProps, mapDispatchToProps)(StudentCertificate);