import { ACTIONS } from "../../action-constants/Actions"


const DealsFetching=()=>{

    return {
        type: ACTIONS.DEALS.DEALS_LIST.DEALS_FETCHING,
        payload:{
            isDealsFetching:true,
            
        }
    }
}

const DealsFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.DEALS.DEALS_LIST.DEALS_FAILED,
        payload:{
            isDealsFetching:false,
            result,
            msg
        }
    }
}

const DealsFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.DEALS.DEALS_LIST.DEALS_FETCHED,
        payload:{
            isDealsFetching:false,
            result:result,
            message:msg
        }
    }
}

export const deals = {
    DealsFetching,
    DealsFailed,
    DealsFetched
}