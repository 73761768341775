export const ACTIONS = {

    USER_LOGIN:{
        USER_LOGGING:"LOGGING[USER]",
        USER_LOGGEDIN:"LOGGEDIN[USER]",
        USER_FAIL_LOGIN: "LOGINFAIL[USER]",
    },
    FORGET_PASSWORD:{
        FORGETING:"FORGETING[USER]",
        FORGETED:"FORGETED[USER]",
        FORGETING_FAILED: "FORGETING_FAILED[USER]",
    },
    USER_CHANGE_PASSWORD:{
        USER_PASSWORD_CHANGING:"CHANGING[USER]",
        USER_PASSWORD_CHANGED:"CHANGED[USER]",
        USER_PASSWORD_CHANGING_FAILED: "FAILED[USER]",
    },
    USER_LIST:{
        USER_LIST_FETCHING:"FETCHING[USER]",
        USER_LIST_FETCHED:"FETCHED[USER]",
        USER_LIST_FAILED: "FAILED[USER]",
    },
    SEMINAR_USER_LIST:{
        SEMINAR_USER_LIST_FETCHING:"FETCHING[SEMINAR_USER]",
        SEMINAR_USER_LIST_FETCHED:"FETCHED[SEMINAR_USER]",
        SEMINAR_USER_LIST_FAILED: "FAILED[SEMINAR_USER]",
    },
    USER_DETAIL:{
        USER_DETAIL_FETCHING:"FETCHING[USER_DETAIL]",
        USER_DETAIL_FETCHED:"FETCHED[USER_DETAIL]",
        USER_DETAIL_FAILED: "FAILED[USER_DETAIL]",
    },
    USER:{
        DELETEUSER:{
            USER_DELETING:"DELETING[DELETEUSER]",
            USER_DELETED:"DELETED[DELETEUSER]",
            USER_DELETING_FAILED: "DELETING_FAILED[DELETEUSER]",
        }
    },
    SUBADMIN_LIST:{
        SUBADMIN_LIST_FETCHING:"FETCHING[SUBADMIN]",
        SUBADMIN_LIST_FETCHED:"FETCHED[SUBADMIN]",
        SUBADMIN_LIST_FAILED: "FAILED[SUBADMIN]",
    },
    CATEGORY:{
        CATEGORY_FETCHING:"FETCHING[CATEGORY]",
        CATEGORY_FETCHED:"FETCHED[CATEGORY]",
        CATEGORY_FAILED: "FAILED[CATEGORY]",
        ADDCATEGORY:{
            CATEGORY_ADDING:"ADDING[CATEGORY]",
            CATEGORY_ADDED:"ADDED[CATEGORY]",
            CATEGORY_FAILED: "FAILED[CATEGORY]",
        },
        EDITCATEGORY:{
            CATEGORY_EDITING:"EDITING[CATEGORY]",
            CATEGORY_EDITED:"EDITED[CATEGORY]",
            CATEGORY_EDITING_FAILED:"EDITING_FAILED[CATEGORY]"
        },
        GETCATEGORYDETAILS:{
            GETCATEGORYDETAILS_FETCHING:"FETCHING[GETCATEGORYDETAILS]",
            GETCATEGORYDETAILS_FETCHED:"FETCHED[GETCATEGORYDETAILS]",
            GETCATEGORYDETAILS_FAILED: "FAILED[GETCATEGORYDETAILS]",
        },
        DELETECATEGORY:{
            CATEGORY_DELETING:"DELETING[DELETECATEGORY]",
            CATEGORY_DELETED:"DELETED[DELETECATEGORY]",
            CATEGORY_DELETING_FAILED: "DELETING_FAILED[DELETECATEGORY]",
        },
        
    },
    PRODUCT:{
        PRODUCT_LIST:{
            PRODUCT_FETCHING:"FETCHING[PRODUCT]",
            PRODUCT_FETCHED:"FETCHED[PRODUCT]",
            PRODUCT_FAILED:"FAILED[PRODUCT]"
        },
        USER_PRODUCT_LIST:{
            USER_PRODUCT_FETCHING:"FETCHING[USER_PRODUCT]",
            USER_PRODUCT_FETCHED:"FETCHED[USER_PRODUCT]",
            USER_PRODUCT_FAILED:"FAILED[USER_PRODUCT]"
        },
        DELETE_PRODUCT:{
            PRODUCT_DELETING:"DELETING[DELETE_PRODUCT]",
            PRODUCT_DELETED:"DELETED[DELETE_PRODUCT]",
            PRODUCT_DELETING_FAILED: "DELETING_FAILED[DELETE_PRODUCT]",
        },
    },
    ORDER:{
        ORDER_LIST:{
            ORDER_FETCHING:"FETCHING[ORDER]",
            ORDER_FETCHED:"FETCHED[ORDER]",
            ORDER_FAILED:"FAILED[ORDER]"
        },
        REJECT_ORDER_LIST:{
            REJECT_ORDER_FETCHING:"FETCHING[REJECT_ORDER]",
            REJECT_ORDER_FETCHED:"FETCHED[REJECT_ORDER]",
            REJECT_ORDER_FAILED:"FAILED[REJECT_ORDER]"
        },
        ACCEPT_ORDER_LIST:{
            ACCEPT_ORDER_FETCHING:"FETCHING[ACCEPT_ORDER]",
            ACCEPT_ORDER_FETCHED:"FETCHED[ACCEPT_ORDER]",
            ACCEPT_ORDER_FAILED:"FAILED[ACCEPT_ORDER]"
        },
        USER_ORDER_LIST:{
            USER_ORDER_FETCHING:"FETCHING[USER_ORDER]",
            USER_ORDER_FETCHED:"FETCHED[USER_ORDER]",
            USER_ORDER_FAILED:"FAILED[USER_ORDER]"
        },
        DELETE_ORDER:{
            ORDER_DELETING:"DELETING[DELETE_ORDER]",
            ORDER_DELETED:"DELETED[DELETE_ORDER]",
            ORDER_DELETING_FAILED: "DELETING_FAILED[DELETE_ORDER]",
        },
    },
    DASHBOARD:{
        DASHBOARD_LIST:{
            DASHBOARD_FETCHING:"FETCHING[DASHBOARD]",
            DASHBOARD_FETCHED:"FETCHED[DASHBOARD]",
            DASHBOARD_FAILED:"FAILED[DASHBOARD]"
        }
    },
    MENU:{
        MENU_FETCH:{
            MENU_FETCH_FETCHING:"FETCHING[MENU_FETCH]",
            MENU_FETCH_FETCHED:"FETCHED[MENU_FETCH]",
            MENU_FETCH_FAILED:"FAILED[MENU_FETCH]"
        },
        NAVBAR_FETCH:{
            NAVBAR_FETCH_FETCHING:"FETCHING[NAVBAR_FETCH]",
            NAVBAR_FETCH_FETCHED:"FETCHED[NAVBAR_FETCH]",
            NAVBAR_FETCH_FAILED:"FAILED[NAVBAR_FETCH]"
        }
    },
    DEALS:{
        DEALS_LIST:{
            DEALS_FETCHING:"FETCHING[DEALS]",
            DEALS_FETCHED:"FETCHED[DEALS]",
            DEALS_FAILED:"FAILED[DEALS]"
        }
    },
    ADMINPROFILE:{
        EDIT_PROFILE:{
            PROFILE_EDITING:"EDITING[PROFILE]",
            PROFILE_EDITED:"EDITED[PROFILE]",
            PROFILE_EDITING_FAILED:"EDITING_FAILED[PROFILE]"
        }
    },
    COMMON_ACTION:{
        DELETE:{
            DELETING:"DELETING[DELETE]",
            DELETED:"DELETED[DELETE]",
            DELETING_FAILED: "DELETING_FAILED[DELETE]",
        },
        ADD:{
            ADDING:"ADDING[ADD]",
            ADDED:"ADDED[ADD]",
            ADDING_FAILED: "ADDING_FAILED[DELETE]",
        },
        DETAILS:{
            DETAILS_FETCHING:"DETAILS_FETCHING[DETAILS]",
            DETAILS_FETCHED:"DETAILS_FETCHED[DETAILS]",
            DETAILS_FAILED: "DETAILS_FAILED[DETAILS]",
        },
        UPDATE:{
            UPDATING:"UPDATING[UPDATE]",
            UPDATED:"UPDATED[UPDATE]",
            UPDATING_FAILED: "UPDATING_FAILED[UPDATE]",
        },
        FETCH:{
            FETCHING:"FETCHING[FETCH]",
            FETCHED:"FETCHED[FETCH]",
            FETCHING_FAILED: "FETCHING_FAILED[FETCH]",
        }
    },
    RESULT:{
        ATTEPMT:{
            ATTEPMT_FETCHING:"FETCHING[ATTEPMT]",
            ATTEPMT_FETCHED:"FETCHED[ATTEPMT]",
            ATTEPMT_FAILED:"FAILED[ATTEPMT]"
        },
        SEMINAR:{
            SEMINAR_FETCHING:"FETCHING[SEMINAR]",
            SEMINAR_FETCHED:"FETCHED[SEMINAR]",
            SEMINAR_FAILED:"FAILED[SEMINAR]"
        },
        HISTORY:{
            HISTORY_FETCHING:"FETCHING[HISTORY]",
            HISTORY_FETCHED:"FETCHED[HISTORY]",
            HISTORY_FAILED:"FAILED[HISTORY]"
        },
    },
    QUESTION:{
        QUESTION_FETCHING:"FETCHING[QUESTION]",
        QUESTION_FETCHED:"FETCHED[QUESTION]",
        QUESTION_FAILED: "FAILED[QUESTION]",
        ADDQUESTION:{
            QUESTION_ADDING:"ADDING[QUESTION]",
            QUESTION_ADDED:"ADDED[QUESTION]",
            QUESTION_FAILED: "FAILED[QUESTION]",
        },
        EDITQUESTION:{
            QUESTION_EDITING:"EDITING[QUESTION]",
            QUESTION_EDITED:"EDITED[QUESTION]",
            QUESTION_EDITING_FAILED:"EDITING_FAILED[QUESTION]"
        },
        GETQUESTIONDETAILS:{
            GETQUESTIONDETAILS_FETCHING:"FETCHING[GETQUESTIONDETAILS]",
            GETQUESTIONDETAILS_FETCHED:"FETCHED[GETQUESTIONDETAILS]",
            GETQUESTIONDETAILS_FAILED: "FAILED[GETQUESTIONDETAILS]",
        },
        DELETEQUESTION:{
            QUESTION_DELETING:"DELETING[DELETEQUESTION]",
            QUESTION_DELETED:"DELETED[DELETEQUESTION]",
            QUESTION_DELETING_FAILED: "DELETING_FAILED[DELETEQUESTION]",
        },
        
    }

}