import React from 'react';

const SearchBox = (props)=>{
    return(
        
            
              <div className="input-group">
                <input type="text" class="form-control pl-2" onChange={props.inputOnChange} placeholder="Search..." name={props.inputName} />
                <div className="input-group-append">
                  <button className="btn theme-button text-white" type="submit" onClick={props.btnOnClick} >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
           

           
       
    )
}

export default SearchBox; 