import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { product } from "../actions/Product/Product";

export const ProductApi = form => {
  return (dispatch, getState) => {
    dispatch(product.ProductFetching());
    return _callApi(
      END_POINT.PRODUCT.PRODUCT_LIST.END_POINT,
      END_POINT.PRODUCT.PRODUCT_LIST.METHOD,
      form
    ).then(res => {
      if(form){
        res.data.result.filter = form;
      }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(product.ProductFailed({result,message}));
        return res.data;
      } else {
        dispatch(product.ProductFetched({result,message}));
        return res.data;
      }
    });
  };
};
