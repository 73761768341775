import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { GetSubAdmin } from "../../actions/SubAdmin/GetSubAdmin";

export const GetSubAdminApi = form => {
  return (dispatch, getState) => {
    dispatch(GetSubAdmin.SubAdminFetching());
    return _callApi(
        END_POINT.USERLIST.END_POINT,
        END_POINT.USERLIST.METHOD,
      form
    ).then(res => {
      res.data.result.filter={}
      if(form){
        res.data.result.filter = form;
      }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(GetSubAdmin.SubAdminFailed({result,message}));
        return res.data;
      } else {
        dispatch(GetSubAdmin.SubAdminFetched({result,message}));
        return res.data;
      }
    });
  };
};
