import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Heading,Image,Button,FilterProducts } from '../../commonComponents';
import { ProductApi } from "../../../../store/api-actions/Product";
import {NavLink} from 'react-router-dom';
import {Dropdown,Row,Col} from "react-bootstrap";
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import { END_POINT } from "../../../../constants/apiEndPoints";
import Loader from 'react-loader-spinner';
import { DeleteProductApi } from "../../../../store/api-actions/product/deleteProductApi";
import { VerifyProductApi } from "../../../../store/api-actions/product/verifyProductApi";
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';




const mapDispatchToProps = dispatch => {
  return {
    product: (reqData)=>dispatch(ProductApi(reqData)),
    delete:(reqData) => dispatch(DeleteProductApi(reqData)),
    verifyProduct:(reqData) => dispatch(VerifyProductApi(reqData))
  };
};

const mapStateToProps = state => {
  return {
    productState: state.product,
    deleteState: state.delete
  };
};

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:"",
          alertMessage:""
        };
      }
      componentDidMount() {
        this.props.product().then(res=>{})
      }

      handlePageClick = data => {
        let reqData = {};
        if(data && !isNaN(data.selected)){
          reqData.pageNo = parseInt(data.selected)+1;
        }
        if(data && data.target && data.target.value){
          reqData.title = data.target.value;
        }
        this.props.product(reqData).then(res=>{})
      };
      
      handleDelete = data => {
        let reqData = {};
        if(data._id){
          reqData.productId = data._id;
        }
        this.props.delete(reqData).then(res=>{
          this.setState({isWarningConfirMsg:false,isSuccessConfirMsg:true,_id:"",alertMessage:MESSAGE.SUCCESS_TITLE});
        })
      };

      handleVerify = data =>{
        let reqData = {};
        if(data._id){
          reqData.productId = data._id;
        }
        if(data.status){
          reqData.status = data.status;
        }
        
        this.props.verifyProduct(reqData).then(res=>{
          this.setState({isWarningConfirMsg:false,isSuccessConfirMsg:true,_id:"",alertMessage:data.status?MESSAGE.PRODUCT_VERIFY:MESSAGE.PRODUCT_UNVERIFY});
        })
      }
    
      cancelDelete = () => {
        this.setState({isWarningConfirMsg:false,_id:""});
      };
    
      confirmMsg = data => {
        this.setState({isWarningConfirMsg:true,_id:data._id});
      };

      render() {
        var conm = "";
        let { list,totalCount} = this.props.productState;
        var loader = "";

        if(this.state.isWarningConfirMsg){
          var obj = {
            isWarningConfirMsg:this.state.isWarningConfirMsg,
            confirmBtnText:MESSAGE.CONFIRM_BTN_TEXT,
            title:MESSAGE.WARNING_TITLE,
            onConfirm:()=>{
              this.handleDelete(this.state)
            },
            onCancel:()=>{
              this.cancelDelete(this.state)
            },
            subtitle:MESSAGE.WARNING_SUB_TITLE
          }
          conm = swal.warning(obj);
        }
    
        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false,alertMessage:""});
              this.handlePageClick();
            },
            subtitle:this.state.alertMessage
          }
          conm = swal.success(obj);
        }

        if(this.props.productState.isProductFetching){
         loader =  <div className="loader-custom">
          <Loader
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={30000000} //3 secs

          />
        </div>
        }
        //console.log('^^^^^^^^^^^^^^',categoryState)
        return (
          <div className="album pt-3">
            
           <div className="container-fluid">
           <Heading headingClass="bg-light" headingText="All user"/>
           <hr />
             <Row>
               <Col className="col-sm-4" ms="4">
                <SearchBox 
                    inputOnChange={this.handlePageClick}
                    inputName="name"
                    btnOnClick={this.handlePageClick}
                  />
               </Col>
               <Col className="col-sm-8" ms="8">
                  {/* <FilterProducts/> */}
               </Col>
             </Row> 
           
           <hr />
           {conm}
           <div className="row"> 
            <div className="col-md-12">
            <NavLink to="/portal/add-category">
               {/* <Button btnType="button" btnClass="btn btn-info pull-right" btnText="Add Category" /> */}
            </NavLink>   
            </div>
           </div>
            <div className="clearfix">&nbsp;</div>
              <div className="table-responsive postion-relative">
                { loader } 
                
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Image</th>
                      <th>title</th>
                      <th>Price</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>User Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td><Image imageSrc={ END_POINT.S3BASE_URL+item.image[0]} imageClass="ima-size-30" /></td>
                        <td>{item.title}</td>
                        <td>{item.price}</td>
                        <td>{item.category}</td>
                        <td>{item.subCategory}</td>
                        <td>{item.userName}</td>
                        <td>
                          <div className="d-flex-grid tooltipcls">
                          <Dropdown>
                            
                              {
                                item.status==0?
                                (<Dropdown.Toggle  className="Pending">Pending</Dropdown.Toggle>):
                                (
                                  item.status==1?
                                  (<Dropdown.Toggle  className="Verified">Verified</Dropdown.Toggle>):
                                  (<Dropdown.Toggle  className="Unverified">Unverified</Dropdown.Toggle>)
                                )
                              }
                            
                              <Dropdown.Menu>
                                {
                                  item.status==0?(
                                    <>
                                    <Dropdown.Item onClick={()=>{
                                      item.status = 1
                                      this.handleVerify(item)
                                      }}>Verify</Dropdown.Item>
                                      <Dropdown.Item onClick={()=>{
                                        item.status =2
                                      this.handleVerify(item)
                                      }}>Unverify</Dropdown.Item>
                                    </>
                                  ):(
                                    <Dropdown.Item onClick={()=>{
                                      item.status==1?(item.status = 2):(item.status=1)
                                      this.handleVerify(item)
                                      }}>{item.status==1?"Unverified":"Verify"}</Dropdown.Item>
                                  )
                                }
                                
                              </Dropdown.Menu>
                          </Dropdown>
                          </div>
                        </td>
                         <td>
                          <Link className="badge bg-light-blue" to={"/portal/viewproduct/"+item._id} data-toggle="tooltip" data-original-title="Edit">
                          <i className="fa fa-eye" title="Details"></i>
                          </Link>

                          <Link onClick={()=>{
                            this.confirmMsg(item)
                          }} className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                          <i className="fa fa-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount/END_POINT.LIMIT}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'text-center pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        );
      }
}
export default Product = connect(mapStateToProps, mapDispatchToProps)(Product);