
import React, { Component } from 'react';
import { Heading,Button,SelectBox,InputBox,} from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Link } from 'react-router-dom';
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import logo from '../../../../assets/images/logo.png'

class Settings extends Component {
  
  render() {
   
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Settings"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-12">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                        <InputBox
                            formWrapClass = "form-group"
                            labelText="App Name"
                            inputClass="form-control"
                            type="text"
                            name = "appname"
                            placeholder="Enter your App Name"
                            handleChange={handleChange}
                            value={values.appname}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Website URL"
                            inputClass="form-control"
                            type="text"
                            name = "websiteurl"
                            placeholder="Please enter your website URL."
                            handleChange={handleChange}
                            value={values.websiteurl}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Currency Symbol"
                            inputClass="form-control"
                            type="text"
                            name = "currencysymbol"
                            placeholder="Enter currency symbol for price."
                            handleChange={handleChange}
                            value={values.currencysymbol}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="New Product Duration"
                            inputClass="form-control"
                            type="text"
                            name = "newproductduration"
                            placeholder="20"
                            handleChange={handleChange}
                            value={values.newproductduration}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                         
                         <hr/>
                         <h5>Push Notification Setting (One Signal) </h5> 
                         <hr/>
                         <div class="upload-img">
                            <InputBox
                                formWrapClass = "form-group"
                                labelText="Website Logo"
                                inputClass="form-control"
                                type="file"
                                placeholder="Website Logo"
                                name = "websitelogo"
                                handleChange= {(e)=>{
                                let imgData = COMMON_FUNCTION.imagePreview(e);
                                this.setState({
                                    file: imgData.file,
                                    s3file : imgData.s3file
                                });
                                }}
                            />
                          </div>
                          <img className="" src={logo}/>
                          <div class="upload-img">
                            <InputBox
                                formWrapClass = "form-group"
                                labelText="Favicon"
                                inputClass="form-control"
                                type="file"
                                placeholder="favicon"
                                name = "favicon"
                                handleChange= {(e)=>{
                                let imgData = COMMON_FUNCTION.imagePreview(e);
                                this.setState({
                                    file: imgData.file,
                                    s3file : imgData.s3file
                                });
                                }}
                            />
                          </div>
                          <img className="" src={logo}/>
                          <hr/>
                            <h5>Inquery Email</h5> 
                          <hr/>
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Contact Us Email"
                            inputClass="form-control"
                            type="text"
                            name = "contactusemail"
                            placeholder="User will be contact to you via this email address."
                            handleChange={handleChange}
                            value={values.contactusemail}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <hr/>
                            <h5>Order Email</h5> 
                          <hr/>
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Order Email"
                            inputClass="form-control"
                            type="text"
                            name = "orderemail"
                            placeholder="PLease enter your email to order email."
                            handleChange={handleChange}
                            value={values.orderemail}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <hr/>
                            <h5>Free Shpping on Min Order Price</h5> 
                          <hr/>
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Min Order Price"
                            inputClass="form-control"
                            type="text"
                            name = "minorderprice"
                            placeholder="10"
                            handleChange={handleChange}
                            value={values.minorderprice}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <hr/>
                            <h5>Our Info</h5> 
                          <hr/>
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Phone Number"
                            inputClass="form-control"
                            type="text"
                            name = "phonenumber"
                            placeholder="User will be able to contact to you via this phone number."
                            handleChange={handleChange}
                            value={values.phonenumber}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Address"
                            inputClass="form-control"
                            type="text"
                            name = "address"
                            placeholder="Please enter your address."
                            handleChange={handleChange}
                            value={values.address}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="City"
                            inputClass="form-control"
                            type="text"
                            name = "city"
                            placeholder="Please enter your city."
                            handleChange={handleChange}
                            value={values.city}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="State"
                            inputClass="form-control"
                            type="text"
                            name = "state"
                            placeholder="Please enter your state."
                            handleChange={handleChange}
                            value={values.state}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Zip"
                            inputClass="form-control"
                            type="text"
                            name = "zip"
                            placeholder="Please enter your zip."
                            handleChange={handleChange}
                            value={values.zip}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Country"
                            inputClass="form-control"
                            type="text"
                            name = "country"
                            placeholder="Please enter your country."
                            handleChange={handleChange}
                            value={values.country}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Latitude"
                            inputClass="form-control"
                            type="text"
                            name = "latitude"
                            placeholder="Please enter your latitude for map."
                            handleChange={handleChange}
                            value={values.latitude}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Longitude"
                            inputClass="form-control"
                            type="text"
                            name = "longitude"
                            placeholder="Please enter your longitude for map."
                            handleChange={handleChange}
                            value={values.longitude}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />

                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="Update"
                        btnText="Update"
                      />
                      <Link to="#" className="btn btn-default" type="button">
                          Back
                      </Link>
                      </div>
                    </form>
                )}
                />
          </div>
            <div className="col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Settings 