export const MESSAGE = {
    WARNING_TITLE:"Are you sure?",
    WARNING_SUB_TITLE:"You will not be able to recover this imaginary file!",
    SUCCESS_TITLE:"SUCCESS.",
    SUCCESS_SUB_TITLE:"",
    CONFIRM_BTN_TEXT:"Yes, delete it!",
    CAT_SUCCESS_TITLE:"Category Added ",
    CAT_SUCCESS_SUB_TITLE:"Category Added Successfully. ",
    PASSWORD_CHANGED:"Password Changes successfully. ",
    PERMISSION:"Permission Changed Successfully. ",
    CAT_EDIT_TITLE:"Category Updated Successfully.",
    WARNING_USER_SUB_TITLE:"You will not be able to recover this User!",
    DELETE_USER_SUB_TITLE:"User deleted successfully.",
    PRODUCT_VERIFY:"Product Verify Successfully.",
    PRODUCT_UNVERIFY:"Product Unverify Successfully.",
    USER_DETAILS_UPDATED:"Student Details Updated Successfully",
    CERTIFICATE_SEND:"Certificate sent Successfully.",
    QUESTION_ADD: "Question Added successfully.",
    QUESTION_EDIT: "Question Updated successfully.",
    WARNING_QUESTION_SUB_TITLE:"You will not be able to recover this Question!",
    DELETE_QUESTION_SUB_TITLE:"Question deleted successfully.",


}