import { ACTIONS } from "../../action-constants/Actions"


const Deleting=()=>{
    return {
        type: ACTIONS.COMMON_ACTION.DELETE.DELETING,
        payload:{
            isDeleting:true,
            
        }
    }
}

const DeletingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.DELETE.DELETING_FAILED,
        payload:{
            isDeleting:false,
            msg
        }
    }
}

const Deleted=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.DELETE.DELETING_FAILED,
        payload:{
            isDeleting:false
        }
    }
}


export const Delete = {
    Deleting,
    DeletingFailed,
    Deleted
}