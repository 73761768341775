import { ACTIONS } from "../../action-constants/Actions"


const ProfileEditing=()=>{

    return {
        type: ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITING,
        payload:{
            isProfileEditing:true,
            
        },
        isSuccess: false,
    }
}

const ProfileEditingFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITING_FAILED,
        payload:{
            isProfileEditing:false,
            result,
            msg
        },
        isSuccess: false,
    }
}

const ProfileEdited=({ result })=>{
    
    return {
        type: ACTIONS.ADMINPROFILE.EDIT_PROFILE.PROFILE_EDITED,
        payload:{
            isProfileEditing:false,
            result:result,
            // message:msg
        },
        isSuccess: true,
    }
}

export const editProfile = {
    ProfileEditing,
    ProfileEditingFailed,
    ProfileEdited
}