import { ACTIONS } from "../action-constants/Actions";

export const productReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FETCHING:{

            let newState = { ...{}, ...state };

            newState.isProductFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}
            return newState
        }
        case ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FETCHED:{
            
            let newState = { ...{}, ...state };
          
            let { result, message } = action.payload;

            newState.isProductFetching=false
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0
            newState.filter = result?result.filter:{}

            return newState;
        }
        case ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;

            newState.isProductFetching=false
            newState.list=[];
            newState.totalCount=0
            newState.filter={}

            return newState
        }
        default:{
            return state;
        }
    }
}