import { ACTIONS } from "../../action-constants/Actions"


const UserDetailFetching=({ result, msg })=>{

    return {
        type: ACTIONS.USER_DETAIL.USER_DETAIL_FETCHING,
        payload:{
            isUserDetailsFetching:true
        }
    }
}

const UserDetailFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.USER_DETAIL.USER_DETAIL_FETCHING,
        payload:{
            isUserDetailsFetching:false,
            result,
            msg
        }
    }
}

const UserDetailFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.USER_DETAIL.USER_DETAIL_FETCHED,
        payload:{
            isUserDetailsFetching:false,
            result,
            msg
        }
    }
}

export const User = {
    UserDetailFailed,
    UserDetailFetching,
    UserDetailFetched
}