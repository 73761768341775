import { ACTIONS } from "../../action-constants/Actions"


const MenuFetching=()=>{

    return {
        type: ACTIONS.MENU.MENU_FETCH.MENU_FETCH_FETCHING,
        payload:{
            isMenuFetching:true,
        }
    }
}

const MenuFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.MENU.MENU_FETCH.MENU_FETCH_FAILED,
        payload:{
            isMenuFetching:false,
            result,
            msg
        }
    }
}

const MenuFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.MENU.MENU_FETCH.MENU_FETCH_FETCHED,
        payload:{
            isMenuFetching:false,
            result:result,
            message:msg
        }
    }
}

export const Menu = {
    MenuFetching,
    MenuFailed,
    MenuFetched
}