import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
// let UserLogin = require("../actions/UserLogin")
import { userForget } from "../actions/ForgetPassword";

export const ForgetPasswordApi = form => {
  console.log(form);
  return (dispatch, getState) => {
    dispatch(userForget.UserForgeting());
    return _callApi(
      END_POINT.FORGET_PASSWORD.END_POINT,
      END_POINT.FORGET_PASSWORD.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
       
      if ( Object.keys(result).length === 0) {
        dispatch(userForget.UserForgetingFail(message));
        return res.data;
      } else {
        dispatch(userForget.UserForgeted(result));
        return res.data;
      }
    });
  };
};
