export const END_POINT = {

    //S3 bucket base url
    S3BASE_URL:"",
    // method 1 is for POST and 2 is for GET and 3 is for put and 4 is for delete

    // BASE_URL:"http://localhost:8009/deelzat/api/v1/admin/",
    // IMAGE_URL: "http://localhost:8009/images/",
    // APP_BASE:"http://localhost:3000/admin",
    // BASE_URL:"https://instructorseminar.com:8009/deelzat/api/v1/admin/",
    // APP_BASE:"https://instructorseminar.com/admin",
    // IMAGE_URL: "https://instructorseminar.com/images/",
    BASE_URL:"https://drivinginstructorlicensecourse.com:8009/deelzat/api/v1/admin/",
    APP_BASE:"http://admin.drivinginstructorlicensecourse.com/",
    IMAGE_URL: "https://instructorseminar.com/images/",
    LIMIT:10,
    PAGENUMBER:1,
    LOGIN:{
        END_POINT:'login',
        METHOD:1
    },
    FORGET_PASSWORD:{
        END_POINT:'forgetPassword',
        METHOD:1
    },
    UPDATE_PASSWORD:{
        END_POINT:'updatePassword',
        METHOD:1
    },
    CHANGEPASSWORD:{
        END_POINT:'changePassword',
        METHOD:1
    },
    ADMINPROFILE:{
        EDIT_PROFILE:{
            END_POINT:'editProfileAdmin',
            METHOD:1
        }
    },
    USERLIST:{
        END_POINT:'userList',
        METHOD:2
    },
    QUESTIONLIST:{
        END_POINT:'questionList',
        METHOD:2
    },

    USER:{
        USER_DETAIL:{
            END_POINT:"userDetails",
            METHOD:2
        },
        USER_DELETE:{
            END_POINT:"userDelete",
            METHOD:4
        },
        USER_UPDATE:{
            END_POINT:"editStudent",
            METHOD:3
        },
        USER_COMPLETE_SEMINAR:{
            END_POINT:"completeLessonStudent",
            METHOD:2
        }
    },

    CATEGORY:{
        CATEGORIES:{
            END_POINT:"categoryList",
            METHOD:2
        },
        ADDCATEGORY:{
            END_POINT:"addCategory",
            METHOD:1
        },
        EDITCATEGORY:{
            END_POINT:"editCategory",
            METHOD:3
        },
        CATEGORYDETAILS:{
            END_POINT:"categoryDetails",
            METHOD:2
        },
        CATEGORYDELETE:{
            END_POINT:"deleteCategory",
            METHOD:4
        }
    },
    QUESTION:{
        QUESTION_LIST:{
            END_POINT:"allQuestionList",
            METHOD:2
        },
        ADDQUESTION:{
            END_POINT:"addQuestion",
            METHOD:1
        },
        EDITQUESTION:{
            END_POINT:"editQuestion",
            METHOD:3
        },
        QUESTIONDETAILS:{
            END_POINT:"questionDetails",
            METHOD:2
        },
        QUESTIONDELETE:{
            END_POINT:"deleteQuestion",
            METHOD:4
        }
    },
    SUBCATEGORY:{
        SUBCATEGORIES:{
            END_POINT:"subCategoryList",
            METHOD:2
        },
        ADDSUBCATEGORY:{
            END_POINT:"addSubCategory",
            METHOD:1
        },
        EDITSUBCATEGORY:{
            END_POINT:"editSubCategory",
            METHOD:3
        },
        SUBCATEGORYDETAILS:{
            END_POINT:"subCategoryDetails",
            METHOD:2
        },
        SUBCATEGORYDELETE:{
            END_POINT:"deleteSubCategory",
            METHOD:4
        }
    },
    MENU:{
        MENU_LIST:{
            END_POINT:"menuList",
            METHOD:2
        },
        NAVBAR_LIST:{
            END_POINT:"navBarList",
            METHOD:2
        }
    },
    PRODUCT:{
        PRODUCT_LIST:{
            END_POINT:"productList",
            METHOD:2
        },
        PRODUCT_DELETE:{
            END_POINT:"productDelete",
            METHOD:4
        },
        VERIFY_PRODUCT:{
            END_POINT:"productVerifyUnverify",
            METHOD:3
        },
        PRODUCT_DETAILS:{
            END_POINT:"productDetails",
            METHOD:2
        }
    },
    ORDER:{
        ORDER_LIST:{
            END_POINT:"orderList",
            METHOD:2
        },
        ORDER_DELETE:{
            END_POINT:"orderDelete",
            METHOD:4
        },
    },
    SUBADMIN:{
        SUBADMIN_LIST:{
            END_POINT:"userList",
            METHOD:2
        },
        SUBADMIN_ADD:{
            END_POINT:"addSubAdmin",
            METHOD:1
        },
        PERMISSION:{
            END_POINT:"setPermission",
            METHOD:3
        }
    },
    DEALS:{
        DEALS_LIST:{
            END_POINT:"dealsList",
            METHOD:2
        },
        DEALS_DELETE:{
            END_POINT:"dealsDelete",
            METHOD:4
        },
        DEALS_ADD:{
            END_POINT:"addDeals",
            METHOD:1
        },
        DEALS_DETAILS:{
            END_POINT:"dealDetails",
            METHOD:2
        },
        DEALS_UPDATE:{
            END_POINT:"editDeals",
            METHOD:3
        }
    },
    DASHBOARD:{
        DASHBOARD_DATA:{
            END_POINT:"dashboardData",
            METHOD:2
        }
    },
    BANNER:{
        GET_BANNER:{
            END_POINT:"bannerList",
            METHOD:2
        },
        BANNER_DELETE:{
            END_POINT:"bannerDelete",
            METHOD:4
        },
        BANNER_ADD:{
            END_POINT:"addBanner",
            METHOD:1
        },
        BANNER_DETAILS:{
            END_POINT:"bannerDetails",
            METHOD:2
        },
        BANNER_UPDATE:{
            END_POINT:"editBanner",
            METHOD:3
        }
    },
    RESULT:{
        SEMINAR_ATTEMPT:{
            END_POINT:"testAttempt",
            METHOD:2
        },
        SEMINAR_RESULT:{
            END_POINT:"seminarTestList",
            METHOD:2
        },
        PAYMENT_HISTORY:{
            END_POINT:"getPaymentHistory",
            METHOD:2
        },
         
    },
    SEND_CERTIFICATE:{
        END_POINT:"sendCertificate",
        METHOD:3
    },
    UPLOAD_IMAGE: {
        END_POINT: 'uploadFile',
        METHOD: 1
    }
    
}