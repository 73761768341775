import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { addQuestion } from "../actions/Question/AddQuestion";

export const AddQuestionApi = form => {
  return (dispatch, getState) => {

    

    dispatch(addQuestion.QuestionAdding());
    return _callApi(
      END_POINT.QUESTION.ADDQUESTION.END_POINT,
      END_POINT.QUESTION.ADDQUESTION.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
     
      if ( Object.keys(result).length === 0) {
        
        dispatch(addQuestion.QuestionFailed({result,message}));
        return res.data;
      } else {
        dispatch(addQuestion.QuestionAdded({result}));
        return res.data;
      }
    });
  };
};
