import { ACTIONS } from "../../action-constants/Actions"


const DetailsFetching=()=>{
    return {
        type: ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FETCHING,
        payload:{
            isFetching:true,
            
        }
    }
}

const DetailsFetchingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FAILED,
        payload:{
            isFetching:false,
            msg
        }
    }
}

const DetailsFetched=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.DETAILS.DETAILS_FETCHED,
        payload:{
            isFetching:false,
            result
        }
    }
}


export const details = {
    DetailsFetching,
    DetailsFetchingFailed,
    DetailsFetched
}