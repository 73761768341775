import { ACTIONS } from "../../action-constants/Actions"


const QuestionDeleting=()=>{
    return {
        type: ACTIONS.QUESTION.DELETEQUESTION.QUESTION_DELETING,
        payload:{
            isQuestionDeleting:true,
            
        }
    }
}

const QuestionDeletingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.QUESTION.DELETEQUESTION.QUESTION_DELETING_FAILED,
        payload:{
            isQuestionDeleting:false,
            result,
            msg
        }
    }
}

const QuestionDeleted=({ result, msg })=>{
    return {
        type: ACTIONS.QUESTION.DELETEQUESTION.QUESTION_DELETED,
        payload:{
            isQuestionDeleting:false,
            result:result,
            message:msg
        }
    }
}


export const questionDelete = {
    QuestionDeleting,
    QuestionDeletingFailed,
    QuestionDeleted
}