import { ACTIONS } from "../../action-constants/Actions"


const PaymentHistoryFetching=()=>{

    return {
        type: ACTIONS.RESULT.HISTORY.HISTORY_FETCHING,
        payload:{
            isPaymentHistoryFetching:true,
        }
    }
}

const PaymentHistoryFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.HISTORY.HISTORY_FAILED,
        payload:{
            isPaymentHistoryFetching:false,
            result,
            msg
        }
    }
}

const PaymentHistoryFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.RESULT.HISTORY.HISTORY_FETCHED,
        payload:{
            isPaymentHistoryFetching:false,
            result:result,
            message:msg
        }
    }
}

export const History = {
    PaymentHistoryFetching,
    PaymentHistoryFailed,
    PaymentHistoryFetched
}