import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { History } from "../../actions/PaymentHistory/paymentHistory";

export const PaymentHistoryApi = form => {
  return (dispatch, getState) => {
    dispatch(History.PaymentHistoryFetching());
    return _callApi(
      END_POINT.RESULT.PAYMENT_HISTORY.END_POINT,
      END_POINT.RESULT.PAYMENT_HISTORY.METHOD,
      form
    ).then(res => {
      
      let { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        result = [];
        dispatch(History.PaymentHistoryFailed({result,message}));
        return res.data;
      }else {
        dispatch(History.PaymentHistoryFetched({result,message}));
        return res.data;
      }
    });
  };
};
