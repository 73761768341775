import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
// let UserLogin = require("../actions/UserLogin")
import { update } from "../actions/CommonAction/Update";

export const UpdatePasswordApi = form => {
  console.log(form);
  return (dispatch, getState) => {
    dispatch(update.Updating());
    return _callApi(
      END_POINT.UPDATE_PASSWORD.END_POINT,
      END_POINT.UPDATE_PASSWORD.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if (Object.keys(result).length === 0) {
        dispatch(update.UpdatingFailed(message));
        return res.data;
      } else {
        dispatch(update.Updated(result));
        return res.data;
      }
    });
  };
};
