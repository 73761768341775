import React, { Component } from "react";
import {AdvertisementsDetail} from '../../commonComponents';
import {Row,Col} from "react-bootstrap";
import {DashboardStoreStats,DashboardSecondarySidebar} from './index';
// import { GetDashboardApi } from "../../../../store/api-actions/dashboard/DashboardApi";
import { connect } from "react-redux";

  
const mapDispatchToProps = dispatch => {
  return {
      //dashboardApi: form => dispatch(GetDashboardApi(form)),
  };
};

const mapStateToProps = state => {
  return {
      dashboardState: state.dashboard,
  };
};

class Dashboard extends Component {


  constructor(props) {
      super(props);
      this.state = {
        formFields: {},
        errors: {},
        message: null,
        messageElement: false,
        success: false,
        file:"",
        s3file:"",
        isSuccessConfirMsg:false
      };
      
  }

  componentDidMount(){
      // let { match } = this.props
      // let reqObj = {};
      // this.props.dashboardApi(reqObj).then(res=>{
      //   const { message = null, result = null } = res;
        
      // })
  }

  render() {
    let { list } = this.props.dashboardState
    return (
      <div className="album pt-3 dashboard">
        <div className="container-fluid">
          <section>
           <div className="ads-analytics">
              <Row>
                <AdvertisementsDetail item={list}/>
              </Row>
           </div>
          </section>
          <section>
             <Row>
               <Col md="4" lg="4">
                 <DashboardSecondarySidebar item={list} />
               </Col>
               <Col md="8" lg="8">
                 <DashboardStoreStats item={list} />
               </Col>
             </Row>
          </section>
        </div>
      </div> 
    );
  }
}

export default Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

