import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,InputBox,LoadingComponent,Image } from "../../commonComponents";
import { connect } from "react-redux";
import { ProductDetailsApi } from "../../../../store/api-actions/product/productDetailsApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { END_POINT } from "../../../../constants/apiEndPoints";


 
const mapDispatchToProps = dispatch => {
    return {
        ProductDetails: form => dispatch(ProductDetailsApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        addCategoryState: state.addCategory
    };
  };

class EditProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false,
          isLoader:false,
          data:[]
        };
    }
    

    productDtails=(req)=>{
        this.setState({isLoader:true});
        this.props.ProductDetails(req).then(res=>{
            const { message = null, result = null } = res;
            if (Object.keys(result).length  > 0) {
              console.log("=====>>>>>>>",result)
                this.setState({isLoader:false,data:result});
            }
        })
    }

    componentDidMount(){
      let request = {}
      console.log("=====>>>",this.props.match.params.id)
      request.productId = this.props.match.params.id
      this.productDtails(request)
    } 


     

    render() {
        let { addCategoryState } = this.props;
        let loader = "";
        let conm = "";
        if(this.state.isLoader){
          loader = <LoadingComponent/>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.CAT_SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }

        let list = this.state.data

        return (
          <div className="album pt-3">
             {loader}
             {conm}           
          <div className="container-fluid">
          <Heading headingClass="" headingText="Product Details"/>
          <hr/>
          <div className="container">
            <div className="row">
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Title"
                    inputClass="form-control"
                    type="text"
                    placeholder="Title"
                    value={list.title}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="category"
                    inputClass="form-control"
                    type="text"
                    value={list.category}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Sub Category"
                    inputClass="form-control"
                    type="text"
                    value={list.subCategory}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Length"
                    inputClass="form-control"
                    type="text"
                    value={list.length}
                    inpuReadOnly={true}
                    />
                 </div> 
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Size"
                    inputClass="form-control"
                    type="text"
                    value={list.size}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Price"
                    inputClass="form-control"
                    type="text"
                    value={list.price}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Security Money"
                    inputClass="form-control"
                    type="text"
                    value={list.securityMoney}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Penalty Amount"
                    inputClass="form-control"
                    type="text"
                    value={list.penaltyAmount}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="view Count"
                    inputClass="form-control"
                    type="text"
                    value={list.viewCount}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-6">
                   <InputBox
                    formWrapClass = "form-group"
                    labelText="Favourite Count"
                    inputClass="form-control"
                    type="text"
                    value={list.wishlistCount}
                    inpuReadOnly={true}
                    />
                 </div>
                 <div className="col-md-12">
                    
                    {
                      // (list.image).map((item, index)=>(
                      //   <Image imageSrc={ END_POINT.S3BASE_URL+item} imageClass="ima-size-30" />
                      // ))
                      // list.image
                    }
                    
                 </div>
            </div>
          </div>
          </div>
        </div>

            
        )
    }
}

export default EditProduct = connect(mapStateToProps, mapDispatchToProps)(EditProduct);