import { ACTIONS } from "../action-constants/Actions";

export const subAdminReducer = ( state={}, action={} )=>{

    switch(action.type){
        case ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FETCHING:{
            let newState = { ...{}, ...state };
            newState.isSuccess = false
            newState.list =  [];
            newState.totalCount = 0
            return newState
        }
        case ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FETCHED:{
            let newState = { ...{}, ...state };
            let { result, message } = action.payload;
            newState.isSuccess = true
            newState.msg = message
            newState.list =  result.data?result.data:[];
            newState.totalCount = result.count?result.count:0
            newState.filter = result?result.filter:{}
            return newState;
        }
        case ACTIONS.SUBADMIN_LIST.SUBADMIN_LIST_FAILED:{
            let newState = { ...{}, ...state };
            let { error } = action.payload;
            newState.isSuccess = false
            newState.msg = error;
            newState.list =  [];
            newState.totalCount = 0
            return newState
        }
        default:{
            return state;
        }
    }
}