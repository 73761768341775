import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { category } from "../actions/Category/Category";

export const GetCategories = form => {
  return (dispatch, getState) => {
 

    dispatch(category.CategoryFetching());
    return _callApi(
      END_POINT.CATEGORY.CATEGORIES.END_POINT,
      END_POINT.CATEGORY.CATEGORIES.METHOD,
      form
    ).then(res => {
      res.data.result.filter={}
      if(form){
        res.data.result.filter = form;
      }

      const { message = null, result = null } = res.data;
     
      if ( Object.keys(result).length === 0) {
        dispatch(category.CategoryFailed({result,message}));
        return res.data;
      } else {
 
        //result.filter = form;
        dispatch(category.CategoryFetched({result,message}));
        //res.data.result.filter = form;
        return res.data;
      }
    });
  };
};
