import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { details } from "../../actions/CommonAction/GetDetails";

export const GetBannerDetailsApi = form => {
  return (dispatch, getState) => {
    dispatch(details.DetailsFetching());
    return _callApi(
      END_POINT.BANNER.BANNER_DETAILS.END_POINT,
      END_POINT.BANNER.BANNER_DETAILS.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      
      if ( Object.keys(result).length === 0) {
        
        dispatch(details.DetailsFetchingFailed({result,message}));
        // console.log("666666666",res.data)
        return res.data;
      } else {
        
        dispatch(details.DetailsFetched({result}));
        return res.data;
      }
    });
  };
};
