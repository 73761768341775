import React, { Component } from "react";
import { _callApi } from "../../../../services/baseService";
import { Heading,InputField,Button,InputBox,LoadingComponent } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { ChangePasswordApi } from "../../../../store/api-actions/ChangePasswordApi";
import {MESSAGE} from '../../../../constants/confirmMessage';
import {swal} from '../../../../constants/confirm-alert';


const mapDispatchToProps = dispatch => {
  return {
    changePassword: form => dispatch(ChangePasswordApi(form))
  };
};

const mapStateToProps = state => {
  return {
    changePasswordState: state.changePassword
  };
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      isWarningConfirMsg:false,
      isSuccessConfirMsg:false,
      isFailedConfirMsg:false,
      _id:"",
      message: null,
      title:"",
      formFields: {},
      errors: {},
      messageElement: false,
      success: false,
      s3file:"",
      isLoader:false,
      image:"",
    };
  }

  changePasswordForm = (values, formikBag)=> {
    this.setState({isLoading:true});
    this.props.changePassword(values).then(res=>{
      if(res.status){
        let { history } = this.props
        localStorage.removeItem("user")
        this.setState({isLoading:false,isSuccessConfirMsg:true,message:MESSAGE.PASSWORD_CHANGED,title:MESSAGE.SUCCESS_TITLE});
        }else{
          this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
      }
    })
  };
  
  render() {
    let { changePasswordState } = this.props;
            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/portal/user`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
    return (
      <div className="album pt-3">
        {loader}
        {conm}
        <div className="container-fluid">
        <Heading headingClass="" headingText="Change Password"/>
        <hr/> 
          <div className="container">
            <div className="row">
                <div className="col-md-6 col-md-offset-3">
                    {/* {JSON.stringify(ChangePassword)} */}
                    {/* <span style={{ color: changePasswordState.isSuccess ? 'green' : 'red' }} > { changePasswordState.msg }</span> */}
                  <Formik
                  initialValues={{
                    currentPassword:'',
                    newPassword:'',
                    confirmPassword:'',
                  }}
                  onSubmit={this.changePasswordForm}
                  validationSchema={VALIDATORS.CHANGEPASSWORD}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Current Password"
                            inputClass="form-control"
                            type="password"
                            placeholder="Current Password"
                            name = "currentPassword"
                            handleChange={handleChange}
                            value={values.currentPassword}
                            errorClass = "text-danger"
                            errorText = { touched.currentPassword && errors.currentPassword }
                          />
                        
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="New Password"
                            inputClass="form-control"
                            type="password"
                            placeholder="New Password"
                            name = "newPassword"
                            handleChange={handleChange}
                            value={values.newPassword}
                            errorClass = "text-danger"
                            errorText = { touched.newPassword && errors.newPassword }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Confirm Password"
                            inputClass="form-control"
                            type="password"
                            placeholder="Confirm Password"
                            name = "confirmPassword"
                            handleChange={handleChange}
                            value={values.confirmPassword}
                            errorClass = "text-danger"
                            errorText = { touched.confirmPassword && errors.confirmPassword }
                          />
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="submit"
                        btnText="Submit"
                      />
                      </div>
                  </form>
                )}
                  />
                    
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);