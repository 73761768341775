import { _callApi } from "../../services/baseService";
import { END_POINT } from "../../constants/apiEndPoints";
import { addCategory } from "../actions/Category/AddCategory";

export const AddCategoryApi = form => {
  return (dispatch, getState) => {

    

    dispatch(addCategory.CategoryAdding());
    return _callApi(
      END_POINT.CATEGORY.ADDCATEGORY.END_POINT,
      END_POINT.CATEGORY.ADDCATEGORY.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
     
      if ( Object.keys(result).length === 0) {
        
        dispatch(addCategory.CategoryFailed({result,message}));
        return res.data;
      } else {
        dispatch(addCategory.CategoryAdded({result}));
        return res.data;
      }
    });
  };
};
