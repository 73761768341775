import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Delete } from "../../actions/CommonAction/Delete";

export const DeleteBannerApi = form => {
  return (dispatch, getState) => {
    dispatch(Delete.Deleting());
    return _callApi(
      END_POINT.BANNER.BANNER_DELETE.END_POINT,
      END_POINT.CATEGORY.CATEGORYDELETE.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Delete.DeletingFailed({result,message}));
        return res.data;
      } else {
          dispatch(Delete.Deleted({result,message}));
        return res.data;
      }
    });
  };
};
