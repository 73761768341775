import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { AddBannerApi } from "../../../../store/api-actions/banner/AddBannerApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';


 
const mapDispatchToProps = dispatch => {
    return {
      addBanner: form => dispatch(AddBannerApi(form))
    };
  };
  
  const mapStateToProps = state => {
    return {
        addBannerState: state.add
    };
  };

class AddBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false,
          isLoader:false
        };
    }
    
    addBannerForm = (values, formikBag)=> {
      this.setState({isLoader:true});
        uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
        .then(data => {
            values.image = data.key;
            values.title = values.name;
            this.props.addBanner(values).then(res=>{

              const { message = null, result = null } = res;
              if ( Object.keys(result).length  > 0) {
                this.setState({isLoader:false});
                this.setState({isSuccessConfirMsg:true});
               }
            })
        })
        .catch(err => console.error(err))
    };


    redirectTo=(data)=>{

      let { history } = this.props
  
      history.push(`/portal/banner`)
    }

    render() {
        let { addBannerState } = this.props;
        let loader = "";
        let conm = "";
        
        if(this.state.isLoader){
          loader = <div className="loader-custom">
                      <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000000} //3 secs
                      />
                    </div>
        }

        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.CAT_SUCCESS_SUB_TITLE
          }
          conm = swal.success(obj);
        }

        return (
          <div className="album pt-3">
             {loader}
             {conm}           
          <div className="container-fluid">
          <Heading headingClass="bg-light text-center" headingText="Change Password"/>
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                  <Formik
                  initialValues={{
                    name:'',
                  }}
                  onSubmit={this.addBannerForm}
                  validationSchema={VALIDATORS.ADDBANNER}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Banner Name"
                            inputClass="form-control"
                            type="text"
                            placeholder="Banner Name"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <div class="upload-img">
                            <InputBox
                              formWrapClass = "form-group"
                              labelText="Bnner Image"
                              inputClass="form-control"
                              type="file"
                              placeholder="Banner Image"
                              name = "image"
                              handleChange= {(e)=>{
                                let imgData = COMMON_FUNCTION.imagePreview(e);
                                this.setState({
                                  file: imgData.file,
                                  s3file : imgData.s3file
                                });
                              }}
                            />
                          </div>
                          
                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="submit"
                        btnText="Submit"
                      />
                      </div>
                  </form>
                )}
                  />
                    
                </div>
                <div className="col-md-6 text-center">
                  <div className="img-placeholder">
                    <img className="wh-174-194" src={this.state.file}/>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>

            
        )
    }
}

export default AddBanner = connect(mapStateToProps, mapDispatchToProps)(AddBanner);