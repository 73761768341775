import { ACTIONS } from "../../action-constants/Actions"


const CategoryDeleting=()=>{
    return {
        type: ACTIONS.CATEGORY.DELETECATEGORY.CATEGORY_DELETING,
        payload:{
            isCategoryDeleting:true,
            
        }
    }
}

const CategoryDeletingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.DELETECATEGORY.CATEGORY_DELETING_FAILED,
        payload:{
            isCategoryDeleting:false,
            result,
            msg
        }
    }
}

const CategoryDeleted=({ result, msg })=>{
    return {
        type: ACTIONS.CATEGORY.DELETECATEGORY.CATEGORY_DELETED,
        payload:{
            isCategoryDeleting:false,
            result:result,
            message:msg
        }
    }
}


export const categoryDelete = {
    CategoryDeleting,
    CategoryDeletingFailed,
    CategoryDeleted
}