import React, { Component } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { EditCategoryApi } from "../../../../store/api-actions/category/EditCategoryApi";
import { GetCategoryApi } from "../../../../store/api-actions/category/GetCategoryApi";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';


const mapDispatchToProps = dispatch => {
    return {
      editCategory: form => dispatch(EditCategoryApi(form)),
      categoryDetails: id => dispatch(GetCategoryApi(id)),
    };
  };
  
  const mapStateToProps = state => {
    return {
        editCategoryState: state.editCategory,
        categoryDetailState: state.categories
    };
  };

class EditCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false
        };
    }

    componentDidMount() {
       this.props.categoryDetails({"catId":this.props.match.params.id}).then(res => {
            const { message = null, result = null } = res;
        })
      }
    
    editCategoryForm = (values, formikBag)=> {
      uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
        .then(data => {
            values.image = data.key;
            values.catId = this.props.match.params.id;
            this.props.editCategory(values).then(res=>{
              const { message = null, result = null } = res;
              if (Object.keys(result).length  > 0) {
                this.setState({isSuccessConfirMsg:true});
              }
            })
        })
        .catch(err => console.error(err))
    };

    imagePreview=(event)=>{
      if(event.target.files.length > 0){
        let file = event.target.files[0];
        if(VALIDATORS.isImage(file.name)){
          let preview = URL.createObjectURL(event.target.files[0])
          this.setState({
            file: Object.assign({preview}, file),
            s3file : file
          })   
        }
      }
    }

    redirectTo=(data)=>{
      let { history } = this.props
      history.push(`/portal/category`)
    }

    render() {
        let { categoryDetails } = this.props.categoryDetailState;
        let loader = "";
        let conm = "";
        // if(this.props.editCategoryState.isCategoryEditing){
        //   loader = <div className="loader-custom">
        //               <Loader
        //                 type="Bars"
        //                 color="#00BFFF"
        //                 height={100}
        //                 width={100}
        //                 timeout={3000} //3 secs
        //               />
        //             </div>
        // }
        if(this.state.isSuccessConfirMsg){
          var obj = {
            title:MESSAGE.SUCCESS_TITLE,
            onConfirm:()=>{
              this.setState({isSuccessConfirMsg:false});
              this.redirectTo();
    
            },
            subtitle:MESSAGE.CAT_EDIT_TITLE
          }
          conm = swal.success(obj);
        }

        return (
          <div className="album pt-3">
             {loader}
             {conm}  
          <div className="container-fluid">
          <Heading headingClass="bg-light" headingText="Edit Category"/>
          <hr/>
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name:categoryDetails.name,
                  }}
                  onSubmit={this.editCategoryForm}
                  validationSchema={VALIDATORS.ADDCATEGORY}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Category Name"
                            inputClass="form-control"
                            type="text"
                            placeholder="Category Name"
                            name = "name"
                            handleChange={handleChange}
                            value={values.name}
                            errorClass = "text-danger"

                            errorText = { touched.name && errors.name }
                          />
                          <div className="upload-img">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Category Image"
                            inputClass="form-control"
                            type="file"
                            placeholder="Category Image"
                            name = "image"
                            handleChange= {(e)=>{
                              let imgData = COMMON_FUNCTION.imagePreview(e);
                              this.setState({
                                file: imgData.file,
                                s3file : imgData.s3file
                              });
                            }}
                          />
                          </div>
                          <div className="form-group auth-form-field">
                            <Button
                              btnClass="btn btn-primary"
                              btnType="submit"
                              btnText="Submit"
                            />
                          </div>
                     </form>
                )}
                  />
                </div>
                <div className="col-md-6 text-center">
                  <div className="img-placeholder">
                    <img className="wh-174-194" src={this.state.file}/>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>
        )
    }
}

export default EditCategory = connect(mapStateToProps, mapDispatchToProps)(EditCategory);