import React, { Component } from 'react'
import { _callApi } from '../../../../services/baseService';
import { END_POINT } from '../../../../constants/apiEndPoints';
import { connect } from "react-redux";
import { Heading,InputBox,SelectBox,MaterialUIPickers,LoadingComponent } from "../../commonComponents";
import { CompleteSeminarApi } from "../../../../store/api-actions/user/CompleteSeminarApi";
import SearchBox from '../../commonComponents/search-box/SearchBox';
import ReactPaginate from 'react-paginate';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { Link } from 'react-router-dom';
import {COMMON_FUNCTION} from '../../../../constants/commonFunction';
import { Formik } from "formik";
import { CONSTANTS } from "../../../../constants/constant";
import { CSVLink } from "react-csv";
import moment from "moment"

const mapDispatchToProps = dispatch => {
  return {
    userList: (reqData)=>dispatch(CompleteSeminarApi(reqData))
  };
};

const mapStateToProps = state => {
  return {
    completeSeminarState: state.completeSeminar
    
  };
};

class CompleteSeminarStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:"",
          isLoading:false
        };
      }
      
      handleSearch = (values, formikBag) => {
        this.setState({isLoading:true})
        values.fromDate = moment(values.fromDate, "YYYY/M/D").valueOf();
        values.toDate = moment(values.toDate, "YYYY/M/D").valueOf();
        this.props.userList(values).then(res=>{
          const { message = null, result = null } = res;
          this.setState({isLoading:false})
        })
      };
      
       

      cancelDelete = () => {
        this.setState({isWarningConfirMsg:false,_id:""});
      };
      confirmMsg = data => {
        this.setState({isWarningConfirMsg:true,_id:data._id});
      };

      render() {
         let conm = "";
          let {list,totalCount} = this.props.completeSeminarState;
          /**=========== alert confirmation========= */
          if(this.state.isWarningConfirMsg){
            var obj = {
              isWarningConfirMsg:this.state.isWarningConfirMsg,
              confirmBtnText:MESSAGE.CONFIRM_BTN_TEXT,
              title:MESSAGE.WARNING_TITLE,
              onConfirm:()=>{
                this.handleDelete(this.state)
              },
              onCancel:()=>{
                this.cancelDelete(this.state)
              },
              subtitle:MESSAGE.WARNING_USER_SUB_TITLE
            }
            conm = swal.warning(obj);
          }
      
          if(this.state.isSuccessConfirMsg){
            var obj = {
              title:MESSAGE.SUCCESS_TITLE,
              onConfirm:()=>{
                this.setState({isSuccessConfirMsg:false});
                this.handlePageClick();
      
              },
              subtitle:MESSAGE.DELETE_USER_SUB_TITLE
            }
            conm = swal.success(obj);
          }
          let load = "";
          if(this.state.isLoading){
            load = <LoadingComponent/>
          }
          /**=========== alert confirmation========= */
        return (
          <div className="album pt-3">
            <div className="container-fluid">
              {load}
            <Heading headingClass="" headingText="EXCEL REPORT"/>
            <hr />
            <Formik
                  enableReinitialize={true}
                  initialValues={{
                    fromDate:new Date(),
                    toDate:new Date(),
                    seminarId:""
                    }}
                  onSubmit={this.handleSearch}
                  render={({handleSubmit, errors, touched, values, handleChange,setFieldValue})=>(
                    <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-3 dob">
                        <MaterialUIPickers
                            margin="normal"
                            id="fromDate"
                            name = "fromDate"
                            maxDate = {new Date()}
                            value={values.fromDate}
                            setFieldValue={(date) => {
                                setFieldValue('fromDate', date)
                                //this.dob(date)
                            }}
                            errorClass = "text-danger"
                            errorText = { touched.fromDate && errors.fromDate }
                        />
                    </div>

                    <div className="col-md-3 dob">
                        <MaterialUIPickers
                            margin="normal"
                            id="toDate"
                            name = "toDate"
                            maxDate = {new Date()}
                            value={values.toDate}
                            setFieldValue={(date) => {
                                setFieldValue('toDate', date)
                                //this.toDate(date)
                            }}
                            errorClass = "text-danger"
                            errorText = { touched.toDate && errors.toDate }
                        />
                    </div>
                    <SelectBox
                        formWrapClass = "form-group col-md-3"
                        selectClass = "form-control select-seminar"
                        placeholder = "Seminar"
                        name = "seminarId"
                        handleChange={handleChange}
                        options = {CONSTANTS.SEMINAR}
                        errorClass = "text-danger"
                        errorText = { touched.seminarId && errors.seminarId }
                    />   
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-info pull-right pull-left btn-search"> <i className="fa fa-search"></i></button>
                        <CSVLink className="pull-right btn btn-info" data={list} headers={CONSTANTS.headers}>
                          Download
                        </CSVLink>
                    </div>
                    </div>
                  </form>
                )}
                  />
            
            {conm}
            <hr />
              <div className="table-responsive">
                <table className="table table-hover text-center  table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Joining Date</th>
                      <th>Completion Date</th>
                       
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.email}</td>
                        <td>{item.joiningDate}</td>
                        <td>{item.created}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                 
              </div>
            </div>
          </div>
        );
      }
}
export default CompleteSeminarStudent = connect(mapStateToProps, mapDispatchToProps)(CompleteSeminarStudent);