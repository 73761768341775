import { ACTIONS } from "../action-constants/Actions";


export const loginReducer= (state={},action={})=>{

    switch(action.type){

        case ACTIONS.USER_LOGIN.USER_LOGGING:{
            let newState = {...{},...state}
            let { remember, isLogging, ...rest } = action.payload
            newState.remember = remember;
            newState.isLogging = isLogging
            newState.msg = ''
            newState.isSuccess = false
            return {...newState};
        }

        case ACTIONS.USER_LOGIN.USER_LOGGEDIN:{
            let { isLogging, result } = action.payload;
            // console.log(action.payload);
            let newState = {...{}, ...state}
            newState.isLogging = isLogging;
            newState.msg = "Successfully logged in";
            newState.isSuccess = true
            newState.details =   { ...{}, ...newState.details, ...result} 
            return {...newState};

        }
        case ACTIONS.USER_LOGIN.USER_FAIL_LOGIN:{
            let { isLogging, error } = action.payload
            let newState = {...{}, ...state}
            newState.isLogging =  isLogging;
            newState.isSuccess = false
            newState.msg = error;
            return {...newState};
        }

        default :{
            return state;
        }
    }
} 