import { ACTIONS } from "../../action-constants/Actions"


const ProductFetching=()=>{

    return {
        type: ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FETCHING,
        payload:{
            isProductFetching:true,
        }
    }
}

const ProductFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FAILED,
        payload:{
            isProductFetching:false,
            result,
            msg
        }
    }
}

const ProductFetched=({ result, msg })=>{
    
    return {
        type: ACTIONS.PRODUCT.PRODUCT_LIST.PRODUCT_FETCHED,
        payload:{
            isProductFetching:false,
            result:result,
            message:msg
        }
    }
}

export const product = {
    ProductFailed,
    ProductFetching,
    ProductFetched
}