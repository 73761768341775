
import React, { Component } from 'react';
import { Heading,Button,SelectBox,InputBox,} from '../../commonComponents';
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { Link } from 'react-router-dom';

class PushNotification extends Component {
  
  render() {
    const options = [
        { key:'0', value: '0', text: 'FCM' },
        { key:'1', value: '1', text: 'One Signal' },
      ];
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Heading headingText="Push Notification Settings"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="row">
          <div className="col-md-6">
            <Formik
                initialValues={{
                name:'',
                }}
                onSubmit={this.addBannerForm}
                validationSchema={VALIDATORS.ADDBANNER}
                render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                          <SelectBox
                            formWrapClass = "form-group"
                            selectClass = "form-control"
                            labelText="Default Notification"
                            name = "defaultnotification"
                            handleChange={handleChange}
                            options = {options}
                            errorClass = "text-danger"
                            errorText = { touched.discountType && errors.discountType }
                          />
                          <hr/>
                         <h5>Push Notification Setting (One Signal) </h5> 
                         <hr/>
                         <InputBox
                            formWrapClass = "form-group"
                            labelText="Onesignal App ID"
                            inputClass="form-control"
                            type="text"
                            name = "onesignalappid"
                            placeholder="Please enter your onesignal App ID."
                            handleChange={handleChange}
                            value={values.onesignalappid}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Onsignal Sender ID"
                            inputClass="form-control"
                            type="text"
                            name = "onsignalsenderid"
                            placeholder="Please enter your onesignal Sender ID."
                            handleChange={handleChange}
                            value={values.onsignalsenderid}
                            errorClass = "text-danger"
                            errorText = { touched.name && errors.name }
                          />

                      <div className="form-group auth-form-field">
                      <Button
                        btnClass="btn btn-primary"
                        btnType="Update"
                        btnText="Update"
                      />
                      <Link to="#" className="btn btn-default" type="button">
                          Back
                      </Link>
                      </div>
                    </form>
                )}
                />
          </div>
            <div className="col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PushNotification 