import React, { Component } from 'react'
import { connect } from "react-redux";
import {Image} from '../../commonComponents';
import { AttemptApi } from "../../../../store/api-actions/testAttempt/TestAttemptApi";
import {Dropdown} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { END_POINT } from "../../../../constants/apiEndPoints";
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { withRouter } from "react-router";
import {COMMON_FUNCTION} from '../../../../constants/commonFunction';




const mapDispatchToProps = dispatch => {
  return {
    Attempt: (reqData)=>dispatch(AttemptApi(reqData))
};
};

const mapStateToProps = state => {
  return {
    attemptState: state.attempt
  };
};

class TestAttempt extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isWarningConfirMsg:false,
          isSuccessConfirMsg:false,
          _id:"",
          alertMessage:""
        };
      }
      componentDidMount() {
        let reqData = {};
        console.log("%%%%%%%%%%%%%%%",this.props)
        reqData.userId = this.props.match.params.id
        this.props.Attempt(reqData).then(res=>{})
      }

      handlePageClick = data => {
        let reqData = {};
        if(data && !isNaN(data.selected)){
          reqData.pageNo = parseInt(data.selected)+1;
        }
        reqData.userId = this.props.match.params.id
        this.props.Attempt(reqData).then(res=>{})
      };
      
      

      render() {
        var conm = "";
        let { list,totalCount} = this.props.attemptState;
        var loader = "";
        
        return (
          <div className="album pt-3">
           <div className="container-fluid">
              <div className="table-responsive postion-relative">
                { loader } 
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Seminar</th>
                      <th>Result</th>
                      <th>Test Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.seminarId}</td>
                        <td>{item.isPass?"Passed":"Failed"}</td>
                        <td>{COMMON_FUNCTION.parseDate(item.created)}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount/END_POINT.LIMIT}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'text-center pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        );
      }
}
export default TestAttempt = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestAttempt));