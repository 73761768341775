import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Attempt } from "../../actions/TestAttempt/testAttempt";

export const AttemptApi = form => {
  return (dispatch, getState) => {
    dispatch(Attempt.AttemptFetching());
    return _callApi(
      END_POINT.RESULT.SEMINAR_ATTEMPT.END_POINT,
      END_POINT.RESULT.SEMINAR_ATTEMPT.METHOD,
      form
    ).then(res => {
      if(form){
        res.data.result.filter = form;
      }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(Attempt.AttemptFailed({result,message}));
        return res.data;
      }else {
        dispatch(Attempt.AttemptFetched({result,message}));
        return res.data;
      }
    });
  };
};
