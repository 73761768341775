import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { questionList } from "../../actions/Question/Question";

export const QuestionListApi = form => {
  return (dispatch, getState) => {
    dispatch(questionList.QuestionListFetching());
    return _callApi(
      END_POINT.QUESTION.QUESTION_LIST.END_POINT,
      END_POINT.QUESTION.QUESTION_LIST.METHOD,
      form
    ).then(res => {
      // console.log(res.data, "question listing");
      res.data.result.filter={}
      if(form){
        res.data.result.filter = form;
      }
      const { message = null, result = null } = res.data;
      if ( Object.keys(result).length === 0) {
        dispatch(questionList.QuestionListFailed(message));
        return res.data;
      } else {
        dispatch(questionList.QuestionListFetched(result));
        return res.data;
      }
    });
  };
};
