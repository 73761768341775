import { ACTIONS } from "../../action-constants/Actions"


const CategoryAdding=()=>{

    return {
        type: ACTIONS.CATEGORY.ADDCATEGORY.CATEGORY_ADDING,
        payload:{
            isCategoryAdding:true,
            
        },
        isSuccess: false,
    }
}

const CategoryFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.CATEGORY.CATEGORY_FAILED,
        payload:{
            isCategoryAdding:false,
            result,
            msg
        },
        isSuccess: false,
    }
}

const CategoryAdded=({ result })=>{
    
    

    return {
        type: ACTIONS.CATEGORY.ADDCATEGORY.CATEGORY_ADDED,
        payload:{
            isCategoryAdding:false,
            result:result,
            // message:msg
        },
        isSuccess: true,
    }
}

export const addCategory = {
    CategoryFailed,
    CategoryAdding,
    CategoryAdded
}