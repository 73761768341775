import React, { Component } from "react";
import { Image } from "../../../components/commonComponents";
import {Navbar,Nav,NavDropdown} from "react-bootstrap";
import {withRouter} from 'react-router';
import  {Link} from "react-router-dom";
import { connect } from "react-redux";
import { GetDashboardApi } from "../../../../store/api-actions/dashboard/DashboardApi";
import userImg from "../../../../assets/images/user.jpg";



const mapDispatchToProps = dispatch => {
  return {
    dashboardApi: form => dispatch(GetDashboardApi(form)),
  };
};

const mapStateToProps = state => {
return {
    dashboardState: state.dashboard,
};
};

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      isSlide:false,
      isClassAdd:false
      
    };
  }

  sidebarHandler = ()=>{
      
      if(!this.state.isClassAdd){
        document.body.classList.add('open-sidebar')
        this.setState({isClassAdd:true})
        this.props.callbackHandler(true)
         
      }else{
        document.body.classList.remove('open-sidebar')
        this.setState({isClassAdd:false})
        this.props.callbackHandler(false)
         
      }

      
      
  }


  logout = () => {
    localStorage.removeItem('user');
    this.props.history.push('/login');
  };

  componentDidMount(){
    this.props.callbackHandler(true)
    let { match } = this.props
      let reqObj = {};
      this.props.dashboardApi(reqObj).then(res=>{
        const { message = null, result = null } = res;
        
    })
  }

  render() {
    var name = "Admin";
    let user = JSON.parse(localStorage.getItem("user"));

    if(user && user.name){
      name = user.name;
    }
    let {list} = this.props.dashboardState
    
    return (
      <div className="header-wrapper">
        <div className="header-inner">
        <div className="close-open">
          <button onClick={() => {this.sidebarHandler()}}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
        </div>

          <div className="all-control">
           <Navbar id="ftco-navbar">
            <Nav className="ml-auto">
             
            <NavDropdown title={<><i class="fa fa-user" aria-hidden="true"></i> <span>admin</span></>}>

            <Link className="dropdown-item disabledCursor" onClick={ (event) => event.preventDefault()} to="admin">            
            <Image imageSrc={userImg} imageClass="img-size-40 margin-left-bottom" />
            {name}
            </Link>
            {/* <Link className="dropdown-item" to="/portal/edit-profile">
              <i className="fa fa-edit"></i> Edit My Profile
            </Link> */}
            <Link className="dropdown-item" to="/portal/change-password">
              <i className="fa fa-key"></i> Change Password
            </Link>
            <Link className="dropdown-item" to="#" onClick={()=>{
              this.logout()
            }}>
              <i className="fa fa-sign-out"></i> Log Out
            </Link>
            </NavDropdown>
            </Nav>
            </Navbar>
          </div>
        </div>
        
      </div>
      
    );
  }
}

export default Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
