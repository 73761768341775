import React from 'react';

const Heading = (props)=>{
    return(
        <div className={props.headingClass}>
            <h2>{props.headingText}</h2>
        </div>
    )
}

export default Heading; 