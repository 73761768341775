
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import {Dropdown} from "react-bootstrap";
import defaultuser from '../../../../assets/images/defaultuser.png';



class Customers extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Listing All Customers"/>
            </div>
            <div className="col-md-8">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add New Customers"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Picture</th>
                  <th>Personal Info</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                      1
                    </td>
                    <td>
                    <img src={defaultuser} width="100" className="defaultuser" alt="Customer"/>
                    </td>
                    <td align="left">
                        <strong>Name:</strong> Majid khan <br/>
                        <strong>DOB: </strong><br/>
                        <strong>Email: </strong> majidk061@gmail.com <br/>
                        <strong>Telephone: </strong><br/>
                        <strong>Fax: </strong><br/>
                        <strong>Devices: </strong> 
                    </td>
                    <td align="left">
                        <strong>Company: </strong><br/>
                        <strong>Address: </strong> 
                    </td>
                    <td> 
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                           Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Edit Customers</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Edit Address</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Customers 