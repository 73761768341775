import React, { Component } from 'react'
import { Header, Sidebar, ContentWrapper } from '../defaultLayout';
import { Switch, Route, Redirect } from "react-router-dom";
import { Dashboard, Post } from '../../components/mainComponents';

export default class Portal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          isSlide:false,
        };
    }

    callbackHandler=(data)=>{
       
        this.setState({isSlide:data})
    }

    render() {
        return (
            <div className="main-container">
                <main className="d-flex">
                    <header>
                        <Header callbackHandler = {this.callbackHandler}/>
                    </header>
                    <aside>
                        <Sidebar isSlide={this.state.isSlide}/>
                    </aside>
                    <div className="content-wrapper ml-auto">
                        <ContentWrapper/>
                    </div>
                </main>
            </div>
        )
    }
}
