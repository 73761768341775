import { ACTIONS } from "../action-constants/Actions";

export const categoriesReducer = ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.CATEGORY.CATEGORY_FETCHING:{

            let newState = { ...{}, ...state };

            newState.isSuccess = false

            newState.list =  [];
            newState.totalCount = 0
            newState.filter ={}

            return newState
        }
        case ACTIONS.CATEGORY.CATEGORY_FETCHED:{
            
            let newState = { ...{}, ...state };
          
            let { result, message } = action.payload;

            newState.isSuccess = true
            newState.msg = message
            newState.list =  result.data;
            newState.totalCount = result.count  
            newState.filter = result.filter
            // console.log("-00000000000-----------",result)

            return newState;
        }
        case ACTIONS.CATEGORY.CATEGORY_FAILED:{


            let newState = { ...{}, ...state };

            let { error } = action.payload;
            newState.isSuccess = false

            newState.msg = error;

            newState.list =  [];
            newState.totalCount = 0
            newState.filter = {}

            return newState
        }

        case ACTIONS.CATEGORY.GETCATEGORYDETAILS.GETCATEGORYDETAILS_FETCHED:{
            
            let newState = { ...{}, ...state };
          console.log("{{{{{{}}}}}}}}}}}}",action.payload);
            let { result, message } = action.payload;
            
            newState.isSuccess = true
            newState.msg = message
            newState.categoryDetails =  result;
              
            

            return newState;
        }

        case ACTIONS.CATEGORY.ADDCATEGORY.CATEGORY_ADDED : {

            let { result } = action.payload
            let newState = { ...{}, ...state };

            // newState.list.unshift(result);

            return newState;
        }

        case ACTIONS.CATEGORY.EDITCATEGORY.CATEGORY_EDITED : {

            let { result } = action.payload
            let newState = { ...{}, ...state };

            // newState.list.unshift(result);

            return newState;
        }
        
        default:{
            return state;
        }
    }
}