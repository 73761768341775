import React, { Component } from 'react'
import { connect } from "react-redux";

import {Image,LoadingComponent} from '../../commonComponents';
import { SeminarApi } from "../../../../store/api-actions/seminarResult/SeminarResultApi";
import {Dropdown} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { END_POINT } from "../../../../constants/apiEndPoints";
import Loader from 'react-loader-spinner';
import {swal} from '../../../../constants/confirm-alert';
import {MESSAGE} from '../../../../constants/confirmMessage';
import { withRouter } from "react-router";
import {COMMON_FUNCTION} from '../../../../constants/commonFunction';
import { SendCertificateApi } from "../../../../store/api-actions/sendCertificate/SendCertificateApi";




const mapDispatchToProps = dispatch => {
  return {
    Seminar: (reqData)=>dispatch(SeminarApi(reqData)),
    SendCertificate: (reqData)=>dispatch(SendCertificateApi(reqData))
};
};

const mapStateToProps = state => {
  return {
    seminarState: state.seminar
  };
};

class SeminarResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading:false,
            isWarningConfirMsg:false,
            isSuccessConfirMsg:false,
            isFailedConfirMsg:false,
            _id:"",
            message: null,
            title:"",
            formFields: {},
            errors: {},
            messageElement: false,
            success: false,
            s3file:"",
            isLoader:false,
            image:"",
        };
      }
      componentDidMount() {
        let reqData = {};
        // console.log("%%%%%%%%%%%%%%%",this.props)
        reqData.userId = this.props.match.params.id
        this.props.Seminar(reqData).then(res=>{})
      }

      handlePageClick = data => {
        let reqData = {};
        if(data && !isNaN(data.selected)){
          reqData.pageNo = parseInt(data.selected)+1;
        }
        reqData.userId = this.props.match.params.id
        this.props.Seminar(reqData).then(res=>{})
      };
      
      sendCertificateHandler = data => {
        this.setState({isLoading:true});
        let reqData = {};
        reqData.name=this.props.studentDetails.firstName+''+this.props.studentDetails.middleName+''+this.props.studentDetails.lastName
        reqData.email=this.props.studentDetails.email
        reqData.instructorLicense=this.props.studentDetails.instructorLicense
        reqData.answerId=data._id
        reqData.schoolName=this.props.studentDetails.schoolName
        reqData.created=data.created
        reqData.seminar = data.seminarId
        this.props.SendCertificate(reqData).then(res=>{
          if(res.status && Object.keys(res.result).length  > 0){
            let { history } = this.props
            this.setState({isLoading:false,isSuccessConfirMsg:true,message:MESSAGE.CERTIFICATE_SEND,title:MESSAGE.SUCCESS_TITLE});
            }else{
              this.setState({isFailedConfirMsg:true,isLoading:false,message:res.message,title:MESSAGE.FAILED_TITLE});
          }
        })
      };

      viewCertificateHandler=(item)=>{
        this.props.history.push(`/portal/student-certificate/${this.props.match.params.id}/${item.seminarId}`)
      }

      render() {
            var conm = "";
            var loader = ""
            var obj = {
                title:this.state.title,
                onConfirm:()=>{
                this.setState({isFailedConfirMsg:false,isSuccessConfirMsg:false});
                this.props.history.push(`/portal/user`)
                },
                subtitle:this.state.message
            }

            if(this.state.isFailedConfirMsg){
                conm = swal.failed(obj);
            }
            
            if(this.state.isSuccessConfirMsg){
                conm = swal.success(obj);
            }
            if(this.state.isLoading){
                loader = <LoadingComponent/>
            }
          let { list,totalCount} = this.props.seminarState;
         
        
        return (
          <div className="album pt-3">
           <div className="container-fluid">
              <div className="table-responsive postion-relative">
                { loader } 
                { conm } 
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Seminar</th>
                      <th>Result</th>
                      <th>Test Date</th>
                      <th>Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                    { list.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.seminarId}</td>
                        <td>{item.isPass?"Passed":"Failed"}</td>
                        <td>{COMMON_FUNCTION.parseDate(item.created)}</td>
                        <td><button onClick={()=>{
                          this.sendCertificateHandler(item);
                        }} className="btn btn-info btn-sm"><i className="fa fa-send"></i> Send</button>
                        
                        &nbsp;&nbsp;
                        <button onClick={()=>{
                          this.viewCertificateHandler(item);
                        }} className="btn btn-info btn-sm"><i className="fa fa-eye"></i> View</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextLabel={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={totalCount/END_POINT.LIMIT}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'text-center pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        );
      }
}
export default SeminarResult = withRouter(connect(mapStateToProps, mapDispatchToProps)(SeminarResult));