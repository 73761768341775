
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import { Link } from 'react-router-dom';

class TaxRates extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Listing All Tax Rates"/>
            </div>
            <div className="col-md-8">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add Tax Rate"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Zone</th>
                  <th>Tax Rates</th>
                  <th>Tax Class</th>
                  <th align="left">Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                      1
                    </td>
                    <td>
                    New York
                    </td>
                    <td>
                    7.00
                    </td>
                    <td>
                    Sale Tax
                    </td>
                    <td align="left">
                    <strong>Added Date: </strong>2017-08-07 07:06:53<br/>
                    <strong>Last Modified: </strong>
                    </td>
                    <td> 
                    <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Link>
                    <Link className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default TaxRates 