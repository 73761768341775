import React, { Component,useState } from 'react';
import { _callApi } from "../../../../services/baseService";
import { Heading,Button,InputBox,SelectBox,TextArea } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { UpdateDealsApi } from "../../../../store/api-actions/deals/UpdateDealsApi";
import { GetDealsDetailsApi } from "../../../../store/api-actions/deals/GetDealsDetailsApi";
import Loader from 'react-loader-spinner';
import {MESSAGE} from '../../../../constants/confirmMessage';

 
const mapDispatchToProps = dispatch => {
    return {
        getDealsDetails: form => dispatch(GetDealsDetailsApi(form)),
    };
  };
  
const mapStateToProps = state => {
  return {
      getDealsDetailsState: state.getDealsDetails,
  };
};

class ViewDeals extends Component {

    constructor(props) {
        super(props);
        this.state = {
          formFields: {},
          errors: {},
          message: null,
          messageElement: false,
          success: false,
          file:"",
          s3file:"",
          isSuccessConfirMsg:false
        };
        
    }
    
    componentDidMount(){
        let { match } = this.props
        let reqObj = {};
        if(match.params.id){
          reqObj.dealsId = match.params.id;
        }
        // console.log("00)))))(((((00",this.props)
        this.props.getDealsDetails(reqObj).then(res=>{
          const { message = null, result = null } = res;
          console.log("####$$$$$$$",this.props.getDealsDetailsState)
        })
    }
    render() {
        let { data } = this.props.getDealsDetailsState;
        let loader = "";
        

        return (
          <div className="album pt-3">
                      
          <div className="container-fluid">
          <Heading headingClass="bg-light text-center" headingText="Deals Details"/>
          <div className="container">
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    title:data.title?data.title:'',
                    address:data.address?data.address:'',
                    price:data.price?data.price:'',
                    discount:data.discount?data.discount:'',
                    discountType:data.discountType?data.discountType:'',
                    startDate:data.startDate?data.startDate:'',
                    endDate:data.endDate?data.endDate:'',
                    description:data.description?data.description:'',
                    
                  }}
                  onSubmit={this.editDealsForm}
                  validationSchema={VALIDATORS.DEALS}
                  render={({handleSubmit, errors, touched, values, handleChange})=>(
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-md-6">
                          <InputBox
                            formWrapClass = "form-group"
                            labelText="Title"
                            inputClass="form-control"
                            type="text"
                            name = "title"
                            value = {values.title}
                            inpuReadOnly = {true}
                          />
                          
                          </div>
                          <div className="col-md-6 text-center">
                            <div className="img-placeholder">
                              <img className="wh-174-194" src=""/>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                        <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Address"
                            inputClass="form-control"
                            type="text"
                            name = "address"
                            value = {values.address}
                            inpuReadOnly = {true}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Price"
                            inputClass="form-control"
                            type="text"
                            name = "price"
                            value = {values.price}
                            inpuReadOnly = {true}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Discount"
                            inputClass="form-control"
                            type="text"
                            name = "discount"
                            value = {values.discount}
                            inpuReadOnly = {true}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="Discount Type"
                            inputClass="form-control"
                            type="text"
                            name = "discountType"
                            value = {values.discountType}
                            inpuReadOnly = {true}
                          />
                           
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="startDate"
                            inputClass="form-control"
                            type="text"
                            name = "startDate"
                            value = {values.startDate}
                            inpuReadOnly = {true}
                          />
                          <InputBox
                            formWrapClass = "form-group col-md-6"
                            labelText="End Date"
                            inputClass="form-control"
                            type="text"
                            name = "endDate"
                            value = {values.endDate}
                            inpuReadOnly = {true}
                          />
                          <TextArea
                          formWrapClass = "form-group col-md-12"
                          labelText="Description"
                          inputClass="form-control"
                          name = "description"
                          value={values.description}
                          inpuReadOnly = {true}
                          />
                          <InputBox
                            type="hidden"
                            name = "lat"
                          />
                          <InputBox
                            type="hidden"
                            name = "long"
                          />
                        </div>
                      <div className="form-group auth-form-field">
                        <Button
                          btnClass="btn btn-primary"
                          btnType="submit"
                          btnText="Submit"
                        />
                      </div>
                      <div className="clearfix">
                        &nbsp;
                      </div>
                  </form>
                )}
                  />
          </div>
          </div>
        </div>
    
        )
    }
}

export default ViewDeals = connect(mapStateToProps, mapDispatchToProps)(ViewDeals);