import { ACTIONS } from "../../action-constants/Actions"


const CategoryEditing=()=>{

    return {
        type: ACTIONS.CATEGORY.EDITCATEGORY.CATEGORY_EDITING,
        payload:{
            isCategoryEditing:true,
            
        },
        isSuccess: false,
    }
}

const CategoryEditingFailed=({ result, msg })=>{
    
    return {
        type: ACTIONS.CATEGORY.EDITCATEGORY.CATEGORY_EDITING_FAILED,
        payload:{
            isCategoryEditing:false,
            result,
            msg
        },
        isSuccess: false,
    }
}

const CategoryEdited=({ result })=>{
    
    

    return {
        type: ACTIONS.CATEGORY.EDITCATEGORY.CATEGORY_EDITED,
        payload:{
            isCategoryEditing:false,
            result:result,
            // message:msg
        },
        isSuccess: true,
    }
}

export const editCategory = {
    CategoryEdited,
    CategoryEditingFailed,
    CategoryEditing
}