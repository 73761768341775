import { _callApi } from "../../../services/baseService";
import { END_POINT } from "../../../constants/apiEndPoints";
import { Dashboard } from "../../actions/Dashboard/dashboard";

export const GetDashboardApi = form => {
  return (dispatch, getState) => {
    dispatch(Dashboard.DashboardFetching());
    return _callApi(
      END_POINT.DASHBOARD.DASHBOARD_DATA.END_POINT,
      END_POINT.DASHBOARD.DASHBOARD_DATA.METHOD,
      form
    ).then(res => {
      const { message = null, result = null } = res.data;
      if (Object.keys(result).length === 0) {
        dispatch(Dashboard.DashboardFailed({result,message}));
        return res.data;
      } else {
        dispatch(Dashboard.DashboardFetched({result,message}));
        return res.data;
      }
    });
  };
};
