import { ACTIONS } from "../../action-constants/Actions"


const Updating=()=>{
    return {
        type: ACTIONS.COMMON_ACTION.UPDATE.UPDATING,
        payload:{
            isUpdating:true,
        },
        isSuccess: false,
    }
}
const UpdatingFailed=({ result, msg })=>{
    return {
        type: ACTIONS.COMMON_ACTION.UPDATE.UPDATING_FAILED,
        payload:{
            isUpdating:false,
            result,
            msg
        },
        isSuccess: false,
    }
}
const Updated=({ result })=>{
    return {
        type: ACTIONS.COMMON_ACTION.UPDATE.UPDATED,
        payload:{
            isUpdating:false,
            result:result,
        },
        isSuccess: true,
    }
}
export const update = {
    Updating,
    UpdatingFailed,
    Updated
}