import React, { Component } from 'react';
import { _callApi, _callApiForUpload } from "../../../../services/baseService";
import { Heading, Button, InputBox, LoadingComponent, SelectBox } from "../../commonComponents";
import { connect } from "react-redux";
import { Formik } from "formik";
import { VALIDATORS } from "../../../../constants/validation-schemas";
import { COMMON_FUNCTION } from "../../../../constants/commonFunction";
import { AddQuestionApi } from "../../../../store/api-actions/AddQuestion";
import { uploadFile } from 'react-s3';
import { CONFIG } from '../../../../constants/config';
import Loader from 'react-loader-spinner';
import { swal } from '../../../../constants/confirm-alert';
import { MESSAGE } from '../../../../constants/confirmMessage';
import axios from "axios";
import { END_POINT } from '../../../../constants/apiEndPoints';



const seminars = [
  { value: 'seminar-1', text: 'Seminar 1' },
  { value: 'seminar-2', text: 'Seminar 2' },
  { value: 'seminar-3', text: 'Seminar 3' }
]

const mapDispatchToProps = dispatch => {
  return {
    addQuestion: form => dispatch(AddQuestionApi(form))
  };
};

const mapStateToProps = state => {
  return {
    addQuestionState: state.addQuestion
  };
};

class AddQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      errors: {},
      message: null,
      messageElement: false,
      success: false,
      file: "",
      s3file: "",
      isSuccessConfirMsg: false,
      isLoader: false,
      imageUpload: ""
    };
  }

  addQuestionForm = (values, formikBag) => {
    this.setState({ isLoader: true });
    console.log(this.state);
    // uploadFile(this.state.s3file, CONFIG.S3_BUCKET_DETAILS)
    //   .then(data => {
    //     values.image = data.key;
    if (this.state.imageUpload) {
      values.image = this.state.imageUpload;
    }
    this.props.addQuestion(values).then(res => {
      const { message = null, result = null } = res;
      if (Object.keys(result).length > 0) {
        this.setState({ isLoader: false });
        this.setState({ isSuccessConfirMsg: true });
      }
    })
    // })
    // .catch(err => console.error(err))
  };
  imageHandleChange = (e) => {
    let imgData = COMMON_FUNCTION.imagePreview(e);
    if (imgData) {
      let formData = new FormData();
      formData.append(
        "image",
        imgData.s3file,
        imgData.s3file.name
      );
      _callApi(END_POINT.UPLOAD_IMAGE.END_POINT, END_POINT.UPLOAD_IMAGE.METHOD, formData).then(res => {
        console.log(res.data.result.fileName);
        if (res.data.result && res.data.result.fileName) {
          this.setState({ "image": END_POINT.IMAGE_URL + res.data.result.fileName, imageUpload: res.data.result.fileName });
        }
      })
    }
  }

  redirectTo = (data) => {
    let { history } = this.props
    history.push(`/portal/questions`)
  }

  render() {
    let { addQuestionState } = this.props;
    let loader = "";
    let conm = "";
    if (this.state.isLoader) {
      loader = <LoadingComponent />
    }

    if (this.state.isSuccessConfirMsg) {
      var obj = {
        title: MESSAGE.SUCCESS_TITLE,
        onConfirm: () => {
          this.setState({ isSuccessConfirMsg: false });
          this.redirectTo();

        },
        subtitle: MESSAGE.QUESTION_ADD
      }
      conm = swal.success(obj);
    }

    return (
      <div className="album pt-3">
        {loader}
        {conm}
        <div className="container-fluid">
          <Heading headingClass="" headingText="Add Question" />
          <hr />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Formik
                  initialValues={{
                    title: '',
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: '',
                    answer: '',
                  }}
                  onSubmit={this.addQuestionForm}
                  validationSchema={VALIDATORS.ADDQUESTION}
                  render={({ handleSubmit, errors, touched, values, handleChange }) => (
                    <form onSubmit={handleSubmit}>
                      <SelectBox
                        formWrapClass="form-group"
                        labelText="Seminar"
                        labelClass=""
                        placeholderText="Select Seminar"
                        selectClass="form-control"
                        name="seminarId"
                        handleChange={handleChange}
                        value={values.seminarId}
                        errorClass="text-danger"
                        errorText={touched.seminarId && errors.seminarId}
                        options={seminars}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Question Title"
                        inputClass="form-control"
                        type="text"
                        placeholder="Question Title"
                        name="title"
                        handleChange={handleChange}
                        value={values.title}
                        errorClass="text-danger"
                        errorText={touched.title && errors.title}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Option 1"
                        inputClass="form-control"
                        type="text"
                        placeholder="Option 1"
                        name="option1"
                        handleChange={handleChange}
                        value={values.option1}
                        errorClass="text-danger"
                        errorText={touched.option1 && errors.option1}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Option 2"
                        inputClass="form-control"
                        type="text"
                        placeholder="Option 2"
                        name="option2"
                        handleChange={handleChange}
                        value={values.option2}
                        errorClass="text-danger"
                        errorText={touched.option2 && errors.option2}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Option 3"
                        inputClass="form-control"
                        type="text"
                        placeholder="Option 3"
                        name="option3"
                        handleChange={handleChange}
                        value={values.option3}
                        errorClass="text-danger"
                        errorText={touched.option3 && errors.option3}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Option 4"
                        inputClass="form-control"
                        type="text"
                        placeholder="Option 4"
                        name="option4"
                        handleChange={handleChange}
                        value={values.option4}
                        errorClass="text-danger"
                        errorText={touched.option4 && errors.option4}
                      />
                      <InputBox
                        formWrapClass="form-group"
                        labelText="Answer"
                        inputClass="form-control"
                        type="text"
                        placeholder="Answer"
                        name="answer"
                        handleChange={handleChange}
                        value={values.answer}
                        errorClass="text-danger"
                        errorText={touched.answer && errors.answer}
                      />

                      <InputBox
                        formWrapClass="form-group"
                        labelText="Question Image"
                        inputClass="form-control"
                        type="file"
                        placeholder="Question Image"
                        name="imagePreview"
                        handleChange={this.imageHandleChange}
                      />
                      <div className="form-group auth-form-field">
                        <Button
                          btnClass="btn btn-primary"
                          btnType="submit"
                          btnText="Submit"
                        />
                      </div>
                    </form>
                  )}
                />

              </div>
              <div className="col-md-6 text-center">
                <div className="img-placeholder">
                  <img className="wh-174-194" src={this.state.image} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    )
  }
}

export default AddQuestion = connect(mapStateToProps, mapDispatchToProps)(AddQuestion);