import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
export default class LoadingComponent extends Component {
    //other logic
    render() {
        return (
            <div className="loader-custom">
                <Loader
                type="Bars"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={1000} //3 secs
                />
                {/* <Loader
                type="MutatingDots"
                color="#00BFFF"
                height="100"
                width="100"
            /> */}
            </div>
        );
    }
}