
import React, { Component } from 'react';
import { Heading,Button } from '../../commonComponents';
import { Link } from 'react-router-dom';
import defaultuser from '../../../../assets/images/defaultuser.png'


class Admin extends Component {
  

  render() {
    return (
      <div className="album pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <Heading headingText="Admins"/>
            </div>
            <div className="col-md-8">
                <Button btnType="button" btnClass="btn btn-primary pull-right" btnText="Add Admins"/>
            </div>
          </div>
          <hr/>
          <div className="clearfix">&nbsp;</div>
          <div className="table-responsive">
            <table className="table table-hover text-center  table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Picture</th>
                  <th>Personal Info</th>
                  <th>Address</th>
                  <th>Admin Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>
                    1
                    </td>
                    <td>
                        <img src={defaultuser} width="80" className="IndiaFlag" alt="India Flag"/>
                    </td>
                    <td align="left">
                    <strong>Name:</strong> Majid khan <br/>
                    <strong>DOB: </strong><br/>
                    <strong>Email: </strong> majidk061@gmail.com <br/>
                    <strong>Telephone: </strong><br/>
                    <strong>Fax: </strong><br/>
                    <strong>Devices: </strong>
                    </td>
                    <td align="left">
                        <strong>Company: </strong><br/>
                        <strong>Address: </strong>
                    </td>
                    <td> 
                       --
                    </td>
                    <td> 
                       --
                    </td>
                    <td> 
                        <Link className="badge bg-light-blue" data-toggle="tooltip" data-original-title="Edit" to="#">
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </Link>
                        <Link className="badge bg-red" data-toggle="tooltip" data-original-title="Delete" to="#">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Admin 